import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { DownloadTableExcel } from "react-export-table-to-excel";
import axios from "axios";
import { APIURL } from "../../../Config";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import ApprovedDataUpdate from "./ApprovedDataUpdate";
import SweetAlert from "react-bootstrap-sweetalert";

const ApprovedPending = () => {
  const token = localStorage.getItem("token");
  const ids = localStorage.getItem("id");
  const role = localStorage.getItem("role"); //Engineer
  const loginType = localStorage.getItem("type"); //Engineer
  const tableRef = React.useRef(null);
  const [selectDate, setSelectDate] = useState(false);
  const [dateValue1, setDateValue1] = useState(
    moment(new Date()).subtract(20, "days").format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  console.log("dateValue2::: ", dateValue2);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);

  const [selectValue, setSelectValue] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [clientDetails, setClientDetails] = useState([]);
  const [engineerDetail, setEngineerDetail] = useState([]);
  const [verificationData, setVerificationData] = useState(null);
  const [personalInformationData, setPersonalInformationData] = useState(null);
  const [updateForm, setUpdateForm] = useState(false);

  const [updateId, setUpdateId] = useState("");

  useEffect(() => {
    const todayDate = new Date();
    setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
    const sevenDaysBeforeDate = new Date();
    sevenDaysBeforeDate.setDate(todayDate.getDate() - 20);
    setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    handleSubmit();
  }, []);

  useEffect(() => {
    fetchClientDetails();
    fetchAssignTo();
  }, []);
  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/ClientDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchAssignTo = async () => {
    await axios
      .get(`${APIURL}/api/EngineerDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setEngineerDetail(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadExcel = () => {
    let url =
      role === "CPV Super Admin"
        ? `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}`
        : `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}`;

    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}/${selectValue}/${assignTo}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}/${selectValue}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/pv/vGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}/${assignTo}`;
    }
    axios
      .request(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Reject.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloadXls = (xlsData) => {
    const blob = new Blob([xlsData], { type: "application/vnd.ms-excel" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Rejected.xls";
    link.click();
  };

  const handleSubmit = () => {
    console.log(":::::::", dateValue1, dateValue2);

    //  "/clientStatusEngineerApprove/{clientId}/{type}/{fromdate}/{todate}",
    //  "/clientStatusEngineerApprove/{clientId}/{type}",

    //  "/clientStatusEngineerByClientsApprove/{clientId}/{type}/{bankerId}",
    //  "/clientStatusEngineerByAssignToApprove/{clientId}/{type}/{assignedTo}",

    //  "/clientStatusEngineerByClientsAndAssignToApprove/{clientId}/{type}/{bankerId}/{assignedTo}",

    //  "/clientStatusEngineerAprAndRejectByClientsApprove/{clientId}/{type}/{bankerId}/{fromdate}/{todate}",
    //  "/clientStatusEngineerAprAndRejectByAssignToApprove/{clientId}/{type}/{assignedTo}/{fromdate}/{todate}",
    //  "/clientStatusEngineerAprAndRejectByClientsAndAssignToApprove/{clientId}/{type}/{assignedTo}/{bankerId}/{fromdate}/{todate}"
    let url =
      role === "CPV Super Admin"
        ? `${APIURL}/api/clientStatusEngineerApprove/${ids}/0`
        : `${APIURL}/api/clientStatusEngineerApprove/${ids}/0`;

    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByClientsAndAssignToApprove/${ids}/0/${assignTo}/${selectValue}/${dateValue1}/${dateValue2}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByClientsApprove/${ids}/0/${selectValue}/${dateValue1}/${dateValue2}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByAssignToApprove/${ids}/0/${assignTo}/${dateValue1}/${dateValue2}`;
    }
    axios
      .request(url, {
        method: "GET",

        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::: ", response);
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleApprove = (id) => {
    const dto = {
      id: id,
      doneBy: ids,
      finalDecision: 2,
      remarks: "",
    };
    axios
      .request(`${APIURL}/v2/api/cpv/saveCpvVerification`, {
        method: "POST",
        data: dto,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        toast.success(response?.data);
        handleSubmit();
        setAlert(null);
        console.log("response:::", response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleUpdate = async (id) => {
    console.log("id::: ", id);
    try {
      axios
        .request(`${APIURL}/v2/api/cpv/getCpvVerificationDetail/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("response::", response);
          setVerificationData(response.data);
          try {
            axios
              .request(
                `${APIURL}/v2/api/cpv/getCpvPersonalInformationDetail/${id}`,
                {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                console.log("getCpvPersonalInformationDetail::", response);
                if (response.data) {
                  setPersonalInformationData(response.data);
                  setUpdateForm(true);
                  setUpdateId(id);
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          } catch (error) {
            console.log("error::: ", error);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const PopUp = (id) => {
    setAlert(
      <SweetAlert
        success
        showCancel
        style={{ display: "block" }}
        onConfirm={() => {
          handleApprove(id);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        confirmBtnStyle={{
          backgroundColor: "#66a725",
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
        }}
        cancelBtnStyle={{
          backgroundColor: "#f44336",
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
          color: "white",
          textDecoration: "none",
        }}
      >
        <h2>Are you sure Approve?</h2>
      </SweetAlert>
    );
  };
  const openLink = (crn) => {
    const url = `${APIURL}/v2/api/cpv/cpvVerificationPdf/${crn}`;
    axios
      .request(url, {
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response:::", response);
        if (response?.data) {
          const pdfData = response?.data;
          downloadPdf(pdfData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const downloadPdf = (pdfData) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "pdsheet.pdf";
    link.click();
  };
  const Imagedumpdownload = (crn) => {
    const url = `${APIURL}/v2/api/cpv/cpvDocumentDumpImagesDownload/561`;
    axios
      .request(url, {
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response:::", response);
        if (response?.data) {
          const arrayBuffer = response?.data;
          const blob = new Blob([arrayBuffer], { type: "image/jpg" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "downloaded_image.jpg";
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      {updateForm ? (
        <>
          <div className="pt-3">
            <div className="card">
              <div className="p-3 pt-4">
                <div>
                  <button
                    className="btn btn-secondary"
                    style={{ width: "125px" }}
                    onClick={() => setUpdateForm(false)}
                  >
                    Back
                  </button>
                  <ApprovedDataUpdate
                    collateralVerificationStatus={
                      verificationData.collateralVerificationStatus
                    }
                    businessVerificationStatus={
                      verificationData.businessVerificationStatus
                    }
                    residenceVerificationStatus={
                      verificationData.residenceVerificationStatus
                    }
                    employmentVerificationStatus={
                      verificationData.employmentVerificationStatus
                    }
                    guarantorRvStatus={verificationData.guarantorRvStatus}
                    updateId={updateId}
                    personalInformationData={personalInformationData}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div class="pt-4">
          {alert}
          <h4 className="text-center my-3">Approve Pending</h4>

          <div className="pt-3">
            <div className="card">
              <div className="p-3 pt-4">
                <div className="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
                  <div>
                    {/* <button
               className="btn btn-success mb-3 mb-md-0"
               style={{ width: "120px", background: "blue" }}
               // onClick={() => downloadExcel()}
             >
               Download
             </button> */}
                  </div>
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    {!selectDate ? (
                      <button
                        className="btn btn-info"
                        style={{ width: "150px" }}
                        onClick={() => setSelectDate(true)}
                      >
                        Filters
                      </button>
                    ) : (
                      <>
                        <div className="d-flex" style={{ width: "100%" }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="mt-2 mx-1">
                              <Form.Select
                                options={clientDetails}
                                value={selectValue}
                                type="number"
                                className="form-control ml-2"
                                onChange={(e) => setSelectValue(e.target.value)}
                              >
                                <option value="">Select Client</option>
                                {clientDetails.map((ids) => (
                                  <option value={ids.id} key={ids.id}>
                                    {ids.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="mt-2 mx-2">
                              <Form.Select
                                options={engineerDetail}
                                value={assignTo}
                                type="text"
                                className="form-control ml-2"
                                onChange={(e) => setAssignTo(e.target.value)}
                              >
                                <option value="">Select Rejected By</option>

                                {engineerDetail.map((ids) => (
                                  <option value={ids.id} key={ids.id}>
                                    {ids.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div>
                              <DatePicker
                                className="ml-2"
                                label="To Date"
                                value={dateValue1}
                                inputFormat="dd-MM-yyyy"
                                onChange={(e) =>
                                  setDateValue1(moment(e).format("YYYY-MM-DD"))
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>
                            <div>
                              <DatePicker
                                className="ml-2"
                                label="From Date"
                                value={dateValue2}
                                inputFormat="dd-MM-yyyy"
                                onChange={(e) =>
                                  setDateValue2(moment(e).format("YYYY-MM-DD"))
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>
                          </LocalizationProvider>
                          <div className="mt-2 mx-2">
                            <button
                              className="btn btn-success"
                              style={{
                                background: "#66a725",
                                width: "100px",
                              }}
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="table-responsive mt-3">
                  <table class="table table-boarded" ref={tableRef}>
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Case No</th>
                        <th>Applicant Name</th>
                        <th>Mobile Number</th>
                        <th>Address</th>
                        <th>client name</th>
                        <th>Approved By </th>
                        {role !== "CPV Field Officer" && <th>Action</th>}
                        {role !== "CPV Field Officer" && <th>Update </th>}
                        <th>Download </th>
                        <th>Image dump download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.caseReferenceNumber}</td>
                          <td>{item?.applicantName}</td>
                          <td>{item?.mobile}</td>
                          <td>{item?.address}</td>

                          <td>{item?.clientName}</td>

                          <td>{item?.approvedBy}</td>
                          {role !== "CPV Field Officer" && (
                            <td>
                              <button
                                className="btn btn-success"
                                onClick={() => PopUp(item?.id)}
                              >
                                Approve
                              </button>
                            </td>
                          )}
                          {role !== "CPV Field Officer" && (
                            <td>
                              <button
                                className="cursor-pointer btn btn-warning"
                                onClick={() => {
                                  handleUpdate(item?.id);
                                }}
                              >
                                Update
                              </button>
                            </td>
                          )}
                          <td>
                            <button
                              className="cursor-pointer btn btn-secondary"
                              onClick={() => {
                                openLink(item?.caseReferenceNumber);
                              }}
                            >
                              Download
                            </button>
                          </td>
                          <td>
                            <a
                              href={`${APIURL}/v2/api/cpv/cpvDocumentDumpImagesDownload/${item?.id}`}
                              target="_blank"
                            >
                              <button className="cursor-pointer btn btn-info">
                                Image dump download
                              </button>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApprovedPending;
