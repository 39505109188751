import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Config";
import Pagination from "../../Pagination/Pagination";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";

export default function EmployerCustomizationRequest() {
  const [isLoader, setIsLoader] = useState(false);
  const [showPrePage, setShowPrePage] = useState(100);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const [showModal, setShowModal] = useState(false);
  const [remark, setRemark] = useState("");
  const [message, setMessage] = useState("");

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [helpline, setHelpline] = useState([]);
  useEffect(() => {
    getHelpline();
  }, []);

  const getHelpline = () => {
    setIsLoader(true);
    axios
      .request(`${APIURL}/corporate/employercustomizationrequest`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        setHelpline(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  const remarkChange = (e) => {
    setMessage(e.target.value);
  };
  const handleSubmit = (remark) => {
    console.log("remark", remark);
    console.log("message", message);
    if (message === "") {
      toast.warning("All field required");
    } else {
      const data = {
        mobileno: remark?.mobileno,
        remarks: message,
      };
      axios
        .request(`${APIURL}/corporate/employercustomizationremarks`, {
          method: "POST",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response:::::::", response);
          setShowModal(false);
          setMessage("");
          toast.success(response?.data);
          getHelpline();
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
          console.log(error);
        });
    }
  };
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Employer Credit Appraisal
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Company email</th>
                        <th>Employer name</th>
                        <th>Contact name</th>
                        <th>Mobile</th>
                        <th>No. of employees</th>
                        <th>
                          Enter remarks by IT dept by clicking on the tabd
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                        {helpline?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>
                              {" "}
                              {item?.emailid}
                            </td>
                            <td>
                              {item?.companyname}
                            </td>
                            <td>{item.employername}</td>
                            <td>{item.mobileno}</td>
                            <td>{item.noofemployees}</td>
                            {item?.remarks === "" ||
                            item?.remarks === null ? (
                              <td>
                                <div className="d-flex justify-content-center">
                                  <button
                                    className="btn btn-success"
                                    style={{ width: "150px" }}
                                    onClick={() => {
                                      setRemark(item);
                                      setShowModal(true);
                                    }}
                                  >
                                    Remark
                                  </button>
                                </div>
                              </td>
                            ) : (
                              <td>{item?.remarks}</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      style={{ background: "rgb(255 255 255 / 40%)" }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Remark</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <input
                          type="text"
                          value={message}
                          onChange={remarkChange}
                          style={{
                            width: "100%",
                            padding: "10px",
                            boxSizing: "border-box",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            marginTop: "10px",
                            fontSize: "18px",
                          }}
                        />
                        <div className="d-flex mt-3 justify-content-end">
                          <button
                            className="btn btn-secondary"
                            style={{ width: "100px" }}
                            onClick={() => setShowModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-success ml-2"
                            style={{
                              width: "100px",
                              background: "#66a725",
                            }}
                            onClick={() => handleSubmit(remark)}
                          >
                            Submit
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                    {helpline && helpline.length !== 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={helpline.length}
                      />
                    )}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
