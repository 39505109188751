import React, { useEffect, useState } from "react";
import "./App.css";
import AllRoutes from "./Router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import mob_Image from "./image/mob-image.jpeg";
import { AppContext } from "./Components/utils/Appcontex";

function App() {
  const [showImage, setShowImage] = useState(true);
  const [key, setKey] = useState("ManageEmployees");
  const [activeIndex, setActiveIndex] = useState(5);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      const timer = setTimeout(() => {
        setShowImage(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setShowImage(false);
    }
  }, []);

  return (
    <>
      <ToastContainer />

      {showImage ? (
        <div className="image-container">
          {/* <img src={mob_Image} alt="Mobile Image" style={{ height: "100vh" }} /> */}
        </div>
      ) : (
        <>
          <AppContext.Provider
            value={{ key, setKey, setActiveIndex, activeIndex, open, setOpen }}
          >
            <AllRoutes />
          </AppContext.Provider>
        </>
      )}
    </>
  );
}

export default App;
