import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";

function ApprovedUpdateCollateral({ data, setFormStates, formStates }) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const collateralInitialValues = {
    clientId: data?.clientId,
    doneBy: data?.doneBy,
    doneOn: data?.doneOn,
    collateralUpdateStatus: data?.collateralUpdateStatus,
    collateralUpdatedBy: id,
    collateralDateOfVisit: data?.collateralDateOfVisit,
    collateralTimeOfVisit: data?.collateralTimeOfVisit,
    collateralApplicantName: data?.collateralApplicantName,
    collateralContactNo: data?.collateralContactNo,
    collateralCurrentAddress: data?.collateralCurrentAddress,
    collateralNearestLandmark: data?.collateralNearestLandmark,
    collateralEaseOfLocating: data?.collateralEaseOfLocating,
    collateralNameOfPersonMet: data?.collateralNameOfPersonMet,
    collateralRelationWithApplicant: data?.collateralRelationWithApplicant,
    collateralOwner: data?.collateralOwner,
    collateralRelationWithOwner: data?.collateralRelationWithOwner,
    collateralSelfOccupiedOrRented: data?.collateralSelfOccupiedOrRented,
    collateralAllMembersStayInProperty:
      data?.collateralAllMembersStayInProperty,
    collateralNameOfTenant: data?.collateralNameOfTenant,
    collateralContactNoOfTenant: data?.collateralContactNoOfTenant,
    collateralRentPerMonth: data?.collateralRentPerMonth,
    collateralHowLongStaying: data?.collateralHowLongStaying,
    collateralRentAgreementExecuted: data?.collateralRentAgreementExecuted,
    collateralReasonOfVacant: data?.collateralReasonOfVacant,
    collateralFirstNeighbourName: data?.collateralFirstNeighbourName,
    collateralFirstNeighbourMobno: data?.collateralFirstNeighbourMobno,
    collateralFirstNeighbourAddress: data?.collateralFirstNeighbourAddress,
    collateralFirstNeighbourHowlongknow:
      data?.collateralFirstNeighbourHowlongknow,
    collateralFirstNeighbourBehaviour: data?.collateralFirstNeighbourBehaviour,
    collateralFirstNeighbourNegativeBehaviour:
      data?.collateralFirstNeighbourNegativeBehaviour,
    collateralFirstNeighbourAnyIllegalActivity:
      data?.collateralFirstNeighbourAnyIllegalActivity,
    collateralSecondNeighbourName: data?.collateralSecondNeighbourName,
    collateralSecondNeighbourMobno: data?.collateralSecondNeighbourMobno,
    collateralSecondNeighbourAddress: data?.collateralSecondNeighbourAddress,
    collateralSecondNeighbourHowlongknow:
      data?.collateralSecondNeighbourHowlongknow,
    collateralSecondNeighbourBehaviour:
      data?.collateralSecondNeighbourBehaviour,
    collateralSecondNeighbourNegativeBehaviour:
      data?.collateralSecondNeighbourNegativeBehaviour,
    collateralSecondNeighbourAnyIllegalActivity:
      data?.collateralSecondNeighbourAnyIllegalActivity,
    collateralAditionalCheckName: data?.collateralAditionalCheckName,
    collateralAditionalCheckMobno: data?.collateralAditionalCheckMobno,
    collateralAditionalCheckAddress: data?.collateralAditionalCheckAddress,
    collateralAditionalCheckFeedbackHowlongknow:
      data?.collateralAditionalCheckFeedbackHowlongknow,
    collateralAditionalCheckFeedbackBehaviour:
      data?.collateralAditionalCheckFeedbackBehaviour,
    collateralAditionalCheckNegativeBehaviour:
      data?.collateralAditionalCheckNegativeBehaviour,
    collateralAditionalCheckAnyIllegalActivity:
      data?.collateralAditionalCheckAnyIllegalActivity,
    collateralDetailsRemark: data?.collateralDetailsRemark,
    collateralDedupStatus: data?.collateralDedupStatus,
    collateralDedupDetails: data?.collateralDedupDetails,
    collateralFinalStatus: data?.collateralFinalStatus,
    collateralVerifierName: data?.collateralVerifierName,
    collateralManagerName: data?.collateralManagerName,
    collateralAgencyName: data?.collateralAgencyName,
    collateralVerificationStatus: data?.collateralVerificationStatus,
    collateralAnyMatchFoundInNegativeDatabase:
      data?.collateralAnyMatchFoundInNegativeDatabase,
  };

  const collateralValidationSchema = Yup.object().shape({
    collateralApplicantName: Yup.string().required(
      "Applicant name is required"
    ),
    collateralContactNo: Yup.string().required("Contact number is required"),
    collateralCurrentAddress: Yup.string().required(
      "Current address is required"
    ),
    collateralNearestLandmark: Yup.string().required(
      "Nearest landmark is required"
    ),
    collateralNameOfPersonMet: Yup.string().required(
      "Name of person met is required"
    ),
    collateralRelationWithApplicant: Yup.string().required(
      "Relation with applicant is required"
    ),
    collateralOwner: Yup.string().required("Owner name is required"),
    collateralRentPerMonth: Yup.number()
      .required("Rent per month is required")
      .positive("Must be positive"),
    collateralRelationWithOwner: Yup.string().required(
      "Relation with owner is required"
    ),
    collateralSelfOccupiedOrRented: Yup.string().required(
      "Specify if self-occupied or rented"
    ),
    collateralAllMembersStayInProperty: Yup.string().required(
      "All members staying in property is required"
    ),
    collateralNameOfTenant: Yup.string().required("Name of tenant is required"),
    collateralContactNoOfTenant: Yup.string().required(
      "Contact number of tenant is required"
    ),
    collateralHowLongStaying: Yup.string().required(
      "How long they are staying is required"
    ),
    collateralRentAgreementExecuted: Yup.string().required(
      "Rent agreement status is required"
    ),
    collateralReasonOfVacant: Yup.string().required(
      "Reason for vacancy is required"
    ),
    collateralFirstNeighbourName: Yup.string().required(
      "First neighbour name is required"
    ),
    collateralFirstNeighbourMobno: Yup.string().required(
      "First neighbour mobile number is required"
    ),
    collateralFirstNeighbourAddress: Yup.string().required(
      "First neighbour address is required"
    ),
    collateralFirstNeighbourHowlongknow: Yup.string().required(
      "How long they know is required"
    ),
    collateralFirstNeighbourBehaviour: Yup.string().required(
      "Behaviour of first neighbour is required"
    ),
    collateralFirstNeighbourNegativeBehaviour: Yup.string().required(
      "Negative behaviour is required"
    ),
    collateralFirstNeighbourAnyIllegalActivity: Yup.string().required(
      "Illegal activity status is required"
    ),
    collateralSecondNeighbourName: Yup.string().required(
      "Second neighbour name is required"
    ),
    collateralSecondNeighbourMobno: Yup.string().required(
      "Second neighbour mobile number is required"
    ),
    collateralSecondNeighbourAddress: Yup.string().required(
      "Second neighbour address is required"
    ),
    collateralSecondNeighbourHowlongknow: Yup.string().required(
      "How long they know is required"
    ),
    collateralSecondNeighbourBehaviour: Yup.string().required(
      "Behaviour of second neighbour is required"
    ),
    collateralSecondNeighbourNegativeBehaviour: Yup.string().required(
      "Negative behaviour is required"
    ),
    collateralSecondNeighbourAnyIllegalActivity: Yup.string().required(
      "Illegal activity status is required"
    ),
    collateralAditionalCheckName: Yup.string().required(
      "Additional check name is required"
    ),
    collateralAditionalCheckMobno: Yup.string().required(
      "Additional check mobile number is required"
    ),
    collateralAditionalCheckAddress: Yup.string().required(
      "Additional check address is required"
    ),
    collateralAditionalCheckFeedbackHowlongknow: Yup.string().required(
      "How long they know is required"
    ),
    collateralAditionalCheckFeedbackBehaviour: Yup.string().required(
      "Behaviour feedback is required"
    ),
    collateralAditionalCheckNegativeBehaviour: Yup.string().required(
      "Negative behaviour is required"
    ),
    collateralAditionalCheckAnyIllegalActivity: Yup.string().required(
      "Illegal activity status is required"
    ),
    collateralDetailsRemark: Yup.string(),
    collateralDedupStatus: Yup.string(),
    collateralDedupDetails: Yup.string(),
    collateralFinalStatus: Yup.string().required("Final status is required"),
    collateralVerifierName: Yup.string().required("Verifier name is required"),
    collateralManagerName: Yup.string().required("Manager name is required"),
    collateralAgencyName: Yup.string(),
    collateralVerificationStatus: Yup.number().required(
      "Verification status is required"
    ),
    collateralAnyMatchFoundInNegativeDatabase: Yup.string().required(
      "Match found status is required"
    ),
  });

  const handleSubmit = (values) => {
    console.log("Form data", values);
    axios
      .request(`${APIURL}/v2/api/cpv/updateCpvCollateralVerification`, {
        method: "POST",
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        console.log("response::: ", response);
        toast.success(response?.data);
        setFormStates(!formStates.collateral);
      })
      .catch((error) => {
        console.log(error);
        // setIsLoader(false);
      });
  };

  return (
    <div className="mt-3">
      <h1>Collateral Verification</h1>
      <Formik
        initialValues={collateralInitialValues}
        // validationSchema={collateralValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Applicant Name</label>
                <Field
                  type="text"
                  name="collateralApplicantName"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralApplicantName"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Contact No</label>
                <Field
                  type="number"
                  name="collateralContactNo"
                  className="form-control"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  name="collateralContactNo"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Current Address</label>
                <Field
                  type="text"
                  name="collateralCurrentAddress"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralCurrentAddress"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Nearest Landmark</label>
                <Field
                  type="text"
                  name="collateralNearestLandmark"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralNearestLandmark"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Ease of Locating</label>
                <Field
                  as="select"
                  id="collateralEaseOfLocating"
                  name="collateralEaseOfLocating"
                  className="form-control"
                >
                  <option value="">Select Ease of Locating</option>
                  <option value="Easy">Easy</option>
                  <option value="Difficult">Difficult</option>
                  <option value="Untrackable">Untrackable</option>
                </Field>

                <ErrorMessage
                  name="collateralEaseOfLocating"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>
                  Name of Person Met & His Relationship with Applicant
                </label>
                <Field
                  as="select"
                  id="collateralNameOfPersonMet"
                  name="collateralNameOfPersonMet"
                  className="form-control"
                >
                  <option value="">Select Person Met</option>
                  <option value="Self">Self</option>
                  <option value="Father">Father</option>
                  <option value="Mother">Mother</option>
                  <option value="Wife">Wife</option>
                  <option value="Son/Daughter">Son/Daughter</option>
                  <option value="Brother">Brother</option>
                  <option value="Sister">Sister</option>
                  <option value="Relative">Relative</option>
                  <option value="Other">Other</option>
                </Field>
                <ErrorMessage
                  name="collateralNameOfPersonMet"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Relation with Applicant</label>
                <Field
                  type="text"
                  name="collateralRelationWithApplicant"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralRelationWithApplicant"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Owner Name</label>
                <Field
                  type="text"
                  name="collateralOwner"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralOwner"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Relation with Owner</label>
                <Field
                  type="text"
                  name="collateralRelationWithOwner"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralRelationWithOwner"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Is collateral is self occupied or rented ? </label>
                <Field
                  as="select"
                  id="collateralSelfOccupiedOrRented"
                  name="collateralSelfOccupiedOrRented"
                  className="form-control"
                >
                  <option value="">Select Occupancy Status</option>
                  <option value="Self Occupied">Self Occupied</option>
                  <option value="Tenant">Tenant</option>
                  <option value="Vacant">Vacant</option>
                </Field>
                <ErrorMessage
                  name="collateralSelfOccupiedOrRented"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>
                  Is self-occupied-who all family members stay in peoperty
                </label>
                <Field
                  as="select"
                  id="collateralAllMembersStayInProperty"
                  name="collateralAllMembersStayInProperty"
                  className="form-control"
                >
                  <option value="">Select Members Staying in Property</option>
                  <option value="Self">Self</option>
                  <option value="Parents">Parents</option>
                  <option value="Inlaws">Inlaws</option>
                  <option value="Family Members">Family Members</option>
                  <option value="Other">Other</option>
                </Field>

                <ErrorMessage
                  name="collateralAllMembersStayInProperty"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Name of Tenant</label>
                <Field
                  type="text"
                  name="collateralNameOfTenant"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralNameOfTenant"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Contact No of Tenant</label>
                <Field
                  type="number"
                  name="collateralContactNoOfTenant"
                  className="form-control"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  name="collateralContactNoOfTenant"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Rent Per Month</label>
                <Field
                  type="number"
                  name="collateralRentPerMonth"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralRentPerMonth"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>How Long Staying</label>
                <Field
                  type="text"
                  name="collateralHowLongStaying"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralHowLongStaying"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Rent Agreement Executed</label>
                <Field
                  type="text"
                  name="collateralRentAgreementExecuted"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralRentAgreementExecuted"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Reason of Vacant</label>
                <Field
                  type="text"
                  name="collateralReasonOfVacant"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralReasonOfVacant"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>First Neighbour Name</label>
                <Field
                  type="text"
                  name="collateralFirstNeighbourName"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralFirstNeighbourName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>First Neighbour Mob No</label>
                <Field
                  type="number"
                  name="collateralFirstNeighbourMobno"
                  className="form-control"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  name="collateralFirstNeighbourMobno"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>First Neighbour Address</label>
                <Field
                  type="text"
                  name="collateralFirstNeighbourAddress"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralFirstNeighbourAddress"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>First Neighbour How Long Know</label>
                <Field
                  type="text"
                  name="collateralFirstNeighbourHowlongknow"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralFirstNeighbourHowlongknow"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>First Neighbour Behaviour</label>
                <Field
                  type="text"
                  name="collateralFirstNeighbourBehaviour"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralFirstNeighbourBehaviour"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>First Neighbour Negative Behaviour</label>
                <Field
                  type="text"
                  name="collateralFirstNeighbourNegativeBehaviour"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralFirstNeighbourNegativeBehaviour"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>First Neighbour Any Illegal Activity</label>
                <Field
                  type="text"
                  name="collateralFirstNeighbourAnyIllegalActivity"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralFirstNeighbourAnyIllegalActivity"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Second Neighbour Name</label>
                <Field
                  type="text"
                  name="collateralSecondNeighbourName"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralSecondNeighbourName"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Second Neighbour Mob No</label>
                <Field
                  type="number"
                  name="collateralSecondNeighbourMobno"
                  className="form-control"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  name="collateralSecondNeighbourMobno"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Second Neighbour Address</label>
                <Field
                  type="text"
                  name="collateralSecondNeighbourAddress"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralSecondNeighbourAddress"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Second Neighbour How Long Know</label>
                <Field
                  type="text"
                  name="collateralSecondNeighbourHowlongknow"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralSecondNeighbourHowlongknow"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Second Neighbour Behaviour</label>
                <Field
                  type="text"
                  name="collateralSecondNeighbourBehaviour"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralSecondNeighbourBehaviour"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Second Neighbour Negative Behaviour</label>
                <Field
                  type="text"
                  name="collateralSecondNeighbourNegativeBehaviour"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralSecondNeighbourNegativeBehaviour"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Second Neighbour Any Illegal Activity</label>
                <Field
                  type="text"
                  name="collateralSecondNeighbourAnyIllegalActivity"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralSecondNeighbourAnyIllegalActivity"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Aditional Check Name</label>
                <Field
                  type="text"
                  name="collateralAditionalCheckName"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAditionalCheckName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Aditional Check Mob No</label>
                <Field
                  type="number"
                  name="collateralAditionalCheckMobno"
                  className="form-control"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  name="collateralAditionalCheckMobno"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Aditional Check Address</label>
                <Field
                  type="text"
                  name="collateralAditionalCheckAddress"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAditionalCheckAddress"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Aditional Check Feedback How Long Know</label>
                <Field
                  type="text"
                  name="collateralAditionalCheckFeedbackHowlongknow"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAditionalCheckFeedbackHowlongknow"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Aditional Check Feedback Behaviour</label>
                <Field
                  type="text"
                  name="collateralAditionalCheckFeedbackBehaviour"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAditionalCheckFeedbackBehaviour"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Aditional Check Negative Behaviour</label>
                <Field
                  type="text"
                  name="collateralAditionalCheckNegativeBehaviour"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAditionalCheckNegativeBehaviour"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Aditional Check Any Illegal Activity</label>
                <Field
                  type="text"
                  name="collateralAditionalCheckAnyIllegalActivity"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAditionalCheckAnyIllegalActivity"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Details Remark</label>
                <Field
                  type="text"
                  name="collateralDetailsRemark"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralDetailsRemark"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Final Status</label>
                <Field
                  as="select"
                  id="collateralFinalStatus"
                  name="collateralFinalStatus"
                  className="form-control"
                >
                  <option value="">Select Final Status</option>
                  <option value="Positive">Positive</option>
                  <option value="Negative">Negative</option>
                  <option value="Refer to Credit">Refer to Credit</option>
                </Field>

                <ErrorMessage
                  name="collateralFinalStatus"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Verifier Name</label>
                <Field
                  type="text"
                  name="collateralVerifierName"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralVerifierName"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Manager Name</label>
                <Field
                  type="text"
                  name="collateralManagerName"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralManagerName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Agency Name</label>
                <Field
                  type="text"
                  name="collateralAgencyName"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAgencyName"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Verification Status</label>
                <Field
                  type="text"
                  name="collateralVerificationStatus"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralVerificationStatus"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                <label>Any Match Found in Negative Database</label>
                <Field
                  type="text"
                  name="collateralAnyMatchFoundInNegativeDatabase"
                  className="form-control"
                />
                <ErrorMessage
                  name="collateralAnyMatchFoundInNegativeDatabase"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ApprovedUpdateCollateral;
