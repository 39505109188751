import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../Config";
import LoanDetails from "./LoanDetails";

export default function SearchDetails({ keyName, searchInput }) {
  const [data, SetData] = useState([]);
  const [lid, setLid] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    searchHandler();
  }, []);

  const searchHandler = () => {
    axios
      .request(`${APIURL}/loanCardDetails?${keyName}=${searchInput}`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response", response);
        SetData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {show ? (
        <>
          <div>
            <LoanDetails lid={lid} />
          </div>
        </>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Name</th>
                  <th>Mobile No </th>
                  <th>Email</th>
                  <th>PanCard</th>
                  <th>APP Id </th>
                  <th>Loan Id </th>
                  <th>Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.mobileno}</td>
                    <td>{item.email}</td>
                    <td>{item.pancard}</td>
                    <td>{item.appclientid}</td>
                    <td
                      style={{
                        textAlign: "center",
                        whiteSpace: "normal",
                        fontSize: "15px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (item.loanid) {
                          setLid(item.loanid);
                          setShow(true);
                        }
                      }}
                    >
                      {item.loanid}
                    </td>
                    <td>{item.status}</td>
                    <td>{item.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
