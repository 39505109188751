import SideBar from "./SideBar";
import StpSideBar from "../Stp/StpSideBar";
import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Navbar from "../Pages/Navbar/Navbar";

function Dashboard() {
  const [isLoader, setIsLoader] = useState(false);
  const fileInputRef = useRef(null);

  const LoginSchema = Yup.object().shape({
    file: Yup.mixed().required("A file is required"),
  });
  return (
    <>
      <div>
        <Navbar />
        <SideBar />
      </div>
    </>
  );
}

export default Dashboard;
