import React, { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { useLocation } from "react-router-dom";

function About() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Navbar />
      <div className="">
        <div className="container">
          <div className="px-md-5">
            <div className="card bg-light">
              <div
                className="h4 text-center"
                style={{
                  backgroundColor: "#66a725",
                  color: "#fff",
                  padding: 4,
                  borderTopLeftRadius: "0.375rem",
                  borderTopRightRadius: "0.375rem",
                }}
              >
                About us
              </div>
              <div className=" p-4">
                <div>
                  <h3 className="mt-3" style={{ fontSize: "20px" }}>
                    Welcome to Ontap Technologies!
                  </h3>
                  <h6 className=" mt-4" style={{ fontWeight: "400" }}>
                    Ontap Technologies is an innovative startup founded by Anju
                    and Yogen Khosla, seasoned professionals with decades of
                    experience in the finance industry. Our flagship product,
                    Salarytap, is designed to empower employees by providing
                    them with the financial flexibility to access a portion of
                    their earned wages before payday, helping to bridge the gap
                    between paychecks.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Yogen Khosla, co-founder of Ontap Technologies, brings a
                    wealth of expertise to the table. He is also the Managing
                    Director of Capital Trust Limited, a publicly listed
                    Non-Banking Financial Company (NBFC). Under his leadership,
                    Capital Trust Limited has achieved significant milestones
                    and established a strong presence in the financial sector.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    At Ontap Technologies, we are committed to leveraging our
                    extensive industry knowledge to create innovative financial
                    solutions that benefit both employees and employers. Our
                    mission is to enhance financial well-being, reduce stress,
                    and improve productivity in the workplace through
                    user-friendly and efficient services.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Join us on our journey to revolutionize the way employees
                    access their earned wages and help us create a financially
                    happier environment.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
