import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GFLogo from "../../../image/GFLogo.png";

const Navbar = () => {
  const history = useNavigate();
  const [activeLink, setActiveLink] = useState("");

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
    history("/greenfinch/home");
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/greenfinch/home") {
      setActiveLink("home");
    }
  }, []);

  const SignOut = () => {
    history("/greenfinch/login");
    localStorage.clear();
  };

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-light border border-bottom">
        <div class="container-fluid">
          <img
            src={GFLogo}
            alt="wrong"
            onClick={() => history("/greenfinch/dashboard")}
            style={{ height: "55px", width: "200px" }}
          />
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item"></li>
              <li class="nav-item"></li>
              <li class="nav-item dropdown">
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown"></ul>
              </li>
            </ul>
            <form class="d-flex">
              <div class="input-group mb-3">
                <input
                  type="search"
                  class="form-control me-2"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <span class="input-group-text" id="basic-addon2">
                  Search
                </span>
              </div>
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li>
                  <a
                    class={`nav-link nav-itm text-black mb-1 rounded ${
                      activeLink === "home" ? "active-home" : ""
                    }`}
                    aria-current="page"
                    onClick={() => handleSetActiveLink("home")}
                  >
                    Home
                  </a>
                </li>
                <li
                  className="nav-link nav-itm text-black mb-1"
                  style={{
                    padding: "8px 24px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    width: "max-content",
                  }}
                  onClick={() => SignOut()}
                >
                  Log Out
                </li>
              </ul>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
