import React, { useEffect, useState } from "react";

function FinanceUnpaidEmployees() {
  const [month, setMonth] = useState(0);
  const [months, setMonths] = useState([]);
  const getMonthName = (date) =>
    date.toLocaleString("default", { month: "long" });

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();

    const monthsArray = [];
    for (let i = 0; i < 3; i++) {
      const monthDate = new Date(currentYear, now.getMonth() - i, 1);
      const monthName = getMonthName(monthDate);
      monthsArray.push({
        name: `${monthName} ${currentYear}`,
        value: i.toString(),
      });
    }

    setMonths(monthsArray);
    setMonth("0");
  }, []);
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Unpaid Employees
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="mx-md-3 d-flex flex-row flex-md-row align-items-center justify-content-end">
                    <div>
                      <select
                        className="form-select mx-md-2 mb-md-0"
                        id="lang"
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                      >
                        {months.map(({ name, value }) => (
                          <option key={value} value={value}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="ml-md-2">
                      <button
                        className="btn btn-success border"
                        style={{ background: "#66a725", color: "#fff" }}
                        // onClick={disbursementReport}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Employer name/code</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Limit assigned</th>
                          <th>Unpaid</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {manageEmp?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.name || "-"}</td>
                        <td>{item.status || "-"}</td>
                        <td
                          style={{
                            textAlign: "center",
                            whiteSpace: "normal",
                            fontSize: "15px",
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setEmpDetails(true);
                          }}
                        >
                          {item.lid || "-"}
                        </td>
                        <td>{item.mobile || "-"}</td>
                        <td>{item.limitassigned || "-"}</td>
                        <td>{item.limitused || "-"}</td>
                      </tr>
                    ))} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceUnpaidEmployees;
