import React from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

function ContactUS() {
  return (
    <>
      <Navbar />
      <div className="container mt-3 px-md-5">
        <div className="card bg-light ">
          <div
            className="card-header h4 text-center"
            style={{
              backgroundColor: "#66a725",
              color: "white",
              padding: 4,
            }}
          >
            Contact
          </div>
          <div className="card-body">
            <div className="d-flex container justify-content-center mt-5">
              <div className="text-center  m-1 p-2">
                <div className="d-flex mt-5 justify-content-center">
                  <div style={{ width: "18%" }}>
                    <svg
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium mr-2 css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CorporateFareIcon"
                      style={{ fontSize: 40 }}
                    >
                      <path d="M12 7V3H2v18h20V7H12zm-2 12H4v-2h6v2zm0-4H4v-2h6v2zm0-4H4V9h6v2zm0-4H4V5h6v2zm10 12h-8V9h8v10zm-2-8h-4v2h4v-2zm0 4h-4v2h4v-2z" />
                    </svg>
                  </div>

                  <h2 className="">Ontap Technologies Pvt Limited.</h2>
                </div>

                
                

                
            </div>
          
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12"><div className="text-center mt-5 d-flex justify-content-center align-items-center">
                  <i
                    className="fa-regular fa-envelope fs-3"
                    style={{ color: "#66a725" }}
                  ></i>
                  &nbsp;
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                      fontWeight: "600",
                    }}
                  >
                    Email
                  </h5>
                </div>
                
                <div className="text-center mt-2">
                  <h4 style={{ fontFamily: "PostGrotesk" }}>
                    ontaptechnologies@gmail.com
                  </h4>
                </div></div>
              <div className="col-lg-4 col-md-4 col-sm-12"><div className="text-center mt-5 d-flex justify-content-center align-items-center">
                  <i
                    className="fa-solid fa-phone fs-3"
                    style={{ color: "#66a725" }}
                  ></i>
                  &nbsp;
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                      fontWeight: "600",
                    }}
                  >
                    Call
                  </h5>
                </div>
                
                <div className="text-center mt-2">
                  <h4 style={{ fontFamily: "PostGrotesk" }}> +91 9953437505</h4>
                </div></div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="text-center mt-5 d-flex justify-content-center align-items-center">
                  <i
                    className="fa-solid fa-mobile-screen-button fs-3"
                    style={{ color: "#66a725" }}
                  ></i>
                  &nbsp;
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                      fontWeight: "600",
                    }}
                  >
                    Naresh Koul
                  </h5>
                </div>
                
                <div className="text-center mt-2">
                  <h4 style={{ fontFamily: "PostGrotesk" }}> +91 9871978822</h4>
                </div>
              </div>
              </div>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="text-center mt-5 d-flex justify-content-center align-items-center">
                  <i
                    className="fa-solid fa-globe fs-3"
                    style={{ color: "#66a725" }}
                  ></i>
                  &nbsp;
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                      fontWeight: "600",
                    }}
                  >
                    Website
                  </h5>
                </div>
                
                <div className="text-center mt-1">
                  <h4 style={{ fontFamily: "PostGrotesk" }}>
                    www.salarytap.in
                  </h4>
                </div>
                <div className="text-center mt-5 ">
                  <h5
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                    }}
                  ></h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="text-center mt-5 d-flex justify-content-center align-items-center">
                  <i
                    className="fa-brands fa-google-play fs-3"
                    style={{ color: "#66a725" }}
                  ></i>
                  &nbsp;
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                      fontWeight: "600",
                    }}
                  >
                    Google Play Store
                  </h5>
                </div>
                
                <div className="text-center mt-1">
                  <h4 style={{ fontFamily: "PostGrotesk" }}>Salarytap</h4>
                </div>
                <div className="text-center mt-5 ">
                  <h5
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                    }}
                  ></h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="text-center mt-5 d-flex justify-content-center align-items-center mb-1">
                  <i
                    className="fa-brands fa-facebook fs-3"
                    style={{ color: "#66a725" }}
                  ></i>
                  &nbsp;
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                      fontWeight: "600",
                    }}
                  >
                    Facebook
                  </h5>
                </div>
                
                <div className="text-center mt-2 ">
                  <h4 style={{ fontFamily: "PostGrotesk" }}>Salarytap</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="text-center mt-5 d-flex justify-content-center align-items-center mb-1">
                  <i
                    className="fa-brands fa-instagram fs-3"
                    style={{ color: "#66a725" }}
                  ></i>
                  &nbsp;
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "PostGrotesk",
                      color: "#66a725",
                      fontWeight: "600",
                    }}
                  >
                    Instagram
                  </h5>
                </div>
                
                <div className="text-center mt-2 ">
                  <h4 style={{ fontFamily: "PostGrotesk" }}>Salarytap</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default ContactUS;
