import React from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";

function ApprovedUpdateEmployment({ data, setFormStates, formStates }) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const initialValues = {
    clientId: data?.clientId || "",
    doneBy: data?.doneBy || "",
    doneOn: data?.doneOn || "",
    nameOfOrganisation: data?.nameOfOrganisation || "",
    addressOfOrganisation: data?.addressOfOrganisation || "",
    addressConfirmed: data?.addressConfirmed || "",
    businessLocationType: data?.businessLocationType || "",
    nameOfOfficialMet: data?.nameOfOfficialMet || "",
    designationOfOfficialMet: data?.designationOfOfficialMet || "",
    employeeIdOfApplicant: data?.employeeIdOfApplicant || "",
    applicantDesignation: data?.applicantDesignation || "",
    applicantDepartment: data?.applicantDepartment || "",
    applicantGrade: data?.applicantGrade || "",
    salaryDrawn: data?.salaryDrawn || "",
    dateOfJoining: data?.dateOfJoining || "",
    yearsOfServiceCompleted: data?.yearsOfServiceCompleted || "",
    pfNumberOfApplicant: data?.pfNumberOfApplicant || "",
    typeOfJob: data?.typeOfJob || "",
    natureOfJob: data?.natureOfJob || "",
    natureOfBusiness: data?.natureOfBusiness || "",
    directNumberOfOfficial: data?.directNumberOfOfficial || "",
    mobileNumberOfOfficial: data?.mobileNumberOfOfficial || "",
    visitingCardObtained: data?.visitingCardObtained || "",
    prominentLandmark: data?.prominentLandmark || "",
    localityOfOffice: data?.localityOfOffice || "",
    typeOfOffice: data?.typeOfOffice || "",
    entryAllowedInOffice: data?.entryAllowedInOffice || "",
    businessSignBoardSeen: data?.businessSignBoardSeen || "",
    businessActivityLevel: data?.businessActivityLevel || "",
    numberOfEmployeesSighted: data?.numberOfEmployeesSighted || "",
    numberOfCustomersSighted: data?.numberOfCustomersSighted || "",
    carpetAreaInSqFtOfOffice: data?.carpetAreaInSqFtOfOffice || "",
    employmentCheckByOtherName: data?.employmentCheckByOtherName || "",
    completeComments: data?.completeComments || "",
    employmentStatus: data?.employmentStatus || "",
    completeRemark: data?.completeRemark || "",
    dedupeStatus: data?.dedupeStatus || "",
    dedupeStatusRemark: data?.dedupeStatusRemark || "",
    employmentVerificationStatus: data?.employmentVerificationStatus || "",
    updateStatus: data?.updateStatus || "",
    updatedBy: data?.updatedBy || "",
    updatedDate: data?.updatedDate || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        console.log(values);
        axios
          .request(`${APIURL}/v2/api/cpv/updateCpvGuarantorVerification`, {
            method: "POST",
            data: values,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then(async (response) => {
            console.log("response::: ", response);
            toast.success(response?.data);
            setFormStates(!formStates.employment);
          })
          .catch((error) => {
            console.log(error);
            // setIsLoader(false);
          });
      }}
    >
      {() => (
        <Form>
          {/* First Row */}
          <div className="row">
            <div className="col-6">
              <label htmlFor="clientId">Client ID</label>
              <Field id="clientId" name="clientId" className="form-control" />
            </div>
            <div className="col-6">
              <label htmlFor="doneBy">Done By</label>
              <Field id="doneBy" name="doneBy" className="form-control" />
            </div>
          </div>

          {/* Second Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="doneOn">Done On</label>
              <Field
                id="doneOn"
                name="doneOn"
                type="datetime-local"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="nameOfOrganisation">Name of Organisation</label>
              <Field
                id="nameOfOrganisation"
                name="nameOfOrganisation"
                className="form-control"
              />
            </div>
          </div>

          {/* Third Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="addressOfOrganisation">
                Address of Organisation
              </label>
              <Field
                id="addressOfOrganisation"
                name="addressOfOrganisation"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="addressConfirmed">Address Confirmed</label>
              <Field
                id="addressConfirmed"
                name="addressConfirmed"
                className="form-control"
              />
            </div>
          </div>

          {/* Fourth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="businessLocationType">
                Business Location Type
              </label>
              <Field
                id="businessLocationType"
                name="businessLocationType"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="nameOfOfficialMet">Name of Official Met</label>
              <Field
                id="nameOfOfficialMet"
                name="nameOfOfficialMet"
                className="form-control"
              />
            </div>
          </div>

          {/* Fifth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="designationOfOfficialMet">
                Designation of Official Met
              </label>
              <Field
                id="designationOfOfficialMet"
                name="designationOfOfficialMet"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="employeeIdOfApplicant">
                Employee ID of Applicant
              </label>
              <Field
                id="employeeIdOfApplicant"
                name="employeeIdOfApplicant"
                className="form-control"
              />
            </div>
          </div>

          {/* Sixth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="applicantDesignation">
                Applicant Designation
              </label>
              <Field
                id="applicantDesignation"
                name="applicantDesignation"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="applicantDepartment">Applicant Department</label>
              <Field
                id="applicantDepartment"
                name="applicantDepartment"
                className="form-control"
              />
            </div>
          </div>

          {/* Seventh Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="applicantGrade">Applicant Grade</label>
              <Field
                id="applicantGrade"
                name="applicantGrade"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="salaryDrawn">Salary Drawn</label>
              <Field
                id="salaryDrawn"
                name="salaryDrawn"
                className="form-control"
              />
            </div>
          </div>

          {/* Eighth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="dateOfJoining">Date of Joining</label>
              <Field
                id="dateOfJoining"
                name="dateOfJoining"
                type="date"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="yearsOfServiceCompleted">
                Years of Service Completed
              </label>
              <Field
                id="yearsOfServiceCompleted"
                name="yearsOfServiceCompleted"
                className="form-control"
              />
            </div>
          </div>

          {/* Ninth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="pfNumberOfApplicant">
                PF Number of Applicant
              </label>
              <Field
                id="pfNumberOfApplicant"
                name="pfNumberOfApplicant"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="typeOfJob">Type of Job</label>
              <Field id="typeOfJob" name="typeOfJob" className="form-control" />
            </div>
          </div>

          {/* Tenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="natureOfJob">Nature of Job</label>
              <Field
                id="natureOfJob"
                name="natureOfJob"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="natureOfBusiness">Nature of Business</label>
              <Field
                id="natureOfBusiness"
                name="natureOfBusiness"
                className="form-control"
              />
            </div>
          </div>

          {/* Eleventh Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="directNumberOfOfficial">
                Direct Number of Official
              </label>
              <Field
                id="directNumberOfOfficial"
                name="directNumberOfOfficial"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="mobileNumberOfOfficial">
                Mobile Number of Official
              </label>
              <Field
                id="mobileNumberOfOfficial"
                name="mobileNumberOfOfficial"
                className="form-control"
              />
            </div>
          </div>

          {/* Twelfth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="visitingCardObtained">
                Visiting Card Obtained
              </label>
              <Field
                id="visitingCardObtained"
                name="visitingCardObtained"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="prominentLandmark">Prominent Landmark</label>
              <Field
                id="prominentLandmark"
                name="prominentLandmark"
                className="form-control"
              />
            </div>
          </div>

          {/* Thirteenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="localityOfOffice">Locality of Office</label>
              <Field
                id="localityOfOffice"
                name="localityOfOffice"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="typeOfOffice">Type of Office</label>
              <Field
                id="typeOfOffice"
                name="typeOfOffice"
                className="form-control"
              />
            </div>
          </div>

          {/* Fourteenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="entryAllowedInOffice">
                Entry Allowed in Office
              </label>
              <Field
                id="entryAllowedInOffice"
                name="entryAllowedInOffice"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="businessSignBoardSeen">
                Business Sign Board Seen
              </label>
              <Field
                id="businessSignBoardSeen"
                name="businessSignBoardSeen"
                className="form-control"
              />
            </div>
          </div>

          {/* Fifteenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="businessActivityLevel">
                Business Activity Level
              </label>
              <Field
                id="businessActivityLevel"
                name="businessActivityLevel"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="numberOfEmployeesSighted">
                Number of Employees Sighted
              </label>
              <Field
                id="numberOfEmployeesSighted"
                name="numberOfEmployeesSighted"
                className="form-control"
              />
            </div>
          </div>

          {/* Sixteenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="numberOfCustomersSighted">
                Number of Customers Sighted
              </label>
              <Field
                id="numberOfCustomersSighted"
                name="numberOfCustomersSighted"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="carpetAreaInSqFtOfOffice">
                Carpet Area (Sq Ft)
              </label>
              <Field
                id="carpetAreaInSqFtOfOffice"
                name="carpetAreaInSqFtOfOffice"
                className="form-control"
              />
            </div>
          </div>

          {/* Seventeenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="employmentCheckByOtherName">
                Employment Check by Other Name
              </label>
              <Field
                id="employmentCheckByOtherName"
                name="employmentCheckByOtherName"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="completeComments">Complete Comments</label>
              <Field
                id="completeComments"
                name="completeComments"
                className="form-control"
              />
            </div>
          </div>

          {/* Eighteenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="employmentStatus">Employment Status</label>
              <Field
                id="employmentStatus"
                name="employmentStatus"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="completeRemark">Complete Remark</label>
              <Field
                id="completeRemark"
                name="completeRemark"
                className="form-control"
              />
            </div>
          </div>

          {/* Nineteenth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="dedupeStatus">Dedupe Status</label>
              <Field
                id="dedupeStatus"
                name="dedupeStatus"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="dedupeStatusRemark">Dedupe Status Remark</label>
              <Field
                id="dedupeStatusRemark"
                name="dedupeStatusRemark"
                className="form-control"
              />
            </div>
          </div>

          {/* Twentieth Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="employmentVerificationStatus">
                Employment Verification Status
              </label>
              <Field
                id="employmentVerificationStatus"
                name="employmentVerificationStatus"
                className="form-control"
              />
            </div>
            <div className="col-6">
              <label htmlFor="updateStatus">Update Status</label>
              <Field
                id="updateStatus"
                name="updateStatus"
                className="form-control"
              />
            </div>
          </div>

          {/* Twenty-First Row */}
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="updatedBy">Updated By</label>
              <Field id="updatedBy" name="updatedBy" className="form-control" />
            </div>
            <div className="col-6">
              <label htmlFor="updatedDate">Updated Date</label>
              <Field
                id="updatedDate"
                name="updatedDate"
                type="datetime-local"
                className="form-control"
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="row mt-3">
            <div className="col-12">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ApprovedUpdateEmployment;
