import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Config";
import Pagination from "../../Pagination/Pagination";

function RegisteredCorporates() {
  const [isLoader, setIsLoader] = useState(false);
  const [month, setMonth] = useState(0);
  const [months, setMonths] = useState([]);
  const [data, setData] = useState([]);
  const [showPrePage, setShowPrePage] = useState(100);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const getMonthName = (date) =>
    date.toLocaleString("default", { month: "long" });

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();

    const monthsArray = [];
    for (let i = 0; i < 3; i++) {
      const monthDate = new Date(currentYear, now.getMonth() - i, 1);
      const monthName = getMonthName(monthDate);
      monthsArray.push({
        name: `${monthName} ${currentYear}`,
        value: i.toString(),
      });
    }

    setMonths(monthsArray);
    setMonth("0");
  }, []);
  useEffect(() => {
    getRegisteredemployer();
  }, []);
  const getRegisteredemployer = () => {
    setIsLoader(true);
    axios
      .request(`${APIURL}/registeredemployer/${month}`, {
        method: "GET",
      })
      .then((response) => {
        setIsLoader(false);
        console.log("response", response);
        setData(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoader(false);
      });
  };
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item"
              id="registered-corporates-tab"
              data-bs-toggle="tab"
              data-bs-target="#registered-corporates"
              type="button"
              role="tab"
              aria-controls="registered-corporates"
              aria-selected="true"
            >
              Registered Employer
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="registered-corporates"
            role="tabpanel"
            aria-labelledby="registered-corporates-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="mx-md-3 d-flex flex-row flex-md-row align-items-center justify-content-end">
                    <div>
                      <select
                        className="form-select mx-md-2 mb-md-0"
                        id="lang"
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                      >
                        {months.map(({ name, value }) => (
                          <option key={value} value={value}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="ml-md-2">
                      <button
                        className="btn btn-success border"
                        style={{ background: "#66a725", color: "#fff" }}
                        onClick={getRegisteredemployer}
                      >
                        {isLoader ? (
                          <div
                            className="spinner-border text-success"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Assured / Non Assured</th>
                          <th>Employer name/Code</th>
                          <th>No. of employees uploaded</th>
                          <th>No. of employees Approved</th>
                          <th>No. of employees Rejected</th>
                          <th>Limit assigned amt</th>
                          <th>Limit Utilized</th>
                          <th>Utilization % </th>
                          <th>Unpaid Count</th>
                          <th>Unpaid Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item.model }</td>
                      <td>{item.empname }</td>
                      <td>{item.uploaded}</td>
                      <td>{item.approved }</td>
                      <td>{item.rejected }</td>
                      <td>{item.limitassigned }</td>
                      <td>{item.limitused }</td>
                      <td>{item.utilization }</td>
                      <td>{item.unpaid_count }</td>
                      <td>{item.unpaid_amount }</td>
                    </tr>
                  ))}
                      </tbody>
                    </table>
                  </div>
                  {data && data.length !== 0 && (
                    <Pagination
                      showPrePage={showPrePage}
                      onPaginationChange={onPaginationChange}
                      total={data.length}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisteredCorporates;
