import React from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";

function ApprovedUpdateGuarantorRv({ data, setFormStates, formStates }) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const initialValues = {
    clientId: data?.clientId || "",
    addressConfirmed: data?.addressConfirmed || "",
    nearestLandmark: data?.nearestLandmark || "",
    nameOfPersonMet: data?.nameOfPersonMet || "",
    contactNoOfPersonMet: data?.contactNoOfPersonMet || "",
    relationWithApplicant: data?.relationWithApplicant || "",
    sinceHowLongKnownApplicant: data?.sinceHowLongKnownApplicant || "",
    youAreGuarantorInDeal: data?.youAreGuarantorInDeal || "",
    detailRemarks: data?.detailRemarks || "",
    finalStatus: data?.finalStatus || "",
    verifiersName: data?.verifiersName || "",
    agencyManagerNameAndSign: data?.agencyManagerNameAndSign || "",
    guarantorVerificationStatus: data?.guarantorVerificationStatus || "",
    doneOn: data?.doneOn || "",
    doneBy: data?.doneBy || "",
    latOfProperty: data?.latOfProperty || "",
    longOfProperty: data?.longOfProperty || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        console.log(values);
        axios
          .request(`${APIURL}/v2/api/cpv/updateCpvGuarantorVerification`, {
            method: "POST",
            data: values,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then(async (response) => {
            console.log("response::: ", response);
            toast.success(response?.data);
            setFormStates(!formStates.guarantorRv);
          })
          .catch((error) => {
            console.log(error);
            // setIsLoader(false);
          });
      }}
    >
      {() => (
        <Form>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="clientId">Client ID</label>
              <Field
                id="clientId"
                name="clientId"
                type="number"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="addressConfirmed">Address Confirmed</label>
              <Field
                id="addressConfirmed"
                name="addressConfirmed"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="nearestLandmark">Nearest Landmark</label>
              <Field
                id="nearestLandmark"
                name="nearestLandmark"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="nameOfPersonMet">Name of Person Met</label>
              <Field
                id="nameOfPersonMet"
                name="nameOfPersonMet"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="contactNoOfPersonMet">
                Contact No. of Person Met
              </label>
              <Field
                id="contactNoOfPersonMet"
                name="contactNoOfPersonMet"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="relationWithApplicant">
                Relation with Applicant
              </label>
              <Field
                id="relationWithApplicant"
                name="relationWithApplicant"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="sinceHowLongKnownApplicant">
                Since How Long Known Applicant
              </label>
              <Field
                id="sinceHowLongKnownApplicant"
                name="sinceHowLongKnownApplicant"
                type="number"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="youAreGuarantorInDeal">
                Are you the Guarantor?
              </label>
              <Field
                id="youAreGuarantorInDeal"
                name="youAreGuarantorInDeal"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="detailRemarks">Detail Remarks</label>
              <Field
                id="detailRemarks"
                name="detailRemarks"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="finalStatus">Final Status</label>
              <Field
                id="finalStatus"
                name="finalStatus"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="verifiersName">Verifier's Name</label>
              <Field
                id="verifiersName"
                name="verifiersName"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="agencyManagerNameAndSign">
                Agency Manager Name & Sign
              </label>
              <Field
                id="agencyManagerNameAndSign"
                name="agencyManagerNameAndSign"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="guarantorVerificationStatus">
                Guarantor Verification Status
              </label>
              <Field
                id="guarantorVerificationStatus"
                name="guarantorVerificationStatus"
                type="number"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="doneOn">Done On</label>
              <Field
                id="doneOn"
                name="doneOn"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="latOfProperty">Latitude of Property</label>
              <Field
                id="latOfProperty"
                name="latOfProperty"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
              <label htmlFor="longOfProperty">Longitude of Property</label>
              <Field
                id="longOfProperty"
                name="longOfProperty"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary mt-3">
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default ApprovedUpdateGuarantorRv;
