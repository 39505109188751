import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../../image/Salarytap_logo_horizontal-light_800X239_colored.jpg";
import { useNavigate } from "react-router-dom";
import Leads from "./Page/Leads";
import CorporateOnboarding from "./Page/EmployerCreditAppraisal";
import RegisteredCorporates from "./Page/RegisteredCorporates";
import NonAssuredRegisteredCorporates from "./Page/NonAssuredRegisteredCorporates";
import SalarytapAccountAdmin from "./Page/SalarytapAccountAdmin";
import Funnel from "./Page/Funnel";
import CorporateOwnership from "./Page/CorporateOwnership";
import FinanceReport from "./Page/FinanceReport";
import Helpline from "./Page/Support";
import Feedback from "./Page/Feedback";
import Search from "./Page/Search";
import CreditAppraisalForMagic from "./Page/CreditAppraisalForMagic";
import EmployerCreditAppraisal from "./Page/EmployerCreditAppraisal";
import EmployerCustomizationRequest from "./Page/EmployerCustomizationRequest";
import EmployeeDetail from "./Page/EmployeeDetail";
import AppHelpline from "./Page/AppHelpline";
import AppFeedback from "./Page/AppFeedback";
import Support from "./Page/Support";
import CollectionReport from "./Page/CollectionReport";
import FinanceAccount from "./Page/FinanceAccount";
import CreditPolicy from "./Page/CreditPolicy";
import FinancierEmployercommercials from "./Page/FinancierEmployercommercials";
import FinanceUnpaidEmployees from "./Page/FinanceUnpaidEmployees";
import TripartiteAgreements from "./Page/TripartiteAgreements";
import axios from "axios";
import { APIURL } from "../../Config";
import LoanDetails from "../LoanDetails";
import StpDisbursementReport from "./Page/StpDisbursementReport";
import FinanceDisbursementReport from "./Page/FinanceDisbursementReport";
import SearchDetails from "../SearchDetails";
import OpenMarket from "./Page/OpenMarket";

function StpSideBar() {
  const history = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [key, setKey] = useState("Leads");
  const [isLoader, setIsLoader] = useState(false);
  const [searchTable, SetSearchTable] = useState(false);

  const [keyName, setKeyName] = useState("mobile");
  const [searchInput, setSearchInput] = useState("");
  const [lid, setLid] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth > 768) {
        setSidebarVisible(true);
      } else {
        setSidebarVisible(false);
      }
    };
    setIsMobile(window.innerWidth < 768);
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleClick = (index, tabKey, tabId) => {
    setActiveIndex(index);
    setKey(tabKey);
    setIsOpen(!isOpen);
    if (window.innerWidth <= 768) {
      setSidebarVisible(false);
    }
  };
  const SignOut = () => {
    history("/");
    localStorage.clear();
  };

  const typeOf = localStorage.getItem("type");

  useEffect(() => {
    if (typeOf == "CA_Admin") {
      setActiveIndex(11);
      setKey("CreditAppraisalforMagic");
    }
    if (typeOf == "Finance_Admin") {
      setActiveIndex(17);
      setKey("DisbursementReport");
    }
    if (typeOf == "Account_Admin") {
      setKey("Leads");
    }
    if (typeOf == "Salarytap_admin") {
      setKey("Leads");
    }
  }, []);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const searchHandler = () => {
    SetSearchTable(true);
  };

  return (
    <div className="d-flex bg-light">
      {sidebarVisible && (
        <div
          className="d-flex flex-column flex-shrink-0 p-3 border sidebar-toggle"
          style={{
            width: 280,
            height: "100vh",
            background: "#fff",
            position: "sticky",
            top: "0",
          }}
        >
          <div
            className="d-flex"
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "75%" }}>
              <img src={logo} width="100%" height="100%" alt="" />
            </div>
          </div>
          <hr />
          {typeOf == "Salarytap_admin" && (
            <ul
              className="nav nav-pills flex-column mb-auto"
              style={{ overflow: "auto", flexWrap: "nowrap" }}
            >
              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 0 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 0 ? "#a6df6d " : "transparent",
                  color: activeIndex === 0 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(0);
                  setKey("Leads");
                }}
              >
                Leads
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 1 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 1 ? "#a6df6d " : "transparent",
                  color: activeIndex === 1 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(1);
                  setKey("EmployerCreditAppraisal");
                }}
              >
                Employer Credit Appraisal
              </li>
              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 12 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 12 ? "#a6df6d " : "transparent",
                  color: activeIndex === 12 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(12);
                  setKey("Employercustomizationrequest");
                }}
              >
                Employer customization request
              </li>
              {/* <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 3 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 3 ? "#a6df6d " : "transparent",
                  color: activeIndex === 3 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(3);
                  setKey("NonAssuredRegisteredCorporates");
                }}
              >
                Non Assured Registered Employer
              </li> */}
              <li
                className={`nav-link nav-itm  mb-1 dropdown ${
                  activeIndex === 2 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 2 ? "#a6df6d " : "transparent",
                  color: activeIndex === 2 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(2);
                  setKey("RegisteredCorporates");
                }}
              >
                Registered Employer
              </li>

              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 4 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 4 ? "#a6df6d " : "transparent",
                  color: activeIndex === 4 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(4);
                  setKey("SalarytapAccountAdmin");
                }}
              >
                Salarytap Account Admin
              </li>
              {/* <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 13 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 13 ? "#a6df6d " : "transparent",

                  color: activeIndex === 13 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(13);
                  setKey("Employeedetailpage");
                }}
              >
                Employee detail page
              </li> */}
              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 5 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 5 ? "#a6df6d " : "transparent",
                  color: activeIndex === 5 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(5);
                  setKey("Corporateownership");
                }}
              >
                Employer ownership change request
              </li>
              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 14 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 14 ? "#a6df6d " : "transparent",
                  color: activeIndex === 14 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(14);
                  setKey("StpDisbursementReport");
                }}
              >
                Disbursement Report
              </li>

              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 11 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 11 ? "#a6df6d " : "transparent",
                  color: activeIndex === 11 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(11);
                  setKey("CreditAppraisalforMagic");
                }}
              >
                Credit Appraisal
              </li>

              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 7 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 7 ? "#a6df6d " : "transparent",
                  color: activeIndex === 7 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(7);
                  setKey("Funnel");
                }}
              >
                Funnel
              </li>
              <li
                className={`nav-link nav-itm  mb-1 dropdown ${
                  activeIndex === 15 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 15 ? "#a6df6d " : "transparent",
                  color: activeIndex === 15 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(15);
                  setKey("AppHelpline");
                }}
              >
                App Helpline
              </li>
              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 16 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 16 ? "#a6df6d " : "transparent",
                  color: activeIndex === 16 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(16);
                  setKey("AppFeedback");
                }}
              >
                App Feedback
              </li>

              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 8 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 8 ? "#a6df6d " : "transparent",
                  color: activeIndex === 8 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(8);
                  setKey("Support");
                }}
              >
                Support
              </li>
              {/* <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 6 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 6 ? "#a6df6d " : "transparent",
                  color: activeIndex === 6 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(6);
                  setKey("FinanceReport");
                }}
              >
                Finance Report
              </li> */}
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 26 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 26 ? "#a6df6d " : "transparent",
                  color: activeIndex === 26 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // handleClick(26);
                  // setKey("FinanceReport");
                }}
              >
                Employer workspace
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 27 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 27 ? "#a6df6d " : "transparent",
                  color: activeIndex === 27 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // handleClick(27);
                  // setKey("FinanceReport");
                }}
              >
                Financier workspace
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 28 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 28 ? "#a6df6d " : "transparent",
                  color: activeIndex === 28 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(28);
                  setKey("OpenMarket");
                }}
              >
                Manage Open-Market
              </li>
            </ul>
          )}
          {typeOf == "Account_Admin" && (
            <ul
              className="nav nav-pills flex-column mb-auto"
              style={{ overflow: "auto", flexWrap: "nowrap" }}
            >
              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 0 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 0 ? "#a6df6d " : "transparent",
                  color: activeIndex === 0 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(0);
                  setKey("Leads");
                }}
              >
                Leads
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 1 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 1 ? "#a6df6d " : "transparent",
                  color: activeIndex === 1 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(1);
                  setKey("EmployerCreditAppraisal");
                }}
              >
                Employer Credit Appraisal
              </li>
              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 12 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 12 ? "#a6df6d " : "transparent",
                  color: activeIndex === 12 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(12);
                  setKey("Employercustomizationrequest");
                }}
              >
                Employer customization request
              </li>
              {/* <li
              className={`nav-link nav-itm mb-1 dropdown ${
                activeIndex === 3 ? "active" : ""
              }`}
              style={{
                backgroundColor:
                  activeIndex === 3 ? "#a6df6d " : "transparent",
                color: activeIndex === 3 ? "#000" : "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClick(3);
                setKey("NonAssuredRegisteredCorporates");
              }}
            >
              Non Assured Registered Employer
            </li> */}
              <li
                className={`nav-link nav-itm  mb-1 dropdown ${
                  activeIndex === 2 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 2 ? "#a6df6d " : "transparent",
                  color: activeIndex === 2 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(2);
                  setKey("RegisteredCorporates");
                }}
              >
                Registered Employer
              </li>

              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 4 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 4 ? "#a6df6d " : "transparent",
                  color: activeIndex === 4 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(4);
                  setKey("SalarytapAccountAdmin");
                }}
              >
                Salarytap Account Admin
              </li>
              {/* <li
              className={`nav-link nav-itm mb-1 ${
                activeIndex === 13 ? "active" : ""
              }`}
              style={{
                backgroundColor:
                  activeIndex === 13 ? "#a6df6d " : "transparent",

                color: activeIndex === 13 ? "#000" : "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClick(13);
                setKey("Employeedetailpage");
              }}
            >
              Employee detail page
            </li> */}
              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 5 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 5 ? "#a6df6d " : "transparent",
                  color: activeIndex === 5 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(5);
                  setKey("Corporateownership");
                }}
              >
                Employer ownership change request
              </li>
              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 14 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 14 ? "#a6df6d " : "transparent",
                  color: activeIndex === 14 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(14);
                  setKey("StpDisbursementReport");
                }}
              >
                Disbursement Report
              </li>

              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 11 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 11 ? "#a6df6d " : "transparent",
                  color: activeIndex === 11 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(11);
                  setKey("CreditAppraisalforMagic");
                }}
              >
                Credit Appraisal
              </li>

              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 7 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 7 ? "#a6df6d " : "transparent",
                  color: activeIndex === 7 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(7);
                  setKey("Funnel");
                }}
              >
                Funnel
              </li>
              <li
                className={`nav-link nav-itm  mb-1 dropdown ${
                  activeIndex === 15 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 15 ? "#a6df6d " : "transparent",
                  color: activeIndex === 15 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(15);
                  setKey("AppHelpline");
                }}
              >
                App Helpline
              </li>
              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 16 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 16 ? "#a6df6d " : "transparent",
                  color: activeIndex === 16 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(16);
                  setKey("AppFeedback");
                }}
              >
                App Feedback
              </li>

              <li
                className={`nav-link nav-itm mb-1 ${
                  activeIndex === 8 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 8 ? "#a6df6d " : "transparent",
                  color: activeIndex === 8 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(8);
                  setKey("Support");
                }}
              >
                Support
              </li>
              {/* <li
              className={`nav-link nav-itm  mb-1 ${
                activeIndex === 6 ? "active" : ""
              }`}
              style={{
                backgroundColor:
                  activeIndex === 6 ? "#a6df6d " : "transparent",
                color: activeIndex === 6 ? "#000" : "#000",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClick(6);
                setKey("FinanceReport");
              }}
            >
              Finance Report
            </li> */}
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 26 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 26 ? "#a6df6d " : "transparent",
                  color: activeIndex === 26 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // handleClick(26);
                  // setKey("FinanceReport");
                }}
              >
                Employer workspace
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 27 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 27 ? "#a6df6d " : "transparent",
                  color: activeIndex === 27 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // handleClick(27);
                  // setKey("FinanceReport");
                }}
              >
                Financier workspace
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 28 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 28 ? "#a6df6d " : "transparent",
                  color: activeIndex === 28 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(28);
                  setKey("OpenMarket");
                }}
              >
                Manage Open-Market
              </li>
            </ul>
          )}
          {typeOf === "Finance_Admin" && (
            <ul
              className="nav nav-pills flex-column mb-auto"
              style={{ overflow: "auto", flexWrap: "nowrap" }}
            >
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 17 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 17 ? "#a6df6d " : "transparent",
                  color: activeIndex === 17 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(17);
                  setKey("DisbursementReport");
                }}
              >
                Disbursement Report
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 18 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 18 ? "#a6df6d " : "transparent",
                  color: activeIndex === 18 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(18);
                  setKey("CollectionReport");
                }}
              >
                Collection Report
              </li>
              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 11 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 11 ? "#a6df6d " : "transparent",
                  color: activeIndex === 11 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(11);
                  setKey("CreditAppraisalforMagic");
                }}
              >
                Credit Appraisal
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 19 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 19 ? "#a6df6d " : "transparent",
                  color: activeIndex === 19 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(19);
                  setKey("FinanceAccount");
                }}
              >
                Account
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 23 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 23 ? "#a6df6d " : "transparent",
                  color: activeIndex === 23 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(23);
                  setKey("CreditPolicy");
                }}
              >
                Credit Policy
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 20 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 20 ? "#a6df6d " : "transparent",
                  color: activeIndex === 20 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(20);
                  setKey("FinancierEmployercommercials");
                }}
              >
                Financier-Employer commercials
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 21 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 21 ? "#a6df6d " : "transparent",
                  color: activeIndex === 21 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(21);
                  setKey("FinanceUnpaidEmployees");
                }}
              >
                Unpaid Employees
              </li>
              <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 22 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 22 ? "#a6df6d " : "transparent",
                  color: activeIndex === 22 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(22);
                  setKey("TripartiteAgreements");
                }}
              >
                Tripartite agreements with employers
              </li>
              {/* <li
                className={`nav-link nav-itm  mb-1 ${
                  activeIndex === 24 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 24 ? "#a6df6d " : "transparent",
                  color: activeIndex === 24 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(24);
                  setKey("CA");
                }}
              >
                CA
              </li> */}
            </ul>
          )}
          {typeOf === "CA_Admin" && (
            <ul
              className="nav nav-pills flex-column mb-auto"
              style={{ overflow: "auto", flexWrap: "nowrap" }}
            >
              <li
                className={`nav-link nav-itm mb-1 dropdown ${
                  activeIndex === 11 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeIndex === 11 ? "#a6df6d " : "transparent",
                  color: activeIndex === 11 ? "#000" : "#000",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(11);
                  setKey("CreditAppraisalforMagic");
                }}
              >
                Credit Appraisal
              </li>
            </ul>
          )}
          <hr />
          <li
            className="nav-link nav-itm text-black mb-1"
            style={{
              padding: "8px 24px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => SignOut()}
          >
            Log Out
          </li>
        </div>
      )}
      <div
        className="container-fluid flex-grow-1 p-3"
        style={{ height: "auto", overflow: "hidden" }}
      >
        {isMobile ? (
          <>
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div
                className="ml-2 px-2 py-1"
                style={{
                  height: "35px",
                  width: "33px",
                  background: "transparent",
                  color: "#000",
                  cursor: "pointer",
                  border: "1px solid #0000002d",
                  borderRadius: "8px",
                }}
                onClick={() => setSidebarVisible(!sidebarVisible)}
              >
                {sidebarVisible ? (
                  <i className="fa-solid fa-xmark" />
                ) : (
                  <i className="fa-solid fa-bars-staggered" />
                )}
              </div>
              {typeOf == "Salarytap_admin" && (
                <div className="d-flex justify-content-center mr-4 mt-3">
                  <div className="d-flex flex-column flex-md-column align-items-center">
                    <select
                      className="form-select mx-md-2 mb-2 mb-md-0"
                      id="lang"
                      style={{ width: "200px" }}
                      onChange={(e) => setKeyName(e.target.value)}
                      value={keyName}
                    >
                      <option value="mobile">Mobile No.</option>
                      <option value="lid">Loan id</option>
                      <option value="name">Name</option>
                    </select>
                    <input
                      className="form-control"
                      style={{
                        height: "38px",
                        width: "200px",
                        background: "#fff",
                      }}
                      type="text"
                      placeholder="Search"
                      value={searchInput}
                      onChange={handleInputChange}
                    />
                    <button
                      className="btn btn-success ml-md-2 mt-2 mt-md-0"
                      style={{ background: "#66a725", color: "#fff" }}
                      onClick={searchHandler}
                    >
                      {isLoader ? (
                        <div
                          className="spinner-border text-success"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>
              )}
              {typeOf == "Finance_Admin" && (
                <div className="d-flex justify-content-end mt-3">
                  <div className="d-flex justify-content-end">
                    <div>
                      <h5 className="m-0" style={{ color: "#000" }}>
                        Bank/NBFC Name Workspace
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {typeOf == "Salarytap_admin" && (
              <div className="d-flex justify-content-end mr-4">
                <div className="d-flex flex-column flex-md-row align-items-center">
                  <select
                    className="form-select mx-md-2 mb-2 mb-md-0"
                    id="lang"
                    style={{ width: "200px" }}
                    onChange={(e) => setKeyName(e.target.value)}
                    value={keyName}
                  >
                    <option value="mobile">Mobile No.</option>
                    <option value="lid">Loan id</option>
                    <option value="name">Name</option>
                  </select>
                  <input
                    className="form-control"
                    style={{
                      height: "38px",
                      width: "200px",
                      background: "#fff",
                    }}
                    type="text"
                    placeholder="Search"
                    value={searchInput}
                    onChange={handleInputChange}
                  />
                  <button
                    className="btn btn-success ml-md-2 mt-2 mt-md-0"
                    style={{ background: "#66a725", color: "#fff" }}
                    onClick={searchHandler}
                  >
                    {isLoader ? (
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Search"
                    )}
                  </button>
                </div>
              </div>
            )}
            {typeOf == "Finance_Admin" && (
              <div className="d-flex justify-content-end">
                <div className="d-flex justify-content-end mr-4">
                  <div>
                    <h5 className="m-0" style={{ color: "#000" }}>
                      Bank/NBFC Name Workspace
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <>
          {!searchTable ? (
            <div>
              {key === "Leads" && <Leads />}
              {key === "EmployerCreditAppraisal" && <EmployerCreditAppraisal />}
              {key === "Employercustomizationrequest" && (
                <EmployerCustomizationRequest />
              )}

              {key === "RegisteredCorporates" && <RegisteredCorporates />}
              {key === "NonAssuredRegisteredCorporates" && (
                <NonAssuredRegisteredCorporates />
              )}
              {key === "StpDisbursementReport" && <StpDisbursementReport />}

              {key === "SalarytapAccountAdmin" && <SalarytapAccountAdmin />}
              {key === "Employeedetailpage" && <EmployeeDetail />}
              {key === "DisbursementReport" && <FinanceDisbursementReport />}
              {key === "CollectionReport" && <CollectionReport />}
              {key === "FinanceAccount" && <FinanceAccount />}
              {key === "CreditPolicy" && <CreditPolicy />}
              {key === "FinancierEmployercommercials" && (
                <FinancierEmployercommercials />
              )}
              {key === "FinanceUnpaidEmployees" && <FinanceUnpaidEmployees />}
              {key === "TripartiteAgreements" && <TripartiteAgreements />}

              {key === "Funnel" && <Funnel />}
              {key === "AppHelpline" && <AppHelpline />}
              {key === "AppFeedback" && <AppFeedback />}
              {key === "OpenMarket" && <OpenMarket />}

              {key === "Corporateownership" && <CorporateOwnership />}
              {key === "FinanceReport" && <FinanceReport />}
              {key === "Search" && <Search />}
              {key === "CreditAppraisalforMagic" && <CreditAppraisalForMagic />}

              {key === "Support" && <Support />}
              {key === "Feedback" && <Feedback />}
            </div>
          ) : (
            <>
              <div className="p-3">
                <div className="pt-3">
                  <div className="card">
                    <div className="p-3 d-flex justify-content-start mt-3">
                      <button
                        className="btn btn-secondary"
                        style={{ width: "100px" }}
                        onClick={() => {
                          SetSearchTable(false);
                          setSearchInput("");
                        }}
                      >
                        Back
                      </button>
                    </div>
                    <div className="p-3 pt-2">
                      <SearchDetails
                        keyName={keyName}
                        searchInput={searchInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default StpSideBar;
