import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

function FinanceAccount() {
  const emailid = localStorage.getItem("emailId");
  const [isLoader, setIsLoader] = useState(false);
  const [listFinanceAdmin, setListFinanceAdmin] = useState([]);
  const [financeopenAdmin, setFinanceOpenAdmin] = useState(0);
  const [addFinanceAdmin, setFinanceAddAdmin] = useState(false);

  useEffect(() => {
    getFinanceAccountList();
  }, []);
  const openFinanceAddAdmin = (value) => {
    setFinanceAddAdmin(true);
    setFinanceOpenAdmin(value);
  };
  const Schema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    name: Yup.string().required("required"),
    status: Yup.string().required("Please select an option"),
  });
  
  const getFinanceAccountList = () => {
    const data = { type: "Finance_Admin" };
    setIsLoader(true);
    axios
      .request(`${APIURL}/listadmin`, {
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        setListFinanceAdmin(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
        <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-item"
              id="owner-tab"
              data-bs-toggle="tab"
              data-bs-target="#owner"
              type="button"
              role="tab"
              aria-controls="owner"
              aria-selected="true"
            >
              Owner
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Account
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="d-flex justify-content-end align-items-center">
                    {!addFinanceAdmin ? (
                      <button
                        className="btn btn-success mb-3 mb-md-0"
                        style={{ width: "120px", backgroundColor: "#66a725" }}
                        onClick={() => {
                          openFinanceAddAdmin(1);
                        }}
                      >
                        Add Admin
                      </button>
                    ) : (
                      <button
                        className="btn btn-secondary mb-3 mb-md-0"
                        style={{ width: "120px" }}
                        onClick={() => {
                          getFinanceAccountList();
                          setFinanceAddAdmin(false);
                        }}
                      >
                        Back
                      </button>
                    )}
                  </div>
                  {addFinanceAdmin ? (
                    <Formik
                      initialValues={{
                        email: listFinanceAdmin?.email || "",
                        name: listFinanceAdmin?.name || "",
                        status: listFinanceAdmin?.status || "Active",
                      }}
                      validationSchema={Schema}
                      onSubmit={(values, { resetForm }) => {
                        console.log("values", values);
                        if (financeopenAdmin == 1) {
                          setIsLoader(true);
                          const data = { ...values, type: "Finance_Admin" };
                          axios
                            .request(`${APIURL}/addadmin`, {
                              method: "POST",
                              data: data,
                              headers: {
                                "Content-Type": "application/json",
                              },
                            })
                            .then((response) => {
                              console.log("response", response);
                              if (
                                response?.data?.message ===
                                "An Account already exists with this email Id. Please provide a new Email Id"
                              ) {
                                toast.warning(response?.data?.message);
                                setIsLoader(false);
                              } else {
                                toast.success(response?.data?.message);
                                setIsLoader(false);
                                getFinanceAccountList();
                                setFinanceAddAdmin(false);
                                resetForm();
                              }
                            })
                            .catch((error) => {
                              setIsLoader(false);
                              console.log(error);
                              resetForm();
                            });
                        }
                        if (financeopenAdmin == 2) {
                          setIsLoader(true);
                          const data = { ...values, type: "Finance_Admin" };
                          axios
                            .request(`${APIURL}/updateadmin`, {
                              method: "POST",
                              data: data,
                              headers: {
                                "Content-Type": "application/json",
                              },
                            })
                            .then((response) => {
                              console.log("response", response);
                              setIsLoader(false);
                              getFinanceAccountList();
                              toast.success(response);
                              setFinanceAddAdmin(false);
                              resetForm();
                            })
                            .catch((error) => {
                              setIsLoader(false);
                              console.log(error);
                              resetForm();
                            });
                        }
                      }}
                    >
                      {({ touched, errors }) => (
                        <>
                          <Form className="">
                            <div>
                              <div>
                                <div className="">
                                  <label>Email*</label>
                                  <Field
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    className={`form-control
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                                    disabled={financeopenAdmin == 2}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="email"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mt-2">
                                  <label>Name*</label>
                                  <Field
                                    type="text"
                                    name="name"
                                    placeholder="Enter name"
                                    className={`form-control
                          ${touched.name && errors.name ? "is-invalid" : ""}`}
                                    disabled={financeopenAdmin == 2}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="name"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mt-2">
                                  <label>Status*</label>
                                  <Field
                                    as="select"
                                    name="status"
                                    placeholder="Enter status"
                                    className={`form-control
                          ${
                            touched.status && errors.status ? "is-invalid" : ""
                          }`}
                                  >
                                    <option value="">Select..</option>
                                    <option value="Active">Active</option>
                                    <option value="Deactive">Deactive</option>
                                  </Field>
                                  <ErrorMessage
                                    component="div"
                                    name="status"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <button
                                  className="btn btn-success mt-3 border"
                                  type="submit"
                                  style={{ backgroundColor: "#66a725" }}
                                >
                                  {isLoader ? (
                                    <div
                                      className="spinner-border text-success"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    "Submit"
                                  )}
                                </button>
                              </div>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  ) : isLoader ? (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table className="table table-striped mt-3">
                          <thead>
                            <tr>
                      
                              <th>Sr. No</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {listFinanceAdmin &&
                              listFinanceAdmin?.map((item, i) => (
                                <tr>
                             
                                  <td style={{ width: "100px" }}>{i + 1}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.email}</td>
                                  <td style={{ width: "120px" }}>
                                    <div className="d-flex">
                                      <button
                                        className={`btn ${
                                          item?.status === "Active"
                                            ? "btn-success"
                                            : "btn-outline-success"
                                        }`}
                                        disabled={item?.status === "Active"}
                                        onClick={() => {
                                          setListFinanceAdmin(item);
                                          setFinanceAddAdmin(true);
                                          setFinanceOpenAdmin(2);
                                        }}
                                      >
                                        Active
                                      </button>
                                      <button
                                        className={`btn ml-2 ${
                                          item?.status === "Deactive"
                                            ? "btn-danger"
                                            : "btn-outline-danger"
                                        }`}
                                        disabled={item?.status === "Deactive"}
                                        onClick={() => {
                                          setListFinanceAdmin(item);
                                          setFinanceAddAdmin(true);
                                          setFinanceOpenAdmin(2);
                                        }}
                                      >
                                        Deactive
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="owner"
            role="tabpanel"
            aria-labelledby="owner-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <h6>Email id:&nbsp;{emailid}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceAccount;
