import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../image/Salarytap_logo_horizontal-light_800X239_colored.jpg";
import axios from "axios";
import * as Yup from "yup";
import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { APIURL } from "../../Config";
import GFLogo from "../../image/GFLogo.png";

export default function Login() {
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(true);
  const [showWorkspaceList, setShowWorkspaceList] = useState(false);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [showOtp, setShowOtp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpBoxReference = useRef([]);
  const [isLoader, setIsLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(0);
  const history = useNavigate();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const backToWeb = () => {
    history("/");
    localStorage.clear();
  };

  useEffect(() => {
    const tokenStatus = localStorage.getItem("token");
    if (
      tokenStatus === "undefined" ||
      tokenStatus === "" ||
      !tokenStatus ||
      tokenStatus === "null"
    ) {
      history("/");
    } else {
      history("/greenfinch/dashboard");
    }
  }, []);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
    if (newArr.every((val) => val !== "")) {
      handelSubmitOtp(newArr);
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  }
  const clearOtpFields = () => {
    setOtp(Array(6).fill(""));
    otpBoxReference.current[0].focus();
  };
  const handelSubmitOtp = async (otpArr) => {
    const hasEmptyString = otpArr.some((value) => value === "");

    if (!hasEmptyString) {
      const data = {
        otp: parseInt(otpArr.join("")),
      };

      if (data) {
        const email = localStorage.getItem("emailId");

        let dto = JSON.stringify({
          email: email,
          otp: String(data.otp),
        });

        setIsLoader(true);
        axios
          .request(`${APIURL}/corporate/otpverify`, {
            method: "POST",
            data: dto,
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("response", response);
            setIsLoader(false);
            if (response?.data?.success == true) {
              console.log("response.data.type", response.data);
              if (response.data.type == "Salarytap_admin") {
                history("/greenfinch/stp-dashboard");
              } else if (response.data.type === "Finance_Admin") {
                history("/greenfinch/stp-dashboard");
              } else if (response.data.type === "CA_Admin") {
                history("/greenfinch/stp-dashboard");
              } else if (response.data.type === "Account_Admin") {
                history("/greenfinch/stp-dashboard");
              } else {
                history("/greenfinch/dashboard");
              }
              toast.success(response?.data?.message);
              clearOtpFields();
              setShowOtp(false);
              localStorage.setItem("type", response.data.type);
              localStorage.setItem("workspace", response?.data?.workspaceid);
              localStorage.setItem("userId", response?.data?.userId);
              localStorage.setItem("token", response?.data?.token);
            } else {
              toast.error(response?.data?.message);
            }
          })
          .catch((error) => {
            setIsLoader(false);
            console.log(error);
          });
      }
    } else {
      toast.error("All fields required");
    }
  };

  const getWorkspaceInfo = (mobile, token) => {
    axios
      .request(`${APIURL}/pv/api/getClientRole/${mobile}`, {
        method: "GET",

        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::: ", response);
        if (typeof response?.data == "string") {
          localStorage.clear();
          setShow(true);
          setShowOtp(false);
          setShowWorkspaceList(false);
        } else {
          localStorage.setItem("role", response?.data?.role);
          localStorage.setItem("type", response?.data?.type);
          localStorage.setItem("id", response?.data?.id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clicktoDelete = (item) => {
    const data = {
      emailid: item?.emailId,
      workspaceId: item?.workspace,
    };
    console.log(data);
    axios
      .request(`${APIURL}/employee/deleteworkspace`, {
        method: "POST",
        data: data,
        headers: {
          Authentication: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        toast.warning(response?.data);
        setShowModal(false);
      })
      .catch((error) => {
        setShowModal(false);
        console.log(error);
      });
  };

  if (workspaceList?.length === 1) {
    localStorage.setItem("workspace", workspaceList[0]?.workspace);
  }
  return (
    <>
      {show && (
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            const data = {
              username: values.username,
              password: values.password,
            };
            setMobileNumber(values.username);
            axios
              .request(`${APIURL}/auth/token`, {
                method: "POST",
                data: data,
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log("response", response);
                setIsLoader(true);
                // history("/greenfinch/dashboard");
                if (response?.data?.type === null) {
                  history("/");
                  toast.warning("Workspace not found...");
                } else {
                  setIsLoader(false);

                  localStorage.setItem("token", response?.data);
                  getWorkspaceInfo(values.username, response?.data);
                  history("/greenfinch/dashboard");
                }
              })
              .catch((error) => {
                console.log("error", error);
                setIsLoader(false);
                toast.warning("User not exist...");
              });
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <>
              <div className="Loginform-container">
                <Form className="Loginform ">
                  <div>
                    <div>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <img
                          src={GFLogo}
                          alt="no logo"
                          style={{ height: "60px", width: "230px" }}
                        />
                      </div>
                      <h4
                        className="mt-3 mb-4 text-center"
                        style={{ fontWeight: 800 }}
                      >
                        Login
                      </h4>

                      <label>user Id*</label>
                      <Field
                        type="number"
                        name="username"
                        placeholder="Enter user Id"
                        className={`form-control mt-2
                          ${touched.email && errors.email ? "is-invalid" : ""}`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />

                      <label className="mt-3">Password*</label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        className={`form-control mt-2
                          ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="password"
                        className="invalid-feedback"
                      />

                      <button
                        className="btn mt-3"
                        type="submit"
                        style={{ background: "#02CCFE" }}
                      >
                        {isLoader ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Sign In"
                        )}
                      </button>
                      {/* <hr /> */}
                      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mt-4">
                        {/* <div className="d-flex flex-column flex-sm-row align-items-center">
                          <div>Don't have a workspace?</div>
                          &nbsp;
                          <Link
                            className=""
                            to={"/greenfinch/createYourWorkspace"}
                            style={{ color: "#02CCFE" }}
                          >
                            Create a new Workspace
                          </Link>
                        </div> */}
                        {/* <button
                          className="btn btn-light mt-3 mt-sm-0"
                          type="button"
                          style={{ width: "70px" }}
                          onClick={() => backToWeb()}
                        >
                          Back
                        </button> */}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Formik>
      )}
      {showOtp && (
        <div className="Loginform-container">
          <div className="Loginform">
            <div className="d-flex justify-content-center mb-4">
              <img src={logo} alt="logo" width="35%" height="100%" />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center mb-5">
                  <span className="fs-3 fw-semibold">
                    Check your email for a code
                  </span>
                </div>
                <div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                    className=""
                  >
                    Enter OTP code sent to your email
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-12 text-center">
                    <div
                      className=""
                      style={{
                        flexDirection: "row",
                        columnGap: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          value={digit}
                          maxLength={1}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                          ref={(reference) =>
                            (otpBoxReference.current[index] = reference)
                          }
                          style={{ height: "45px", width: "44px" }}
                          className={`border w-12 h-12 text-4xl text-black p-3 rounded-md block bg-light focus:border-2 focus:outline-none appearance-none text-center`}
                        />
                      ))}
                    </div>

                    <button
                      onClick={() => handelSubmitOtp()}
                      className="btn btn-success mt-3 w-50"
                      type="submit"
                      style={{ background: "#66a725", color: "#fff" }}
                    >
                      {isLoader ? (
                        <div
                          className="spinner-border text-success"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-light mt-2"
                        type="button"
                        style={{ width: "70px" }}
                        onClick={() => {
                          setShow(true);
                          setShowOtp(false);
                          clearOtpFields();
                        }}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      )}
    </>
  );
}
