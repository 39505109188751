import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import Home from "./Components/Pages//Home";
import Login from "./Components/Pages/Login";
import Dashboard from "./Components/Admin/Dashboard";
import About from "./Components/Pages/About";
import Pricing from "./Components/Pages/Pricing";
import TakeToSales from "./Components/Pages/TakeToSales";
import ContactUS from "./Components/Pages/ContactUS";
import SignUp from "./Components/Pages/SignUp";
import HowItWorks from "./Components/Pages/HowItWorks";
import CreateYourWorkspace from "./Components/Pages/CreateYourWorkspace";
import FinalizeCreateYourWorkspace from "./Components/Pages/FinalizeCreateYourWorkspace";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import TermConditions from "./Components/Pages/Term&Conditions";
import StpSideBar from "./Components/Stp/StpSideBar";
import GrievanceRedressal from "./Components/Pages/GrievanceRedressal";
import Media from "./Components/Pages/Media";
import Home from "./Components/Pages/Home";

function AllRoutes() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  React.useEffect(() => {
    const tokenStatus = localStorage.getItem("token");

    if (tokenStatus === "undefined" || tokenStatus === "" || !tokenStatus) {
      setAuthenticated(false);
    } else {
      setAuthenticated(true);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/greenfinch/signUp" element={<SignUp />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/greenfinch/home" element={<Home />} />

          <Route path="/greenfinch/about" element={<About />} />
          <Route path="/greenfinch/pricing" element={<Pricing />} />
          <Route path="/greenfinch/howItWorks" element={<HowItWorks />} />
          <Route path="/greenfinch/talkToSales" element={<TakeToSales />} />
          <Route
            path="/greenfinch/createYourWorkspace"
            element={<CreateYourWorkspace />}
          />
          <Route
            path="/greenfinch/finalCreateYourWorkspace"
            element={<FinalizeCreateYourWorkspace />}
          />

          <Route path="/greenfinch/media" element={<Media />} />

          <Route path="/greenfinch/contact" element={<ContactUS />} />

          <Route
            path="/greenfinch/privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/greenfinch/grievance-redressal"
            element={<GrievanceRedressal />}
          />

          <Route
            path="/greenfinch/term-conditions"
            element={<TermConditions />}
          />

          <Route path="/greenfinch/stp-dashboard" element={<StpSideBar />} />

          <Route path="/greenfinch/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
    </>
  );
}

export default AllRoutes;
