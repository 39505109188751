import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Config";
import LoanDetails from "../../LoanDetails";

function OpenMarket() {
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [month, setMonth] = useState(0);
  const [months, setMonths] = useState([]);
  const [month1, setMonth1] = useState(0);
  const [months1, setMonths1] = useState([]);
  const [keyName, setKeyName] = useState("mobile");
  const [searchInput, setSearchInput] = useState("");
  const [hide, setHide] = useState(false);
  const [lid, setLid] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    manageemployeeopen();
  }, []);
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const getMonthName = (date) =>
    date.toLocaleString("default", { month: "long", month1: "long" });

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();

    const monthsArray = [];
    for (let i = 0; i < 3; i++) {
      const monthDate = new Date(currentYear, now.getMonth() - i, 1);
      const monthName = getMonthName(monthDate);
      monthsArray.push({
        name: `${monthName} ${currentYear}`,
        value: i.toString(),
      });
    }

    setMonths(monthsArray);
    setMonths1(monthsArray);
    setMonth("0");
    setMonth1("0");
  }, []);

  const manageemployeeopen = () => {
    setIsLoader(true);
    axios
      .request(`${APIURL}/manageemployeeopen/${month1}`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        setData(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const search = () => {
    setIsLoader(true);
    const data = { [keyName]: searchInput, status: month };
    axios
      .request(`${APIURL}/searchdetails`, {
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        setData(response?.data);
        setHide(true);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Manage Employees
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  {showSearch ? (
                    <>
                      <div className="p-3 d-flex justify-content-start mt-3">
                        <button
                          className="btn btn-secondary"
                          style={{ width: "100px" }}
                          onClick={() => {
                            setShowSearch(false);
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className="p-3 pt-2">
                        <LoanDetails lid={lid} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="p-3 d-flex flex-column flex-md-row justify-content-end align-items-center">
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          <div>
                            <select
                              className="form-select mb-md-0"
                              id="lang"
                              onChange={(e) => setMonth(e.target.value)}
                              value={month}
                              style={{ width: "120px" }}
                            >
                              {months.map(({ name, value }) => (
                                <option key={value} value={value}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <select
                            className="form-select mx-md-2 mb-2 mb-md-0"
                            id="lang"
                            onChange={(e) => setKeyName(e.target.value)}
                            value={keyName}
                            style={{ width: "130px" }}
                          >
                            <option value="mobile">Mobile No.</option>
                            <option value="lid">Loan id</option>
                            <option value="name">Name</option>
                          </select>
                          <input
                            className="form-control"
                            style={{
                              height: "38px",
                              width: "150px",
                              background: "#fff",
                            }}
                            type="text"
                            placeholder="Search"
                            value={searchInput}
                            onChange={handleInputChange}
                          />
                          <button
                            className="btn btn-success ml-md-2 mt-2 mt-md-0"
                            style={{ background: "#66a725", color: "#fff" }}
                            onClick={search}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="mx-md-3 d-flex flex-row flex-md-row align-items-center justify-content-end">
                        <div>
                          <select
                            className="form-select mx-md-2 mb-md-0"
                            id="lang"
                            onChange={(e) => setMonth1(e.target.value)}
                            value={month1}
                          >
                            {months1.map(({ name, value }) => (
                              <option key={value} value={value}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="ml-md-2">
                          <button
                            className="btn btn-success border mx-2"
                            style={{ background: "#66a725", color: "#fff" }}
                            onClick={manageemployeeopen}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive mt-3">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Sr. No</th>
                              <th>Name </th>
                              <th>Status</th>
                              <th>LID</th>
                              <th>Mobile</th>
                              <th>Limit assigned</th>
                              <th>Limit used</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.accountstatus}</td>
                                <td
                                  onClick={() => {
                                    if (item.loanid) {
                                      setLid(item.loanid);
                                      setShowSearch(true);
                                    }
                                  }}
                                >
                                  {item.loanid}
                                </td>
                                <td>{item.mobile}</td>
                                <td>{item.limitassigned}</td>
                                <td>{item?.limitused}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {/* {helpline && helpline.length !== 0 && (
                    <Pagination
                      showPrePage={showPrePage}
                      onPaginationChange={onPaginationChange}
                      total={helpline.length}
                    />
                  )} */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenMarket;
