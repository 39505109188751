import React from "react";
import Navbar from "./Navbar/Navbar";

const Home = () => {
  return (
    <div>
      <Navbar />
      <h1 style={{ textAlign: "center" }}>Home</h1>
    </div>
  );
};

export default Home;
