import React, { useEffect, useState } from "react";
import logo from "../../image/Salarytap_logo_horizontal-light_800X239_colored.jpg";
import img from "../../image/CSA image13.jpg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { APIURL } from "../../Config";
import { Modal, Button } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

function FinalizeCreateYourWorkspace() {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [corporateId, setCorporateId] = useState("");
  const [workspaceList, setWorkspaceList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [alert, setAlert] = useState(null);
  const history = useNavigate();

  const LoginSchema = Yup.object().shape({
    companyName: Yup.string().required("required"),
    empname: Yup.string().required("required"),
    mobile: Yup.string()
      .required("required")
      .min(10, "MobileNo must be 10 characters at minimum")
      .max(10, "MobileNo must be 10 characters at maximum"),
    // designation: Yup.string().required("required"),
    numberofemployees: Yup.string().required("required"),
    // expectedAmount: Yup.string().required("required"),
    // model: Yup.string().required("Please select an option"),
  });

  const backToWeb = () => {
    setShow(false);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tokenValue = searchParams.get("token");
    const emailValue = searchParams.get("email");
    setToken(tokenValue);
    setEmail(emailValue);
  }, []);
  const getWorkspaceInfo = () => {
    // const dto = {
    //   email: emailid,
    // };
    // axios
    //   .request(`${APIURL}/corporate/workspacedetails`, {
    //     method: "POST",
    //     data: dto,
    //     headers: {
    //       Authentication: token,
    //       "Content-Type": "application/json",
    //      ,
    //     },
    //   })
    //   .then((response) => {
    //     setWorkspaceList(response?.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const handleCreateWorkspace = () => {
    setShow(true);
    // const dto = {
    //   email: emailid,
    // };
    // setIsLoader(true);
    // axios
    //   .request(`${APIURL}/corporate/createworkspace`, {
    //     method: "POST",
    //     data: dto,
    //     headers: {
    //       Authentication: token,
    //       "Content-Type": "application/json",
    //      ,
    //     },
    //   })
    //   .then((response) => {
    //     setIsLoader(false);
    //     localStorage.setItem("workspace", response?.data?.workspace);
    //     toast.success(response?.message);
    //     setShow(true);
    //   })
    //   .catch((error) => {
    //     setIsLoader(false);
    //     console.log(error);
    //   });
  };
  const Login = (item) => {
    localStorage.setItem("workspace", item?.workspace);
    history("/greenfinch/dashboard");
  };

  const handleRadioChange = (setFieldValue, value) => {
    setFieldValue("model", value);
    if (value === "no") {
      setShowModal(true);
    }
    if (value === "yes") {
      setShowModal1(true);
    }
  };

  const handleModalChange = (value, setFieldValue) => {
    if (value === "yes") {
      setShowModal1(true);
      setShowModal(false);
      setFieldValue("model", "yes");
    } else if (value === "no") {
      setFieldValue("model", "no");
      setShowModal1(false);
      setShowModal(false);
    }
  };

  const PopUp = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        onConfirm={() => {
          setAlert(null);
          history("/greenfinch/dashboard");
          localStorage.setItem("emailId", email);
          localStorage.setItem("token", token);
          // localStorage.setItem("corporateId", response?.data?.corporateId);
        }}
        confirmBtnStyle={{ backgroundColor: "#66a725", border: "none" }}
      >
        <h6>Congratulations, you're almost there!</h6>
        <h6>Just complete your profile to finish setting up your workspace.</h6>
      </SweetAlert>
    );
  };
  return (
    <>
      {alert}
      {!show && (
        <div className="" style={{ height: "100vh" }}>
          <div className="container">
            <div className="px-md-5 py-md-4">
              <div className="d-flex justify-content-center mb-2">
                <img src={logo} alt="logo" width="30%" height="100%" />
              </div>
              <div className="d-flex justify-content-center mb-0">
                <h3 className="m-0">{email}</h3>
              </div>
              <div className="mt-0">
                <div className="row mt-5">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-4 mb-md-0 order-lg-2 order-md-2 order-sm-1 d-flex flex-column justify-content-center">
                    <img
                      src={img}
                      alt=""
                      width="100%"
                      height="auto"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  <div
                    className="col-lg-6 col-md-6 col-sm-12 order-lg-1 order-md-1 order-sm-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <div className="d-flex justify-content-end"> </div>
                    <div className="mt-2 mb-3">
                      <h1 style={{ fontSize: "66px" }}>
                        Create a new workspace
                      </h1>
                    </div>
                    <div className="mt-2 mb-4">
                      <p className="fs-4 text-start">
                        Bridging the gap between paychecks
                      </p>
                    </div>
                    <div className="w-100 mt-2">
                      <button
                        className="px-5 py-2 border border-0 fs-5 fw-medium rounded-1 w-100"
                        style={{ backgroundColor: "#66a725", color: "#fff" }}
                        onClick={handleCreateWorkspace}
                      >
                        Create a workspace
                      </button>
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      <button
                        className="btn btn-light"
                        type="button"
                        style={{
                          width: "100px",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        onClick={() =>
                          history("/greenfinch/createYourWorkspace")
                        }
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="pt-4">
            <hr />
          </div>
           <div className="pb-5 pt-4">
            <div className="d-flex justify-content-center pb-4">
              <h3>Open a workspace</h3>
            </div>
            {typeof workspaceList === "string" ? (
              <div className="d-flex justify-content-center">
                <div className="card bg-light" style={{ width: "42rem" }}>
                  <div
                    className="card-body"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h5 className="card-title">{workspaceList}</h5>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="card bg-light" style={{ width: "42rem" }}>
                  {workspaceList?.map((item, i) => (
                    <>
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="px-2">
                          <h5 className="card-title">{item?.emailId}</h5>
                          <p className="card-text">{item?.corporateName}</p>
                        </div>
                        <div className="ps-5 pe-3">
                          <button
                            className="btn btn-primary"
                            style={{
                              background: "#66a725",
                              color: "#fff",
                              border: "0px",
                            }}
                            onClick={() => Login(item)}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                      {i < workspaceList?.length - 1 && (
                        <div className="px-5">
                          <hr />
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            )}
          </div> */}
          </div>
        </div>
      )}
      {show && (
        <Formik
          initialValues={{
            companyName: "",
            empname: "",
            mobile: "",
            numberofemployees: "",
            // model: "yes",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values, { resetForm }) => {
            let dto = {
              ...values,
              corpadminmail: email,
            };
            console.log("dto", dto);

            setIsLoader(true);
            axios
              .request(`${APIURL}/corporate/workspaceprofile`, {
                method: "POST",
                data: dto,
                headers: {
                  Authentication: token,
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log("response", response);
                setIsLoader(false);
                if (
                  response?.data?.message ===
                  "workspace already created with this email id."
                ) {
                  toast.warning(response?.data?.message);
                  history("/");
                  localStorage.clear();
                } else if (response?.data?.success === false) {
                  toast.warning(response?.data?.message);
                } else {
                  toast.success(response?.data?.message);
                  if (response?.data?.success === true) {
                    PopUp();
                    resetForm();
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
                setIsLoader(false);
                resetForm();
                toast.warning("User not exist...");
              });
          }}
        >
          {({ touched, errors, setFieldValue }) => (
            <div className="Loginform-container">
              <Form
                className="Loginform "
                style={{ padding: "30px 35px 20px 35px" }}
              >
                <div>
                  <h4
                    className="mb-3 text-center"
                    style={{ color: "#66a725", fontWeight: 800 }}
                  >
                    Profile
                  </h4>
                  <div>
                    <label>Employer name*</label>
                    <Field
                      type="text"
                      name="companyName"
                      placeholder="Enter employer name"
                      className={`form-control ${
                        touched.companyName && errors.companyName
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="companyName"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mt-2">
                    <label>Your Name*</label>
                    <Field
                      type="text"
                      name="empname"
                      placeholder="Enter name"
                      className={`form-control ${
                        touched.empname && errors.empname ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="empname"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="mt-2">
                    <label>Mobile No*</label>
                    <Field
                      type="number"
                      name="mobile"
                      placeholder="Enter mobile no"
                      className={`form-control ${
                        touched.mobile && errors.mobile ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="mobile"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mt-2">
                    <label>No. of employees*</label>
                    <Field
                      type="number"
                      name="numberofemployees"
                      placeholder="Enter number of employees"
                      className={`form-control ${
                        touched.numberofemployees && errors.numberofemployees
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="numberofemployees"
                      className="invalid-feedback"
                    />
                  </div>

                  {/* <div className="form-group mt-2">
                    <label>
                      I am willing to assure repayment of my employee if the
                      employee does not pay by the 7th of the next month? *
                    </label>
                    <div className="d-flex">
                      <div className="form-check">
                        <Field
                          type="radio"
                          name="model"
                          value="yes"
                          className={`form-check-input ${
                            touched.model && errors.model ? "is-invalid" : ""
                          }`}
                          onChange={() =>
                            handleRadioChange(setFieldValue, "yes")
                          }
                        />
                        <label className="form-check-label">Yes</label>
                      </div>
                      <div className="form-check ml-2">
                        <Field
                          type="radio"
                          name="model"
                          value="no"
                          className={`form-check-input ${
                            touched.model && errors.model ? "is-invalid" : ""
                          }`}
                          onChange={() =>
                            handleRadioChange(setFieldValue, "no")
                          }
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </div>
                    <ErrorMessage
                      component="div"
                      name="check"
                      className="invalid-feedback"
                    />
                  </div>
                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                    size="lg"
                    style={{ background: "#ffffffd1" }}
                  >
                    <Modal.Body closeButton>
                      <p className="px-2 py-2">
                        We would like you to reconsider assuring employee
                        repayment, as failing to do so will require us to
                        conduct a thorough credit assessment of the employee.
                        This could result in many rejections and reduced credit
                        limits, thereby limiting the benefits of the on-demand
                        salary feature for employees. Would you like to
                        reconsider Assuring repayment for your own employees. I
                        confirm that I will Assure repayment of my employees.
                      </p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-start">
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="px-2"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="modalCheck"
                            onClick={() =>
                              handleModalChange("yes", setFieldValue)
                            }
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check ml-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="modalCheck"
                            onClick={() =>
                              handleModalChange("no", setFieldValue)
                            }
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={showModal1}
                    onHide={() => setShowModal1(false)}
                    centered
                    size="lg"
                    style={{ background: "#ffffffd1" }}
                  >
                    <Modal.Body closeButton>
                      <p
                        style={{
                          fontSize: "18px",
                          color: "#66a725",
                          fontWeight: 600,
                          alignItems: "center",
                        }}
                      >
                        Thank you for your interest in Salarytap. Our Sales team
                        shall contact you shortly.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "20%",
                        }}
                      >
                        <Button
                          className="border"
                          style={{ background: "#66a725" }}
                          onClick={() => setShowModal1(false)}
                        >
                          Ok
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal> */}
                  <button
                    className="btn btn-success mt-3"
                    type="submit"
                    style={{ backgroundColor: "#66a725", color: "#fff" }}
                  >
                    {isLoader ? (
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-light mt-4"
                      type="button"
                      style={{ width: "70px" }}
                      onClick={() => backToWeb()}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </>
  );
}

export default FinalizeCreateYourWorkspace;
