import React, { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { useLocation } from "react-router-dom";

function HowItWorks() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Navbar />
      <div className="">
        <div className="container">
          <div className="px-md-5">
            <div className="mb-4">
              <h3
                className="text-center fw-bolder"
                style={{ color: "#66a725" }}
              >
                How it Works
              </h3>
            </div>

            <div className="row">
              <div>
                <h3 className="">For Employers</h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                <div className="card bg-light h-100">
                  <div
                    className=" h4 text-start px-4 "
                    style={{
                      backgroundColor: "#66a725",
                      color: "#fff",
                      padding: 4,
                      borderTopLeftRadius: "0.375rem",
                      borderTopRightRadius: "0.375rem",
                    }}
                  >
                    Create your workspace
                  </div>
                  <div className=" p-4">
                    <div>
                      <h4 className="mt-1" style={{ fontSize: "20px" }}>
                        Create your workspace
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Create your new workspace on{" "}
                        <span style={{ color: "blue", cursor: "pointer" }}>
                          Salarytap.in
                        </span>
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Choose model
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Choose Assured or Non Assured model.
                      </h6>
                      <h4 className="mt-1" style={{ fontSize: "20px" }}>
                        Set Up
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Decide whether you would like to use the Salarytap
                        platform or want us to integrate it to your HR app
                      </h6>

                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Choose funding option
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Decide whether you would like to use your own money to
                        finance the advance salary or would you like us bring in
                        a financier.
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Upload
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Upload employee data
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                <div className="card bg-light h-100">
                  <div
                    className="h4 text-start px-4 "
                    style={{
                      backgroundColor: "#66a725",
                      color: "#fff",
                      padding: 4,
                      borderTopLeftRadius: "0.375rem",
                      borderTopRightRadius: "0.375rem",
                    }}
                  >
                    Pricing
                  </div>
                  <div className="p-4">
                    <div>
                      <h4 className="mt-1" style={{ fontSize: "20px" }}>
                        Free integration
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        There is no integration cost if you are using our
                        Salarytap platform.
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Customization
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        A customization fee applies for integrating Salarytap
                        into your HR App. Additionally, if you prefer to use
                        your own funds, there is an integration cost for linking
                        your bank account.
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Deploy & Earn
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Earn interest on funds deployed if you choose to deploy
                        your own funds for advancing salary to your own
                        employees.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div>
                <h3 className="">For Employees</h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                <div className="card bg-light h-100">
                  <div
                    className=" h4 text-start px-4 "
                    style={{
                      backgroundColor: "#66a725",
                      color: "#fff",
                      padding: 4,
                      borderTopLeftRadius: "0.375rem",
                      borderTopRightRadius: "0.375rem",
                    }}
                  >
                    Download Salarytap app
                  </div>
                  <div className=" p-4">
                    <div>
                      <h4 className="mt-1" style={{ fontSize: "20px" }}>
                        Log in
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Log into Salarytap App with your mobile number
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Register
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        If your employer has assured repayment then you just
                        need to fill in some basic details and we need your bank
                        account for transferring the money. However, if your
                        employer has not assured repayment or your employer is
                        not empaneled with us then we need access to your bank
                        account to ascertain your repayment capability.
                      </h6>
                      <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                        Register with Aadhar, Pan, Employer name and Bank
                        Account.
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Withdraw funds
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Instantly avail your limit and get funds in your bank
                        account or even use our QR code reader to make any
                        payment to third party
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Repay
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        Repay outstanding anytime or on receipt of salary before
                        the 7th of next month and enjoy the credit limit month
                        on month
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                <div className="card bg-light h-100">
                  <div
                    className=" h4 text-start px-4 "
                    style={{
                      backgroundColor: "#66a725",
                      color: "#fff",
                      padding: 4,
                      borderTopLeftRadius: "0.375rem",
                      borderTopRightRadius: "0.375rem",
                    }}
                  >
                    Pricing
                  </div>
                  <div className="p-4">
                    <div>
                      <h4 className="mt-1" style={{ fontSize: "20px" }}>
                        One time set up fees
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        A one time set up fee of Rs.300 is charged to the
                        employee at the time of the first withdrawal
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Minimal Convenience Charges
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        A small convenience fee is levied on every withdrawal by
                        the employee.
                      </h6>
                      <h4 className=" mt-3" style={{ fontSize: "20px" }}>
                        Interest Free
                      </h4>
                      <h6 className=" mt-2" style={{ fontWeight: "400" }}>
                        No interest is levied on the withdrawals
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HowItWorks;
