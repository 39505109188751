import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";
import * as yup from "yup";

function ApprovedUpdateBusiness({ data, setFormStates, formStates }) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const businessVerificationSchema = yup.object().shape({
    businessApplicantName: yup.string().required("Applicant name is required"),
    businessContactNo: yup
      .string()
      .required("Contact number is required")
      .min(10, "Contact number must be 10 digits")
      .max(10, "Contact number must be 10 digits"),
    businessOrEmploymentAddress: yup.string().required("Address is required"),
    businessNearestLandmark: yup
      .string()
      .required("Nearest landmark is required"),
    businessEaseOfLocating: yup
      .string()
      .required("Ease of locating is required"),
    businessTypeOfOfficeSetup: yup
      .string()
      .required("Type of office setup is required"),
    businessAreaLocality: yup.string().required("Area locality is required"),
    employmentStatus: yup.string().required("Employment status is required"),
    businessNameOfPersonMet: yup
      .string()
      .required("Name of person met is required"),
    businessMetPersonDesignation: yup
      .string()
      .required("Met person designation is required"),
    businessApplicantWorkingAs: yup
      .string()
      .required("Applicant working as is required"),
    businessApplicantWorkingSinceYear: yup
      .number()
      .required("Working since year is required")
      .positive("Working since year must be a positive number"),
    businessApplicantWorkingSinceMonth: yup
      .number()
      .required("Working since month is required")
      .positive("Working since month must be a positive number"),
    businessRegistration: yup.string().required("Registration is required"),
    businessIsAnyOtherWorkplace: yup
      .string()
      .required("Any other workplace is required"),
    businessOtherWorkplaceAddress: yup
      .string()
      .required("Other workplace address is required"),
    businessDocumentVerificationDetails: yup
      .string()
      .required("Document verification details is required"),
    businessOfficeOwnership: yup
      .string()
      .required("Office ownership is required"),
    businessNoOfYearsRunning: yup
      .number()
      .required("Years running is required")
      .positive("Years running must be a positive number"),
    businessNoOfMonthsRunning: yup
      .number()
      .required("Months running is required")
      .positive("Months running must be a positive number"),
    businessDetailsPrevious: yup
      .string()
      .required("Previous details is required"),
    businessNatureOfBusiness: yup
      .string()
      .required("Nature of business is required"),
    businessOfficeBoardSeen: yup
      .string()
      .required("Office board seen is required"),
    businessOfficeBoardSeenRemarks: yup
      .string()
      .required("Office board seen remarks is required"),
    businessNoOfEmployeesSeenAtVisit: yup
      .number()
      .required("Number of employees seen at visit is required")
      .positive("Number of employees seen at visit must be a positive number"),
    businessActivityOrStockLevelSeenAtVisit: yup
      .string()
      .required("Activity or stock level seen at visit is required"),
    businessConfirmedBy: yup.string().required("Confirmed by is required"),
    businessFirstNeighbourName: yup
      .string()
      .required("First neighbour name is required"),
    businessFirstNeighbourMobno: yup
      .string()
      .required("First neighbour mobile number is required")
      .min(10, "First neighbour mobile number must be 10 digits")
      .max(10, "First neighbour mobile number must be 10 digits"),
    businessFirstNeighbourAddress: yup
      .string()
      .required("First neighbour address is required"),
    businessFirstNeighbourHowlongknow: yup
      .string()
      .required("First neighbour how long known is required"),
    businessFirstNeighbourBehaviour: yup
      .string()
      .required("First neighbour behaviour is required"),
    businessFirstNeighbourNegativeBehaviour: yup
      .string()
      .required("First neighbour negative behaviour is required"),
    businessFirstNeighbourAnyIllegalActivity: yup
      .string()
      .required("First neighbour any illegal activity is required"),
    businessSecondNeighbourName: yup
      .string()
      .required("Second neighbour name is required"),
    businessSecondNeighbourMobno: yup
      .string()
      .required("Second neighbour mobile number is required")
      .min(10, "Second neighbour mobile number must be 10 digits")
      .max(10, "Second neighbour mobile number must be 10 digits"),
    businessSecondNeighbourAddress: yup
      .string()
      .required("Second neighbour address is required"),
    businessSecondNeighbourHowlongknow: yup
      .string()
      .required("Second neighbour how long known is required"),
    businessSecondNeighbourBehaviour: yup
      .string()
      .required("Second neighbour behaviour is required"),
    businessSecondNeighbourNegativeBehaviour: yup
      .string()
      .required("Second neighbour negative behaviour is required"),
    businessSecondNeighbourAnyIllegalActivity: yup
      .string()
      .required("Second neighbour any illegal activity is required"),
    businessAditionalCheckName: yup
      .string()
      .required("Additional check name is required"),
    businessAditionalCheckMobno: yup
      .string()
      .required("Additional check mobile number is required")
      .min(10, "Additional check mobile number must be 10 digits")
      .max(10, "Additional check mobile number must be 10 digits"),
    businessAditionalCheckAddress: yup
      .string()
      .required("Additional check address is required"),
    businessAditionalCheckFeedbackHowlongknow: yup
      .string()
      .required("Additional check feedback how long known is required"),
    businessAditionalCheckFeedbackBehaviour: yup
      .string()
      .required("Additional check feedback behaviour is required"),
    businessAditionalCheckNegativeBehaviour: yup
      .string()
      .required("Additional check feedback negative behaviour is required"),
    businessAditionalCheckAnyIllegalActivity: yup
      .string()
      .required("Additional check feedback any illegal activity is required"),
    businessDetailsRemark: yup.string().required("Details remark is required"),
    businessDedupStatus: yup.string().required("Dedup status is required"),
    businessDedupDetails: yup.string().required("Dedup details is required"),
    businessFinalStatus: yup.string().required("Final status is required"),
    businessVerifierName: yup.string().required("Verifier name is required"),
    businessManagerName: yup.string().required("Manager name is required"),
    businessAgencyName: yup.string().required("Agency name is required"),
    businessVerificationStatus: yup
      .string()
      .required("Verification status is required"),
    businessAnyMatchFoundInNegativeDatabase: yup
      .string()
      .required("Match found in negative database is required"),
    businessProfileOfApplicant: yup
      .string()
      .required("Profile of applicant is required"),
  });

  const businessInitialValues = {
    clientId: data?.clientId,
    doneBy: data?.doneBy,
    doneOn: data?.doneOn,
    businessDateOfVisit: data?.businessDateOfVisit,
    businessTimeOfVisit: data?.businessTimeOfVisit,
    businessApplicantName: data?.businessApplicantName,
    businessContactNo: data?.businessContactNo,
    businessOrEmploymentAddress: data?.businessOrEmploymentAddress,
    businessNearestLandmark: data?.businessNearestLandmark,
    businessEaseOfLocating: data?.businessEaseOfLocating,
    businessTypeOfOfficeSetup: data?.businessTypeOfOfficeSetup,
    businessAreaLocality: data?.businessAreaLocality,
    businessTypeOfEmployment: data?.businessTypeOfEmployment,
    businessNameOfPersonMet: data?.businessNameOfPersonMet,
    businessMetPersonDesignation: data?.businessMetPersonDesignation,
    businessNameOfOtherPersonMet: data?.businessNameOfOtherPersonMet,
    businessContactOfOtherPersonMet: data?.businessContactOfOtherPersonMet,
    businessMetOtherPersonDesignation: data?.businessMetOtherPersonDesignation,
    businessApplicantWorkingAs: data?.businessApplicantWorkingAs,
    businessApplicantWorkingSinceYear: data?.businessApplicantWorkingSinceYear,
    businessApplicantWorkingSinceMonth:
      data?.businessApplicantWorkingSinceMonth,
    businessRegistration: data?.businessRegistration,
    businessIsAnyOtherWorkplace: data?.businessIsAnyOtherWorkplace,
    businessOtherWorkplaceAddress: data?.businessOtherWorkplaceAddress,
    businessDocumentVerificationDetails:
      data?.businessDocumentVerificationDetails,
    businessOfficeOwnership: data?.businessOfficeOwnership,
    businessNoOfYearsRunning: data?.businessNoOfYearsRunning,
    businessNoOfMonthsRunning: data?.businessNoOfMonthsRunning,
    businessDetailsPrevious: data?.businessDetailsPrevious,
    businessNatureOfBusiness: data?.businessNatureOfBusiness,
    businessOfficeBoardSeen: data?.businessOfficeBoardSeen,
    businessOfficeBoardSeenRemarks: data?.businessOfficeBoardSeenRemarks,
    businessNoOfEmployeesSeenAtVisit: data?.businessNoOfEmployeesSeenAtVisit,
    businessActivityOrStockLevelSeenAtVisit:
      data?.businessActivityOrStockLevelSeenAtVisit,
    businessConfirmedBy: data?.businessConfirmedBy,
    businessFirstNeighbourName: data?.businessFirstNeighbourName,
    businessFirstNeighbourMobno: data?.businessFirstNeighbourMobno,
    businessFirstNeighbourAddress: data?.businessFirstNeighbourAddress,
    businessFirstNeighbourHowlongknow: data?.businessFirstNeighbourHowlongknow,
    businessFirstNeighbourBehaviour: data?.businessFirstNeighbourBehaviour,
    businessFirstNeighbourNegativeBehaviour:
      data?.businessFirstNeighbourNegativeBehaviour,
    businessFirstNeighbourAnyIllegalActivity:
      data?.businessFirstNeighbourAnyIllegalActivity,
    businessSecondNeighbourName: data?.businessSecondNeighbourName,
    businessSecondNeighbourMobno: data?.businessSecondNeighbourMobno,
    businessSecondNeighbourAddress: data?.businessSecondNeighbourAddress,
    businessSecondNeighbourHowlongknow:
      data?.businessSecondNeighbourHowlongknow,
    businessSecondNeighbourBehaviour: data?.businessSecondNeighbourBehaviour,
    businessSecondNeighbourNegativeBehaviour:
      data?.businessSecondNeighbourNegativeBehaviour,
    businessSecondNeighbourAnyIllegalActivity:
      data?.businessSecondNeighbourAnyIllegalActivity,
    businessAditionalCheckName: data?.businessAditionalCheckName,
    businessAditionalCheckMobno: data?.businessAditionalCheckMobno,
    businessAditionalCheckAddress: data?.businessAditionalCheckAddress,
    businessAditionalCheckFeedbackHowlongknow:
      data?.businessAditionalCheckFeedbackHowlongknow,
    businessAditionalCheckFeedbackBehaviour:
      data?.businessAditionalCheckFeedbackBehaviour,
    businessAditionalCheckNegativeBehaviour:
      data?.businessAditionalCheckNegativeBehaviour,
    businessAditionalCheckAnyIllegalActivity:
      data?.businessAditionalCheckAnyIllegalActivity,
    businessDetailsRemark: data?.businessDetailsRemark,
    businessDedupStatus: data?.businessDedupStatus,
    businessDedupDetails: data?.businessDedupDetails,
    businessFinalStatus: data?.businessFinalStatus,
    businessVerifierName: data?.businessVerifierName,
    businessManagerName: data?.businessManagerName,
    businessAgencyName: data?.businessAgencyName,
    businessVerificationStatus: data?.businessVerificationStatus,
    businessAnyMatchFoundInNegativeDatabase:
      data?.businessAnyMatchFoundInNegativeDatabase,
    businessProfileOfApplicant: data?.businessProfileOfApplicant,
    businessUpdateStatus: data?.businessUpdateStatus,
    businessUpdatedBy: id,
    businessUpdatedDate: data?.businessUpdatedDate,
  };
  const handleSubmitBusiness = (values, { setSubmitting }) => {
    console.log("Form values:", values);
    // Here you would typically send the form values to your API
    setSubmitting(false);
    axios
      .request(`${APIURL}/v2/api/cpv/updateCpvBusinessVerification`, {
        method: "POST",
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        toast.success(response?.data);
        console.log("response::: ", response);
        setFormStates(!formStates.business);
      })
      .catch((error) => {
        console.log(error);
        // setIsLoader(false);
      });
  };
  return (
    <div className="mt-3">
      <h4>Business Verification</h4>
      <Formik
        initialValues={businessInitialValues}
        // validationSchema={businessVerificationSchema}
        onSubmit={handleSubmitBusiness}
      >
        {({ values, handleSubmit }) => (
          <Form>
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-md-12 form-group">
                <label htmlFor="businessApplicantName">Applicant Name</label>
                <Field
                  className="form-control"
                  name="businessApplicantName"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessApplicantName"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessContactNo">Contact No</label>
                <Field
                  className="form-control"
                  name="businessContactNo"
                  type="number"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessContactNo"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessOrEmploymentAddress">Address</label>
                <Field
                  className="form-control"
                  name="businessOrEmploymentAddress"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessOrEmploymentAddress"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessNearestLandmark">
                  Nearest Landmark
                </label>
                <Field
                  className="form-control"
                  name="businessNearestLandmark"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessNearestLandmark"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessEaseOfLocating">Ease of Locating</label>
                <Field
                  as="select"
                  id="businessEaseOfLocating"
                  name="businessEaseOfLocating"
                  className="form-control"
                >
                  <option value="">Select Ease of Locating Business</option>
                  <option value="Easy">Easy</option>
                  <option value="Difficult">Difficult</option>
                  <option value="Untrackable">Untrackable</option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessEaseOfLocating"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessTypeOfOfficeSetup">
                  Type of Office Setup
                </label>
                <Field
                  className="form-control"
                  name="businessTypeOfOfficeSetup"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessTypeOfOfficeSetup"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAreaLocality">Area Locality</label>
                <Field
                  className="form-control"
                  name="businessAreaLocality"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAreaLocality"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessTypeOfEmployment">
                  Type of Employment
                </label>
                <Field
                  as="select"
                  id="employmentStatus"
                  name="employmentStatus"
                  className="form-control"
                >
                  <option value="">Select Employment Status</option>
                  <option value="Salaried">Salaried</option>
                  <option value="Self Employed">Self Employed</option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessTypeOfEmployment"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessNameOfPersonMet">
                  Name of Person Met
                </label>
                <Field
                  className="form-control"
                  name="businessNameOfPersonMet"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessNameOfPersonMet"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessMetPersonDesignation">
                  Met Person Designation
                </label>
                <Field
                  className="form-control"
                  name="businessMetPersonDesignation"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessMetPersonDesignation"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessApplicantWorkingAs">
                  Applicant Working As
                </label>
                <Field
                  className="form-control"
                  name="businessApplicantWorkingAs"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessApplicantWorkingAs"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessApplicantWorkingSinceYear">
                  Working Since Year
                </label>
                <Field
                  className="form-control"
                  name="businessApplicantWorkingSinceYear"
                  type="number"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessApplicantWorkingSinceYear"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessApplicantWorkingSinceMonth">
                  Working Since Month
                </label>
                <Field
                  className="form-control"
                  name="businessApplicantWorkingSinceMonth"
                  type="number"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessApplicantWorkingSinceMonth"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessRegistration">Registration</label>
                <Field
                  className="form-control"
                  name="businessRegistration"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessRegistration"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessIsAnyOtherWorkplace">
                  Any Other Workplace
                </label>
                <Field
                  className="form-control"
                  name="businessIsAnyOtherWorkplace"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessIsAnyOtherWorkplace"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessOtherWorkplaceAddress">
                  Other Workplace Address
                </label>
                <Field
                  className="form-control"
                  name="businessOtherWorkplaceAddress"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessOtherWorkplaceAddress"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessDocumentVerificationDetails">
                  Business registration if available
                </label>
                <Field
                  as="select"
                  id="businessDocumentVerificationDetails"
                  name="businessDocumentVerificationDetails"
                  className="form-control"
                >
                  <option value="">Select Document Verification Type</option>
                  <option value="Shop and Establishment">
                    Shop and Establishment
                  </option>
                  <option value="GST">GST</option>
                  <option value="Other Govt. Valid Registration">
                    Other Govt. Valid Registration
                  </option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessDocumentVerificationDetails"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessOfficeOwnership">
                  Office Ownership
                </label>
                <Field
                  as="select"
                  id="businessOfficeOwnership"
                  name="businessOfficeOwnership"
                  className="form-control"
                >
                  <option value="">Select Office Ownership Type</option>
                  <option value="Self Owned">Self Owned</option>
                  <option value="Rented">Rented</option>
                  <option value="Leased">Leased</option>
                  <option value="Shared Premises">Shared Premises</option>
                  <option value="Business Centre">Business Centre</option>
                  <option value="Company Owned">Company Owned</option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessOfficeOwnership"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessNoOfYearsRunning">Years Running</label>
                <Field
                  className="form-control"
                  name="businessNoOfYearsRunning"
                  type="number"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessNoOfYearsRunning"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessNoOfMonthsRunning">
                  Months Running
                </label>
                <Field
                  className="form-control"
                  name="businessNoOfMonthsRunning"
                  type="number"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessNoOfMonthsRunning"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessDetailsPrevious">
                  Previous Details
                </label>
                <Field
                  className="form-control"
                  name="businessDetailsPrevious"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessDetailsPrevious"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessNatureOfBusiness">
                  Nature of Business
                </label>
                <Field
                  as="select"
                  id="businessNatureOfBusiness"
                  name="businessNatureOfBusiness"
                  className="form-control"
                >
                  <option value="">Select Nature of Business</option>
                  <option value="Trading">Trading</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Service">Service</option>
                  <option value="Jobwork">Jobwork</option>
                  <option value="Commission Agent">Commission Agent</option>
                  <option value="Other">Other</option>
                </Field>
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessNatureOfBusiness"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessOfficeBoardSeen">
                  Office Board Seen
                </label>
                <Field
                  as="select"
                  id="businessOfficeBoardSeen"
                  name="businessOfficeBoardSeen"
                  className="form-control"
                >
                  <option value="">Select Board Seen Status</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Remark">Remark</option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessOfficeBoardSeen"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessOfficeBoardSeenRemarks">
                  Board Remarks
                </label>
                <Field
                  className="form-control"
                  name="businessOfficeBoardSeenRemarks"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessOfficeBoardSeenRemarks"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessNoOfEmployeesSeenAtVisit">
                  Employees Seen
                </label>
                <Field
                  className="form-control"
                  name="businessNoOfEmployeesSeenAtVisit"
                  type="number"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessNoOfEmployeesSeenAtVisit"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessActivityOrStockLevelSeenAtVisit">
                  Stock levels / Business Activity seen at the time of visit
                </label>
                <Field
                  as="select"
                  id="businessActivityOrStockLevelSeenAtVisit"
                  name="businessActivityOrStockLevelSeenAtVisit"
                  className="form-control"
                >
                  <option value="">Select Activity or Stock Level</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessActivityOrStockLevelSeenAtVisit"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessConfirmedBy">
                  Business Confirmed by
                </label>
                <Field
                  as="select"
                  id="businessConfirmedBy"
                  name="businessConfirmedBy"
                  className="form-control"
                >
                  <option value="">Select Confirmed By</option>
                  <option value="Trading">Trading</option>
                  <option value="Neighbour">Neighbour</option>
                  <option value="Security">Security</option>
                  <option value="Person Met">Person Met</option>
                  <option value="Could not confirm">Could not confirm</option>
                  <option value="Remarks">Remarks</option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessConfirmedBy"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFirstNeighbourName">
                  First Neighbour Name
                </label>
                <Field
                  className="form-control"
                  name="businessFirstNeighbourName"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFirstNeighbourName"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFirstNeighbourMobno">
                  First Neighbour Mobile No
                </label>
                <Field
                  className="form-control"
                  name="businessFirstNeighbourMobno"
                  type="number"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFirstNeighbourMobno"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFirstNeighbourAddress">
                  First Neighbour Address
                </label>
                <Field
                  className="form-control"
                  name="businessFirstNeighbourAddress"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFirstNeighbourAddress"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFirstNeighbourHowlongknow">
                  How Long Known
                </label>
                <Field
                  className="form-control"
                  name="businessFirstNeighbourHowlongknow"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFirstNeighbourHowlongknow"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFirstNeighbourBehaviour">
                  Behaviour
                </label>
                <Field
                  className="form-control"
                  name="businessFirstNeighbourBehaviour"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFirstNeighbourBehaviour"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFirstNeighbourNegativeBehaviour">
                  Negative Behaviour
                </label>
                <Field
                  className="form-control"
                  name="businessFirstNeighbourNegativeBehaviour"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFirstNeighbourNegativeBehaviour"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFirstNeighbourAnyIllegalActivity">
                  Any Illegal Activity
                </label>
                <Field
                  className="form-control"
                  name="businessFirstNeighbourAnyIllegalActivity"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFirstNeighbourAnyIllegalActivity"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessSecondNeighbourName">
                  Second Neighbour Name
                </label>
                <Field
                  className="form-control"
                  name="businessSecondNeighbourName"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessSecondNeighbourName"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessSecondNeighbourMobno">
                  Second Neighbour Mobile No
                </label>
                <Field
                  className="form-control"
                  name="businessSecondNeighbourMobno"
                  type="number"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessSecondNeighbourMobno"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessSecondNeighbourAddress">
                  Second Neighbour Address
                </label>
                <Field
                  className="form-control"
                  name="businessSecondNeighbourAddress"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessSecondNeighbourAddress"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessSecondNeighbourHowlongknow">
                  How Long Known
                </label>
                <Field
                  className="form-control"
                  name="businessSecondNeighbourHowlongknow"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessSecondNeighbourHowlongknow"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessSecondNeighbourBehaviour">
                  Behaviour
                </label>
                <Field
                  className="form-control"
                  name="businessSecondNeighbourBehaviour"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessSecondNeighbourBehaviour"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessSecondNeighbourNegativeBehaviour">
                  Negative Behaviour
                </label>
                <Field
                  className="form-control"
                  name="businessSecondNeighbourNegativeBehaviour"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessSecondNeighbourNegativeBehaviour"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessSecondNeighbourAnyIllegalActivity">
                  Any Illegal Activity
                </label>
                <Field
                  className="form-control"
                  name="businessSecondNeighbourAnyIllegalActivity"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessSecondNeighbourAnyIllegalActivity"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAditionalCheckName">
                  Additional Check Name
                </label>
                <Field
                  className="form-control"
                  name="businessAditionalCheckName"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAditionalCheckName"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAditionalCheckMobno">
                  Additional Check Mobile No
                </label>
                <Field
                  className="form-control"
                  name="businessAditionalCheckMobno"
                  type="number"
                  min="0"
                  max="9999999999"
                  onInput={(e) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAditionalCheckMobno"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAditionalCheckAddress">
                  Additional Check Address
                </label>
                <Field
                  className="form-control"
                  name="businessAditionalCheckAddress"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAditionalCheckAddress"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAditionalCheckFeedbackHowlongknow">
                  Feedback: How Long Known
                </label>
                <Field
                  className="form-control"
                  name="businessAditionalCheckFeedbackHowlongknow"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAditionalCheckFeedbackHowlongknow"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAditionalCheckFeedbackBehaviour">
                  Feedback: Behaviour
                </label>
                <Field
                  className="form-control"
                  name="businessAditionalCheckFeedbackBehaviour"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAditionalCheckFeedbackBehaviour"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAditionalCheckNegativeBehaviour">
                  Feedback: Negative Behaviour
                </label>
                <Field
                  className="form-control"
                  name="businessAditionalCheckNegativeBehaviour"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAditionalCheckNegativeBehaviour"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAditionalCheckAnyIllegalActivity">
                  Feedback: Any Illegal Activity
                </label>
                <Field
                  className="form-control"
                  name="businessAditionalCheckAnyIllegalActivity"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAditionalCheckAnyIllegalActivity"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessDetailsRemark">Details Remark</label>
                <Field
                  className="form-control"
                  name="businessDetailsRemark"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessDetailsRemark"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessDedupStatus">Dedup Status</label>
                <Field
                  className="form-control"
                  name="businessDedupStatus"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessDedupStatus"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessDedupDetails">Dedup Details</label>
                <Field
                  className="form-control"
                  name="businessDedupDetails"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessDedupDetails"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessFinalStatus">Final Status</label>
                <Field
                  as="select"
                  id="businessFinalStatus"
                  name="businessFinalStatus"
                  className="form-control"
                >
                  <option value="">Select Final Status</option>
                  <option value="Positive">Positive</option>
                  <option value="Negative">Negative</option>
                  <option value="Refer to Credit">Refer to Credit</option>
                </Field>

                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessFinalStatus"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessVerifierName">Verifier Name</label>
                <Field
                  className="form-control"
                  name="businessVerifierName"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessVerifierName"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessManagerName">Manager Name</label>
                <Field
                  className="form-control"
                  name="businessManagerName"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessManagerName"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAgencyName">Agency Name</label>
                <Field
                  className="form-control"
                  name="businessAgencyName"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAgencyName"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessVerificationStatus">
                  Verification Status
                </label>
                <Field
                  className="form-control"
                  name="businessVerificationStatus"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessVerificationStatus"
                />
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessAnyMatchFoundInNegativeDatabase">
                  Match Found in Negative Database
                </label>
                <Field
                  className="form-control"
                  name="businessAnyMatchFoundInNegativeDatabase"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessAnyMatchFoundInNegativeDatabase"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6 form-group ">
                <label htmlFor="businessProfileOfApplicant">
                  Profile of Applicant
                </label>
                <Field
                  className="form-control"
                  name="businessProfileOfApplicant"
                  type="text"
                />
                <ErrorMessage
                  component="div"
                  className="text-danger"
                  name="businessProfileOfApplicant"
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ApprovedUpdateBusiness;
