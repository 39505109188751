import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Pagination from "../../Pagination/Pagination";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function EmployerCreditAppraisal() {
  const [isLoader, setIsLoader] = useState(false);
  const [empCredit, setEmpCredit] = useState([]);
  const [showPrePage, setShowPrePage] = useState(100);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const [showModal, setShowModal] = useState(false);
  const [showRemarkModal, setShowRemarkModal] = useState(false);

  const [remark, setRemark] = useState("");
  const [message, setMessage] = useState("");
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  useEffect(() => {
    getEmployercreditappraiser();
  }, []);

  const getEmployercreditappraiser = () => {
    axios
      .request(`${APIURL}/corporate/employercreditappraiser`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response:::::::", response);
        setIsLoader(false);
        setEmpCredit(response?.data);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const Schema = Yup.object().shape({
    remarks: Yup.string().required("required"),
    commercialfees: Yup.string().required("required"),
    setupfees: Yup.string().required("required"),
    // employercode: Yup.string().required("required"),
    model: Yup.string().required("Please select an option"),
    companytype: Yup.string().required("Please select an option"),
    status: Yup.string().required("Please select an option"),
  });

  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Employer Credit Appraisal
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Employer name</th>
                          <th>Contact name</th>
                          <th>Mobile</th>
                          <th>Assured / Non Assured</th>
                          <th>Assured Category Assigned A/B/C</th>
                          <th>Commercials Assigned</th>
                          <th>Give Employer code</th>
                          <th>Convenience fee</th>
                          <th>Setup fees</th>
                          <th>Remark</th>
                          <th>Status</th>
                          <th>update</th>
                        </tr>
                      </thead>
                      <tbody>
                        {empCredit?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.employer_name}</td>
                            <td>{item.employee_name}</td>
                            <td>{item.mobile}</td>
                            <td>{item.model}</td>
                            <td>{item.companytype}</td>
                            <td>{item.commercialassigned}</td>
                            <td>{item.employercode}</td>
                            <td>{item.commercialfees}</td>
                            <td>{item.setupfees}</td>
                            <td>{item?.remarks}</td>
                            <td>{item?.status}</td>
                            <td>
                              <button
                                className="btn btn-warning"
                                onClick={() => {
                                  setRemark(item);
                                  setShowModal(true);
                                }}
                              >
                                Update
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    style={{ background: "rgb(255 255 255 / 40%)" }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Formik
                        initialValues={{
                          model: remark?.model || "",
                          companytype: remark?.companytype || "",
                          // employercode: remark?.employercode || "",
                          commercialfees: remark?.commercialfees || "",
                          setupfees: remark?.setupfees || "",
                          status: remark?.status || "",
                          remarks: remark?.remarks || "",
                        }}
                        validationSchema={Schema}
                        onSubmit={(values, { resetForm }) => {
                          console.log("values", values);
                          setIsLoader(true);
                          axios
                            .request(
                              `${APIURL}/corporate/employercreditappraiserUpdate`,
                              {
                                method: "POST",
                                data: values,
                                headers: {
                                  "Content-Type": "application/json",
                                },
                              }
                            )
                            .then((response) => {
                              console.log("response", response);
                              setIsLoader(false);
                              getEmployercreditappraiser();
                              toast.success("updated successfully");
                              setShowModal(false);
                            })
                            .catch((error) => {
                              setIsLoader(false);
                              console.log(error);
                            });
                        }}
                      >
                        {({ touched, errors }) => (
                          <>
                            <Form className="">
                              <div>
                                <div>
                                  <div className="form-group custom-select-wrapper">
                                    <label>Assured / Non Assured*</label>
                                    <Field
                                      as="select"
                                      name="model"
                                      placeholder="Enter model"
                                      className={`form-control ${
                                        touched.model && errors.model
                                          ? "is-invalid"
                                          : ""
                                      } custom-select`}
                                    >
                                      <option value="">Select..</option>
                                      <option value="Assured">Assured</option>
                                      <option value="Non Assured">
                                        Non Assured
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      component="div"
                                      name="model"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="mt-2 form-group custom-select-wrapper">
                                    <label>Assured Category Assigned*</label>
                                    <Field
                                      as="select"
                                      name="companytype"
                                      placeholder="Enter companytype"
                                      className={`form-control
                          ${
                            touched.companytype && errors.companytype
                              ? "is-invalid"
                              : ""
                          } custom-select`}
                                    >
                                      <option value="">Select..</option>
                                      <option value="A">A</option>
                                      <option value="B">B</option>
                                      <option value="C">C</option>
                                    </Field>
                                    <ErrorMessage
                                      component="div"
                                      name="companytype"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  {/* <div className="mt-2">
                                    <label>Employer code*</label>
                                    <Field
                                      type="number"
                                      name="employercode"
                                      placeholder="Enter employer code"
                                      className={`form-control
                          ${
                            touched.employercode && errors.employercode
                              ? "is-invalid"
                              : ""
                          }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="employercode"
                                      className="invalid-feedback"
                                    />
                                  </div> */}
                                  <div className="form-group custom-select-wrapper">
                                    <label>Status*</label>
                                    <Field
                                      as="select"
                                      name="status"
                                      placeholder="Enter status"
                                      className={`form-control ${
                                        touched.status && errors.status
                                          ? "is-invalid"
                                          : ""
                                      } custom-select`}
                                    >
                                      <option value="">Select..</option>
                                      <option value="APPROVED">APPROVED</option>
                                      <option value="REJECTED">REJECTED</option>
                                    </Field>
                                    <ErrorMessage
                                      component="div"
                                      name="status"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="form-group custom-select-wrapper">
                                    <label>Convenience fee*</label>
                                    <Field
                                      as="select"
                                      name="commercialfees"
                                      placeholder="Enter commercial fees"
                                      className={`form-control ${
                                        touched.commercialfees &&
                                        errors.commercialfees
                                          ? "is-invalid"
                                          : ""
                                      } custom-select`}
                                    >
                                      <option value="">Select..</option>
                                      <option value="1%">1%</option>
                                      <option value="2%">2%</option>
                                      <option value="3%">3%</option>
                                      <option value="4%">4%</option>
                                      <option value="5%">5%</option>
                                    </Field>
                                    <ErrorMessage
                                      component="div"
                                      name="commercialfees"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <label>Setup fee*</label>
                                    <Field
                                      type="number"
                                      name="setupfees"
                                      placeholder="Enter setup fee"
                                      className={`form-control
                          ${
                            touched.setupfees && errors.setupfees
                              ? "is-invalid"
                              : ""
                          }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="setupfees"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <label>Remark*</label>
                                    <Field
                                      type="text"
                                      name="remarks"
                                      placeholder="Enter remark"
                                      className={`form-control
                          ${
                            touched.remarks && errors.remarks
                              ? "is-invalid"
                              : ""
                          }`}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="remarks"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                  <button
                                    className="btn btn-success mt-3 border"
                                    type="submit"
                                    style={{ backgroundColor: "#66a725" }}
                                  >
                                    {isLoader ? (
                                      <div
                                        className="spinner-border text-success"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Submit"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </Modal.Body>
                  </Modal>
                  {empCredit && empCredit.length !== 0 && (
                    <Pagination
                      showPrePage={showPrePage}
                      onPaginationChange={onPaginationChange}
                      total={empCredit.length}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .custom-select-wrapper {
          position: relative;
        }

        .custom-select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>')
            no-repeat right 0.75rem center/8px 10px;
          padding-right: 1.5rem;
        }
      `}</style>
    </div>
  );
}

export default EmployerCreditAppraisal;
