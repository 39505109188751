import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { APIURL } from "../../../Config";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Pagination from "../../Pagination/Pagination";
import { Modal } from "react-bootstrap";
import MapContainer from "./MapContainer";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import SearchDetails from "../../SearchDetails";

function CreditAppraisalForMagic() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [tvrPending, setTvrPending] = useState([]);
  const [tvrRejected, setTvrRejected] = useState([]);
  const [neftSuccessData, setNeftSuccessData] = useState([]);
  const [instantDisburse, setInstantDisburse] = useState([]);
  const [neftRejectedData, setNeftRejectedData] = useState([]);
  const [reHitPennyDrop, setReHitPennyDrop] = useState([]);
  const [data, setData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [showPrePage, setShowPrePage] = useState(100);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPrePage,
  });
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [password, setPassword] = useState("");
  const [clientPhotoBase64, setClientPhotoBase64] = useState(null);
  const [approve, setApprove] = useState("");
  const [mapValue, setMapValue] = useState("");
  const [mapStatus, setMapStatus] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [hide, setHide] = useState(false);
  const [loanId, setLoanId] = useState("");
  const [lid, setLid] = useState("");
  const [appClientId, setAppClientId] = useState("");
  const TVRPending = React.useRef(null);
  const NEFTData = React.useRef(null);
  const InstantDisbursed = React.useRef(null);
  const NEFTRejected = React.useRef(null);
  const TVRRejected = React.useRef(null);
  const ReHitDrop = React.useRef(null);
  const fileInputRef = useRef(null);
  const [alert, setAlert] = useState(null);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    if (activeTab === "tab1") {
      getTvrPendingDetails();
    }
    if (activeTab === "tab3") {
      getAllNeftSuccessData();
    }
    if (activeTab === "tab2") {
      getInstantDisburse();
    }
    if (activeTab === "tab4") {
      getAllNeftRejectedData();
    }
    if (activeTab === "tab5") {
      getAllRejectedCases();
    }
    if (activeTab === "tab6") {
      getReHitPennyDrop();
    }
  }, [activeTab]);

  function getTvrPendingDetails() {
    axios
      .request(`${APIURL}/getTvrPendingDetails`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTvrPending(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getAllNeftSuccessData() {
    axios
      .request(`${APIURL}/getAllNeftSuccessData`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setNeftSuccessData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getInstantDisburse() {
    axios
      .request(`${APIURL}/getInstantDisburseApprovedCases`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setInstantDisburse(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAllNeftRejectedData() {
    axios
      .request(`${APIURL}/getAllNeftRejectedData`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setNeftRejectedData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getAllRejectedCases() {
    axios
      .request(`${APIURL}/getAllRejectedCases`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setTvrRejected(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getReHitPennyDrop() {
    axios
      .request(`${APIURL}/getReHitPennyDrop`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        if (typeof response?.data !== "string") {
          setReHitPennyDrop(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getClientDetailsMagic(loanId) {
    axios
      .request(`${APIURL}/getClientDetailsMagic/${loanId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setData(response?.data);
        console.log("response::::", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function allUploadedDocs() {
    axios
      .request(`${APIURL}/allUploadedDocs/${loanId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function AccountAggregator() {
    window.open(`${APIURL}/download/${loanId}`);
  }
  function groLocation() {
    axios
      .request(`${APIURL}/clientdetails/${loanId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setShowModal(true);
        console.log("response", response);
        setMapValue(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function companyLocation() {
    axios
      .request(`${APIURL}/getGeoLocation/${loanId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function epfDetails() {
    axios
      .request(`${APIURL}/epfdetails/${loanId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleDetailsChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        const base64String = loadEvent.target.result;
        setClientPhotoBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const employerUploadContact = () => {
    const dto = {
      mobileNo: data.mobile,
      appclientid: data.appClientId ? data.appClientId : data.appclientid,
      latitude: "28.6679571",
      longitude: "77.2302148",
      clientPhotoBase64: clientPhotoBase64,
      clientUID: data?.enrollmentid,
    };
    console.log("data", dto);
    axios
      .request(`${APIURL}/employerUploadContact`, {
        method: "POST",
        data: dto,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setShowUploadModal(false);
        setIsLoader(false);
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (approvalStatus == 2) {
      if (password == "ctlA") {
        setShowPasswordModal(false);
        setShowApproveModal(true);
      } else {
        toast.warning("Wrong Password");
      }
    }
    if (approvalStatus == 3) {
      if (password == "ctlR") {
        submitData();
        setShowPasswordModal(false);
      } else {
        toast.warning("Wrong Password");
      }
    }
  };

  const handleApprove = (status) => {
    if (status == "new") {
      console.log("status", status);
      setApprove("new");
      submitData(status);
      setPassword("");
      setRemark("");
    }
    if (status == "ignore") {
      pop(status);
      setPassword("");
      setRemark("");
    }
  };

  const submitData = (status) => {
    console.log("approve", approve);
    const appId = localStorage.getItem("userId");
    const dto = {
      actionType: status,
      appclientid: data.appClientId ? data.appClientId : data.appclientid,
      creditstatus: approvalStatus,
      borrname: data.clientName,
      borrcontactno: data.mobile,
      borrremark: remark,
      approvedby: appId,
    };
    const formData = new FormData();

    for (const key in dto) {
      if (dto.hasOwnProperty(key)) {
        formData.append(key, dto[key]);
      }
    }
    console.log("dto", dto);
    axios
      .request(`${APIURL}/greenfinch/saveca`, {
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("response", response);
        setIsLoader(false);
        setShowApproveModal(false);
        toast.success(response?.data?.remark);
        if (response?.data?.remark) {
          setHide(true);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };
  const pop = (status) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          setApprove(status);
          submitData(status);
          setShowApproveModal(false);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
          setShowApproveModal(false);
        }}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Are you confirm?"
        cancelBtnText="Cancel"
        showCancel
      ></SweetAlert>
    );
  };
  const instantDisburseApprove = (appCientId) => {
    const data = { appClientId: appCientId, approvedby: 16 };

    axios
      .request(`${APIURL}/approvedAndReject`, {
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setIsLoader(false);
        toast.success("Approve Successfully.");
        getInstantDisburse();
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const instantDisburseReject = (appCientId) => {
    axios
      .request(`${APIURL}/rejectPennyDrop/${appCientId}`, {
        method: "GET",
      })
      .then((response) => {
        console.log("response", response);
        setIsLoader(false);
        toast.success("Rejected Successfully");
        getInstantDisburse();
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const reHitPennyDropApprove = (id) => {
    axios
      .request(`${APIURL}/getReHitPennyDropApproved/${id}`, {
        method: "GET",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setIsLoader(false);
        toast.success("ReHitPennyDrop Approve Successfully");
        getReHitPennyDrop();
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  const reHitPennyDropReject = (id) => {
    axios
      .request(`${APIURL}/rejectPennyDrop/${id}`, {
        method: "GET",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        setIsLoader(false);
        toast.success("ReHitPennyDrop Rejected Successfully");
        getReHitPennyDrop();
      })
      .catch((error) => {
        setIsLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="p-3">
      <div>
        <div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link tab-item ${
                  activeTab === "tab1" ? "active" : ""
                }`}
                id="tab1-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab1"
                type="button"
                role="tab"
                aria-controls="tab1"
                aria-selected={activeTab === "tab1"}
                onClick={() => handleTabClick("tab1")}
              >
                TVR Pending
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link tab-item ${
                  activeTab === "tab2" ? "active" : ""
                }`}
                id="tab2-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab2"
                type="button"
                role="tab"
                aria-controls="tab2"
                aria-selected={activeTab === "tab2"}
                onClick={() => handleTabClick("tab2")}
              >
                Instant Disbursed
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link tab-item ${
                  activeTab === "tab3" ? "active" : ""
                }`}
                id="tab3-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab3"
                type="button"
                role="tab"
                aria-controls="tab3"
                aria-selected={activeTab === "tab3"}
                onClick={() => handleTabClick("tab3")}
              >
                NEFT Success Data
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link tab-item ${
                  activeTab === "tab4" ? "active" : ""
                }`}
                id="tab4-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab4"
                type="button"
                role="tab"
                aria-controls="tab4"
                aria-selected={activeTab === "tab4"}
                onClick={() => handleTabClick("tab4")}
              >
                NEFT Rejected Data
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link tab-item ${
                  activeTab === "tab5" ? "active" : ""
                }`}
                id="tab5-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab5"
                type="button"
                role="tab"
                aria-controls="tab5"
                aria-selected={activeTab === "tab5"}
                onClick={() => handleTabClick("tab5")}
              >
                TVR Rejected
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link tab-item ${
                  activeTab === "tab6" ? "active" : ""
                }`}
                id="tab6-tab"
                data-bs-toggle="tab"
                data-bs-target="#tab6"
                type="button"
                role="tab"
                aria-controls="tab6"
                aria-selected={activeTab === "tab6"}
                onClick={() => handleTabClick("tab6")}
              >
                ReHit Penny Drop
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "tab1" ? "show active" : ""
              }`}
              id="tab1"
              role="tabpanel"
              aria-labelledby="tab1-tab"
            >
              <div className="pt-3">
                {!show ? (
                  <div className="card">
                    <div className="p-3">
                      {showSearch ? (
                        <>
                          <div className="p-3 d-flex justify-content-start mt-3">
                            <button
                              className="btn btn-secondary"
                              style={{ width: "100px" }}
                              onClick={() => {
                                setShowSearch(false);
                              }}
                            >
                              Back
                            </button>
                          </div>
                          <div className="p-3 pt-2">
                            <SearchDetails
                              keyName="appClientId"
                              searchInput={appClientId}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="d-flex ms-2">
                            <DownloadTableExcel
                              filename="TVRPending"
                              sheet="users"
                              currentTableRef={TVRPending.current}
                            >
                              <button
                                className="btn btn-success border"
                                style={{
                                  width: "120px",
                                  backgroundColor: "#66a725",
                                }}
                              >
                                {" "}
                                Download{" "}
                              </button>
                            </DownloadTableExcel>
                          </div>
                          <div className="table-responsive mt-3">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th className="text-nowrap">Sr. No</th>
                                  <th className="text-nowrap">App Client Id</th>
                                  <th className="text-nowrap">Client Name</th>
                                  <th className="text-nowrap">Mobile No</th>
                                  <th className="text-nowrap">Loan Type</th>
                                  <th className="text-nowrap">Scheme No.</th>
                                  <th className="text-nowrap">
                                    Sanction Amount
                                  </th>
                                  <th className="text-nowrap">
                                    Verification Date
                                  </th>
                                  <th className="text-nowrap">CA Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tvrPending
                                  ?.slice(pagination.start, pagination.end)
                                  ?.map((item, i) => (
                                    <tr key={i}>
                                      <td>{i + 1 + pagination.start}</td>
                                      <td
                                        className="text-nowrap"
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          if (item.appClientId) {
                                            setAppClientId(item.appClientId);
                                            setShowSearch(true);
                                          }
                                        }}
                                      >
                                        {item.appClientId}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.clientName}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.clientmobileno}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.loanType}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.schemeNo}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.sanctionAmount}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.verificationDate}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-success"
                                          onClick={() => {
                                            setShow(true);
                                            setLoanId(item?.loanid);
                                            setLoanId(item?.appClientId);
                                            getClientDetailsMagic(
                                              item?.appClientId
                                            );
                                          }}
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          {tvrPending && tvrPending.length !== 0 && (
                            <Pagination
                              showPrePage={showPrePage}
                              onPaginationChange={onPaginationChange}
                              total={tvrPending.length}
                            />
                          )}
                        </>
                      )}

                      <table
                        className="table table-striped mt-3 "
                        style={{ display: "none" }}
                        ref={TVRPending}
                      >
                        <thead>
                          <tr>
                            <th className="text-nowrap">Sr. No</th>
                            <th className="text-nowrap">App Client Id</th>
                            <th className="text-nowrap">Client Name</th>
                            <th className="text-nowrap">Mobile No</th>
                            <th className="text-nowrap">Loan Type</th>
                            <th className="text-nowrap">Scheme No.</th>
                            <th className="text-nowrap">Sanction Amount</th>
                            <th className="text-nowrap">Verification Date</th>
                            <th className="text-nowrap">CA Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tvrPending?.map((item, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>

                              <td className="text-nowrap">
                                {item.appClientId}
                              </td>
                              <td className="text-nowrap">{item.clientName}</td>
                              <td className="text-nowrap">
                                {item.clientmobileno}
                              </td>
                              <td className="text-nowrap">{item.loanType}</td>
                              <td className="text-nowrap">{item.schemeNo}</td>
                              <td className="text-nowrap">
                                {item.sanctionAmount}
                              </td>
                              <td className="text-nowrap">
                                {item.verificationDate}
                              </td>
                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <>
                    {alert}
                    <div className="card">
                      <div className="p-3">
                        <div className="d-flex justify-content-end">
                          <div>
                            <button
                              className="btn btn-success"
                              onClick={() => setShow(false)}
                              style={{
                                width: "100px",
                                color: "#66a725",
                                background: "transparent",
                                border: "0px",
                                textDecoration: "underline",
                                fontSize: "18px",
                              }}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                        <div className="mt-3">
                          <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                              >
                                <a
                                  href={`${APIURL}/allUploadedDocs/${loanId}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  ID Photo
                                </a>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                              >
                                <a href="#" style={{ textDecoration: "none" }}>
                                  Self Photo
                                </a>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                              >
                                <a
                                  href={`${APIURL}/epfdetails/${loanId}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  EPF DETAILS
                                </a>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                              >
                                <a href="#" style={{ textDecoration: "none" }}>
                                  PAN CARD
                                </a>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                              >
                                <a
                                  href={`${APIURL}/download/${loanId}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  ACCOUNT AGGREGATOR
                                </a>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                              >
                                <a href="#" style={{ textDecoration: "none" }}>
                                  DEVICE DETAILS
                                </a>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                                onClick={companyLocation}
                                style={{ textDecoration: "none" }}
                              >
                                COMPANY LOCATION
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className={`nav-link tab-link `}
                                role="tab"
                                style={{ textDecoration: "none" }}
                                onClick={groLocation}
                              >
                                GEOLOCATION
                              </button>
                            </li>
                          </ul>
                        </div>

                        <Modal
                          size="lg"
                          show={showModal}
                          onHide={handleModalClose}
                          style={{ background: "#ffffffc4" }}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>GEOLOCATION</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <MapContainer data={mapValue} status={mapStatus} />
                          </Modal.Body>
                        </Modal>

                        <div
                          style={{
                            fontFamily: "PostGrotesk",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="row mt-3">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div
                                className=""
                                style={{
                                  borderRadius: "0px",
                                  border: "2px solid #CACACA",
                                }}
                              >
                                <h5 className="p-2">Loan Details</h5>
                                <hr />
                                <table
                                  className="table table-striped m-0"
                                  style={{
                                    tableLayout: "fixed",
                                    border: "none",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Loan Id
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data?.loanid}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Client Id
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data?.enrollmentid}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Sanction Amount
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data?.sanctionAmount}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Scheme No
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data?.schemeNo}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Product
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data?.product}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>Period</th>
                                      <th style={{ border: "none" }}>
                                        {data?.period}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>EMI</th>
                                      <th style={{ border: "none" }}>
                                        {data?.emi}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Equifax 360
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.score}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Pincode
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.pincode}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>E-Mail</th>
                                      <th style={{ border: "none" }}>
                                        {data.emailid}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Date Of Exit
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.dateofexit}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Office Pincode
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.officeaddress}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Client Account No
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.clientaccountno}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="mt-3">
                                  <span className="fw-bold ms-2">
                                    Borrower KYC Address :-
                                  </span>
                                  <br />
                                  <div
                                    className="ms-3"
                                    style={{ fontSize: "18px" }}
                                  >
                                    <span>
                                      {data.clienthouseno && (
                                        <span>{data.clienthouseno}, </span>
                                      )}
                                      {data.clientstreet && (
                                        <span>{data.clientstreet}, </span>
                                      )}
                                      {data.clientlocality && (
                                        <span>{data.clientlocality}, </span>
                                      )}
                                      {data.clientlandmark && (
                                        <span>{data.clientlandmark}, </span>
                                      )}
                                      {data.clienttown && (
                                        <span>{data.clienttown}, </span>
                                      )}
                                      {data.clientpostoffice && (
                                        <span>{data.clientpostoffice}, </span>
                                      )}
                                      {data.clientsubdistrict && (
                                        <span>{data.clientsubdistrict}, </span>
                                      )}
                                      {data.clientdistrict && (
                                        <span>{data.clientdistrict}, </span>
                                      )}
                                      {data.clientstate && (
                                        <span>{data.clientstate}, </span>
                                      )}
                                      {data.clientpincode && (
                                        <span>{data.clientpincode}</span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div
                                className=""
                                style={{
                                  borderRadius: "0px",
                                  border: "2px solid #CACACA",
                                }}
                              >
                                <h5 className="p-2">Borrower Details</h5>
                                <hr />
                                <table
                                  className="table table-striped m-0"
                                  style={{
                                    tableLayout: "fixed",
                                    border: "none",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        App Client Id
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.appClientId
                                          ? data.appClientId
                                          : data.appclientid}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>Name</th>
                                      <th style={{ border: "none" }}>
                                        {data.name || data.clientname}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>Gender</th>
                                      <th style={{ border: "none" }}>
                                        {data.gender === "M"
                                          ? "Male"
                                          : data.gender === "F"
                                          ? "Female"
                                          : ""}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>C/O</th>
                                      <th style={{ border: "none" }}>
                                        {data.careOf}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Relation
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.relation === "M"
                                          ? "Mother"
                                          : data.relation === "F"
                                          ? "Father"
                                          : data.relation === "H"
                                          ? "Husband"
                                          : data.relation === "W"
                                          ? "Wife"
                                          : ""}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Date Of Birth
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.dateOfBirth
                                          ? moment(data.dateOfBirth).format(
                                              "DD-MM-YYYY"
                                            )
                                          : ""}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>Mobile</th>
                                      <th style={{ border: "none" }}>
                                        {data.mobile}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        KYC Aadhar Card
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.kycAadhaarNumber ||
                                          data.kycNumber}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Employee Type
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.employeeType === 1
                                          ? "Salaried Employee"
                                          : data.employeeType === 2
                                          ? "Self Employee"
                                          : ""}
                                      </th>
                                    </tr>
                                    {data.employeeType === 2 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Average Eod Balance
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.averageEodBalance}
                                        </th>
                                      </tr>
                                    )}
                                    {data.employeeType === 2 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Total No of Credit
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.totalNoOfCreditTransactions}
                                        </th>
                                      </tr>
                                    )}
                                    {data.employeeType === 2 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Total No of Debit
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.totalNoOfDebitTransactions}
                                        </th>
                                      </tr>
                                    )}
                                    {data.employeeType === 1 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Employee Name
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.employerName}
                                        </th>
                                      </tr>
                                    )}
                                    {data.employeeType === 1 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Date Of Joining
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {moment(data.dateOfJoining).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </th>
                                      </tr>
                                    )}
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Equifax Score Microfinance
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.equifaxScoreMicrofinance}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Equifax Score Retail
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.equifaxScoreRetail}
                                      </th>
                                    </tr>
                                    {data.employeeType === 1 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Account Aggregator Name
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.accountAggregatorName}
                                        </th>
                                      </tr>
                                    )}
                                    {data.employeeType === 1 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          First Month Salary
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.firstMonthSalary}
                                        </th>
                                      </tr>
                                    )}
                                    {data.employeeType === 1 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Second Month Salary
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.secondMonthSalary}
                                        </th>
                                      </tr>
                                    )}
                                    {data.employeeType === 1 && (
                                      <tr>
                                        <th style={{ border: "none" }}>
                                          Third Month Salary
                                        </th>
                                        <th style={{ border: "none" }}>
                                          {data.thirdMonthSalary}
                                        </th>
                                      </tr>
                                    )}
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Account No
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.accountno}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Establishment Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.establishmentName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        EPF Employer
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.epfisemployed}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        EPF Total Amount
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.epftotalamount}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        EPF Employees Count
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.epfemployeescont}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        EPF Date Of Joining
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.epfdateofjoining}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div
                                className=""
                                style={{
                                  borderRadius: "0px",
                                  border: "2px solid #CACACA",
                                }}
                              >
                                <h5 className="p-2">Reference Details</h5>
                                <hr />
                                <table
                                  className="table table-striped m-0"
                                  style={{
                                    tableLayout: "fixed",
                                    border: "none",
                                  }}
                                >
                                  <tbody>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Client Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.clientName || data.clientname}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Account Aggregator Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.accountAggregatorName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Current Organisation Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.currentOrganisationName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        EPF Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.epfName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Sim Owner Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.simOwnerName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        PAN Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.panName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        First Reference Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.firstRefName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        First Reference Relation
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.firstRefRelation}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        First Reference Contact No
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.firstRefMobile}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Friend Name
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.friendName}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Friend Contact No
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.friendMobileno}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th style={{ border: "none" }}>
                                        Friend No Of Years
                                      </th>
                                      <th style={{ border: "none" }}>
                                        {data.friendNoOfYears}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div className="mt-5 d-flex justify-content-center">
                            <table className="table table-bordered w-100 w-md-75 border">
                              <tbody>
                                {!hide && (
                                  <>
                                    <tr className="border">
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          border: "none",
                                        }}
                                      >
                                        Approval Status
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                        }}
                                      >
                                        <div className="row mt-2">
                                          <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                                            <input
                                              type="radio"
                                              name="shipSpeed"
                                              value="2"
                                              checked={approvalStatus === "2"}
                                              onChange={(e) =>
                                                setApprovalStatus(
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                height: "18px",
                                                width: "18px",
                                              }}
                                            />
                                            <label className="m-0">
                                              Approve
                                            </label>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                                            <input
                                              type="radio"
                                              name="shipSpeed"
                                              value="3"
                                              checked={approvalStatus === "3"}
                                              onChange={(e) =>
                                                setApprovalStatus(
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                height: "18px",
                                                width: "18px",
                                              }}
                                            />
                                            <label className="m-0">
                                              Reject
                                            </label>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center mt-3 mb-2">
                                          <input
                                            type="radio"
                                            onClick={() =>
                                              setShowUploadModal(true)
                                            }
                                            style={{
                                              height: "18px",
                                              width: "18px",
                                            }}
                                          />
                                          <label className="m-0">
                                            Employer contact Details Upload
                                          </label>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="border">
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          border: "none",
                                        }}
                                      >
                                        Remark:
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                        }}
                                      >
                                        <textarea
                                          value={remark}
                                          onChange={(e) =>
                                            setRemark(e.target.value)
                                          }
                                          style={{ width: "80%" }}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="border">
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          border: "none",
                                        }}
                                      >
                                        Approval
                                      </td>
                                      <td
                                        style={{
                                          border: "none",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          className="btn btn-success"
                                          style={{ background: "#66a725" }}
                                          onClick={() =>
                                            setShowPasswordModal(true)
                                          }
                                        >
                                          Submit
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                )}

                                <Modal
                                  show={showUploadModal}
                                  onHide={() => setShowUploadModal(false)}
                                  centered
                                  style={{ background: "#ffffffd1" }}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Employer contact Details Upload
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <input
                                      type="file"
                                      onChange={handleDetailsChange}
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        boxSizing: "border-box",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        marginTop: "10px",
                                      }}
                                    />
                                    <div className="d-flex mt-3 justify-content-end">
                                      <button
                                        className="btn btn-secondary"
                                        style={{ width: "100px" }}
                                        onClick={() =>
                                          setShowUploadModal(false)
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn btn-success ml-2"
                                        style={{
                                          width: "100px",
                                          background: "#66a725",
                                        }}
                                        onClick={employerUploadContact}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </Modal.Body>
                                </Modal>
                                <Modal
                                  show={showPasswordModal}
                                  onHide={() => setShowPasswordModal(false)}
                                  centered
                                  style={{ background: "#ffffffd1" }}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Enter Password</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <input
                                      type="password"
                                      value={password}
                                      onChange={handlePasswordChange}
                                      style={{
                                        width: "100%",
                                        padding: "10px",
                                        boxSizing: "border-box",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        marginTop: "10px",
                                      }}
                                    />
                                    <div className="d-flex mt-3 justify-content-end">
                                      <button
                                        className="btn btn-secondary"
                                        style={{ width: "100px" }}
                                        onClick={() =>
                                          setShowPasswordModal(false)
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        className="btn btn-success ml-2"
                                        style={{
                                          width: "100px",
                                          background: "#66a725",
                                        }}
                                        onClick={handleSubmit}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </Modal.Body>
                                </Modal>
                                <Modal
                                  show={showApproveModal}
                                  onHide={() => setShowApproveModal(false)}
                                  centered
                                  style={{ background: "#ffffffd1" }}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body style={{ height: "120px" }}>
                                    <div className="d-flex mt-3 justify-content-center">
                                      <button
                                        className="btn btn-secondary"
                                        style={{
                                          background: "#66a725",
                                        }}
                                        onClick={() => handleApprove("new")}
                                      >
                                        New
                                      </button>
                                      <button
                                        className="btn btn-success ml-2"
                                        style={{
                                          background: "#66a725",
                                        }}
                                        onClick={() => handleApprove("ignore")}
                                      >
                                        Ignore
                                      </button>
                                    </div>
                                  </Modal.Body>
                                </Modal>
                                <tr
                                  className="border"
                                  style={{ background: "#66a725" }}
                                >
                                  <td
                                    style={{
                                      fontWeight: "bold",
                                      border: "none",
                                    }}
                                  >
                                    Approval Status
                                  </td>
                                  <td
                                    style={{
                                      border: "none",
                                      textAlign: "center",
                                    }}
                                  >
                                    {data.cibli_status === 0 && (
                                      <span>
                                        <span style={{ fontWeight: "bold" }}>
                                          Equifax Rejected
                                        </span>{" "}
                                        on {data.cibil_date}
                                      </span>
                                    )}
                                    <span>
                                      <span style={{ fontWeight: "bold" }}>
                                        Further Action
                                      </span>{" "}
                                      by
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {/* {(data.grtApprovalStatus === 0 ||
        data.cibli_status === 0 ||
        data.creditstatus === 2 ||
        data.creditstatus === 3 ||
        data.isDeleted === 1) && (
        <Table bordered responsive style={{ width: "70%", marginLeft: "15%" }}>
          <tbody>
            <tr>
              <td style={{ fontWeight: "bold", borderRight: "none", borderTop: "none" }}>
                Remark:
              </td>
              <td style={{ borderLeft: "none", borderTop: "none", width: "60%" }}>
                {remark}
              </td>
            </tr>
            {[3, 4].includes(data.creditstatus) && (
              <tr>
                <td style={{ fontWeight: "bold", borderRight: "none" }}>
                  Reason
                </td>
                <td style={{ borderLeft: "none", textAlign: "center" }}>
                  {data.otherReason == null ? data.reason : data.otherReason}
                </td>
              </tr>
            )}
            <tr style={{ backgroundColor: "#A8BB21" }}>
              <td style={{ fontWeight: "bold", borderRight: "none" }}>
                Approval Status
              </td>
              <td style={{ borderLeft: "none", textAlign: "center" }}>
                {data.creditstatus === 2 && (
                  <span>
                    <span style={{ fontWeight: "bold" }}>TVR Approved</span> by {data.approvedbyname} ({data.approvedby}) on {data.sanctionletterdate}
                  </span>
                )}
                {data.creditstatus === 3 && (
                  <span>
                    <span style={{ fontWeight: "bold" }}>TVR Rejected</span> by {data.approvedbyname} ({data.approvedby}) on {data.sanctionletterdate} Reason- {data.reason}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "tab2" ? "show active" : ""
              }`}
              id="tab2"
              role="tabpanel"
              aria-labelledby="tab2-tab"
            >
              <div className="pt-3">
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex ms-2">
                      <DownloadTableExcel
                        filename="NEFT Data"
                        sheet="users"
                        currentTableRef={InstantDisbursed.current}
                      >
                        <button
                          className="btn btn-success border"
                          style={{
                            width: "120px",
                            backgroundColor: "#66a725",
                          }}
                        >
                          {" "}
                          Download{" "}
                        </button>
                      </DownloadTableExcel>
                    </div>
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Sr. No</th>
                            <th className="text-nowrap">Customer Id</th>
                            <th className="text-nowrap">Appclient Id</th>
                            <th className="text-nowrap">Client Name</th>
                            <th className="text-nowrap">Branch Name</th>
                            <th className="text-nowrap">Account No</th>
                            <th className="text-nowrap">IFSC Code</th>
                            <th className="text-nowrap">Bank Name</th>
                            <th className="text-nowrap">Bank Branch</th>
                            <th className="text-nowrap">Imps BeneAccDest</th>
                            <th className="text-nowrap">Imps BeneNameDest</th>
                            <th className="text-nowrap">Cheque InFavour</th>
                            <th className="text-nowrap">EMI</th>

                            <th className="text-nowrap">No Of Installment</th>
                            <th className="text-nowrap">Client MobileNo</th>
                            <th className="text-nowrap">Approve</th>
                            <th className="text-nowrap">Reject</th>
                          </tr>
                        </thead>
                        <tbody>
                          {instantDisburse
                            ?.slice(pagination.start, pagination.end)
                            ?.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1 + pagination.start}</td>
                                <td className="text-nowrap">
                                  {item.customerId}
                                </td>
                                <td className="text-nowrap">
                                  {item.appclientid}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientName}
                                </td>
                                <td className="text-nowrap">
                                  {item.branchName}
                                </td>
                                <td className="text-nowrap">
                                  {item.accountNo}
                                </td>
                                <td className="text-nowrap">{item.ifscCode}</td>
                                <td className="text-nowrap">{item.bankName}</td>
                                <td className="text-nowrap">
                                  {item.bankBranch}
                                </td>
                                <td className="text-nowrap">
                                  {item.impsBeneAccDest}
                                </td>
                                <td className="text-nowrap">
                                  {item.impsBeneNameDest}
                                </td>
                                <td className="text-nowrap">
                                  {item.chequeInFavour}
                                </td>
                                <td className="text-nowrap">{item.emi}</td>

                                <td className="text-nowrap">
                                  {item.noOfInstallment}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientmobileno}
                                </td>
                                <td className="text-nowrap">
                                  <button
                                    className="btn btn-success"
                                    onClick={() =>
                                      instantDisburseApprove(item.appclientid)
                                    }
                                  >
                                    {isLoader ? (
                                      <div
                                        className="spinner-border text-success"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Approve"
                                    )}
                                  </button>
                                </td>
                                <td className="text-nowrap">
                                  <button
                                    className="btn btn-success"
                                    onClick={() =>
                                      instantDisburseReject(item.appclientid)
                                    }
                                  >
                                    {isLoader ? (
                                      <div
                                        className="spinner-border text-success"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Reject"
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {instantDisburse && instantDisburse.length !== 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={instantDisburse.length}
                      />
                    )}
                    <table
                      className="table table-striped mt-3 "
                      style={{ display: "none" }}
                      ref={InstantDisbursed}
                    >
                      <thead>
                        <tr>
                          <th className="text-nowrap">Sr. No</th>
                          <th className="text-nowrap">Customer Id</th>
                          <th className="text-nowrap">Appclient Id</th>
                          <th className="text-nowrap">Client Name</th>
                          <th className="text-nowrap">Branch Name</th>
                          <th className="text-nowrap">Account No</th>
                          <th className="text-nowrap">IFSC Code</th>
                          <th className="text-nowrap">Bank Name</th>
                          <th className="text-nowrap">Bank Branch</th>
                          <th className="text-nowrap">Imps BeneAccDest</th>
                          <th className="text-nowrap">Imps BeneNameDest</th>
                          <th className="text-nowrap">Cheque InFavour</th>
                          <th className="text-nowrap">EMI</th>
                          <th className="text-nowrap">No Of Installment</th>
                          <th className="text-nowrap">
                            Net DisbursementAmount
                          </th>
                          <th className="text-nowrap">Client MobileNo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {instantDisburse?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td className="text-nowrap">{item.customerId}</td>
                            <td className="text-nowrap">{item.appclientid}</td>
                            <td className="text-nowrap">{item.clientName}</td>
                            <td className="text-nowrap">{item.branchName}</td>
                            <td className="text-nowrap">{item.accountNo}</td>
                            <td className="text-nowrap">{item.ifscCode}</td>
                            <td className="text-nowrap">{item.bankName}</td>
                            <td className="text-nowrap">{item.bankBranch}</td>
                            <td className="text-nowrap">
                              {item.impsBeneAccDest}
                            </td>
                            <td className="text-nowrap">
                              {item.impsBeneNameDest}
                            </td>
                            <td className="text-nowrap">
                              {item.chequeInFavour}
                            </td>
                            <td className="text-nowrap">{item.emi}</td>
                            <td className="text-nowrap">
                              {item.noOfInstallment}
                            </td>
                            <td className="text-nowrap">
                              {item.netDisbursementAmount}
                            </td>
                            <td className="text-nowrap">
                              {item.clientmobileno}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "tab3" ? "show active" : ""
              }`}
              id="tab3"
              role="tabpanel"
              aria-labelledby="tab3-tab"
            >
              <div className="pt-3">
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex ms-2">
                      <DownloadTableExcel
                        filename="NEFT Data"
                        sheet="users"
                        currentTableRef={NEFTData.current}
                      >
                        <button
                          className="btn btn-success border"
                          style={{
                            width: "120px",
                            backgroundColor: "#66a725",
                          }}
                        >
                          {" "}
                          Download{" "}
                        </button>
                      </DownloadTableExcel>
                    </div>
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Sr. No</th>
                            <th className="text-nowrap">Loan Id</th>
                            <th className="text-nowrap">Client Name</th>
                            <th className="text-nowrap">Mobile No</th>
                            <th className="text-nowrap">Done By Id</th>
                            <th className="text-nowrap">Done By Name</th>
                            <th className="text-nowrap">Entry Date</th>
                            <th className="text-nowrap">Status Desc</th>
                          </tr>
                        </thead>
                        <tbody>
                          {neftSuccessData
                            ?.slice(pagination.start, pagination.end)
                            ?.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1 + pagination.start}</td>

                                <td className="text-nowrap">{item.loanid}</td>
                                <td className="text-nowrap">
                                  {item.clientname}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientmobileno}
                                </td>
                                <td className="text-nowrap">{item.doneById}</td>
                                <td className="text-nowrap">
                                  {item.doneByName}
                                </td>
                                <td className="text-nowrap">
                                  {item.entryDate}
                                </td>
                                <td className="text-nowrap">
                                  {item.statusDesc}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {neftSuccessData && neftSuccessData.length !== 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={neftSuccessData.length}
                      />
                    )}
                    <table
                      className="table table-striped mt-3 "
                      style={{ display: "none" }}
                      ref={NEFTData}
                    >
                      <thead>
                        <tr>
                          <th className="text-nowrap">Sr. No</th>
                          <th className="text-nowrap">Loan Id</th>
                          <th className="text-nowrap">Client Name</th>
                          <th className="text-nowrap">Mobile No</th>
                          <th className="text-nowrap">Done By Id</th>
                          <th className="text-nowrap">Done By Name</th>
                          <th className="text-nowrap">Entry Date</th>
                          <th className="text-nowrap">Status Desc</th>
                        </tr>
                      </thead>
                      <tbody>
                        {neftSuccessData?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>

                            <td className="text-nowrap">{item.loanid}</td>
                            <td className="text-nowrap">{item.clientname}</td>
                            <td className="text-nowrap">
                              {item.clientmobileno}
                            </td>
                            <td className="text-nowrap">{item.doneById}</td>
                            <td className="text-nowrap">{item.doneByName}</td>
                            <td className="text-nowrap">{item.entryDate}</td>
                            <td className="text-nowrap">{item.statusDesc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "tab4" ? "show active" : ""
              }`}
              id="tab4"
              role="tabpanel"
              aria-labelledby="tab4-tab"
            >
              <div className="pt-3">
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex ms-2">
                      <DownloadTableExcel
                        filename="NEFT Rejected Data"
                        sheet="users"
                        currentTableRef={NEFTRejected.current}
                      >
                        <button
                          className="btn btn-success border"
                          style={{
                            width: "120px",
                            backgroundColor: "#66a725",
                          }}
                        >
                          {" "}
                          Download{" "}
                        </button>
                      </DownloadTableExcel>
                    </div>
                    <div className="table-responsive mt-3">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Sr. No</th>
                            <th className="text-nowrap">Loan Id</th>
                            <th className="text-nowrap">Client Name</th>
                            <th className="text-nowrap">Mobile No</th>
                            <th className="text-nowrap">Done By Id</th>
                            <th className="text-nowrap">Done By Name</th>
                            <th className="text-nowrap">Entry Date</th>
                            <th className="text-nowrap">Status Desc</th>
                          </tr>
                        </thead>
                        <tbody>
                          {neftRejectedData
                            ?.slice(pagination.start, pagination.end)
                            ?.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>

                                <td className="text-nowrap">{item.loanid}</td>
                                <td className="text-nowrap">
                                  {item.clientname}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientmobileno}
                                </td>
                                <td className="text-nowrap">{item.doneById}</td>
                                <td className="text-nowrap">
                                  {item.doneByName}
                                </td>
                                <td className="text-nowrap">
                                  {item.entryDate}
                                </td>
                                <td className="text-nowrap">
                                  {item.statusDesc}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {neftRejectedData && neftRejectedData.length !== 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={neftRejectedData.length}
                      />
                    )}
                    <table
                      className="table table-striped mt-3 "
                      style={{ display: "none" }}
                      ref={NEFTRejected}
                    >
                      <thead>
                        <tr>
                          <th className="text-nowrap">Sr. No</th>
                          <th className="text-nowrap">Loan Id</th>
                          <th className="text-nowrap">Client Name</th>
                          <th className="text-nowrap">Mobile No</th>
                          <th className="text-nowrap">Done By Id</th>
                          <th className="text-nowrap">Done By Name</th>
                          <th className="text-nowrap">Entry Date</th>
                          <th className="text-nowrap">Status Desc</th>
                        </tr>
                      </thead>
                      <tbody>
                        {neftRejectedData?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>

                            <td className="text-nowrap">{item.loanid}</td>
                            <td className="text-nowrap">{item.clientname}</td>
                            <td className="text-nowrap">
                              {item.clientmobileno}
                            </td>
                            <td className="text-nowrap">{item.doneById}</td>
                            <td className="text-nowrap">{item.doneByName}</td>
                            <td className="text-nowrap">{item.entryDate}</td>
                            <td className="text-nowrap">{item.statusDesc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "tab5" ? "show active" : ""
              }`}
              id="tab5"
              role="tabpanel"
              aria-labelledby="tab5-tab"
            >
              <div className="pt-3">
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex ms-2">
                      <DownloadTableExcel
                        filename="TVR Rejected"
                        sheet="users"
                        currentTableRef={TVRRejected.current}
                      >
                        <button
                          className="btn btn-success border"
                          style={{
                            width: "120px",
                            backgroundColor: "#66a725",
                          }}
                        >
                          {" "}
                          Download{" "}
                        </button>
                      </DownloadTableExcel>
                    </div>

                    <div className="table-responsive mt-3">
                      <table className="table table-striped ">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Sr. No</th>
                            <th className="text-nowrap">App Client Id</th>
                            <th className="text-nowrap">Loan Id</th>
                            <th className="text-nowrap">Client Name</th>
                            <th className="text-nowrap">Mobile No</th>
                            <th className="text-nowrap">Loan Type</th>
                            <th className="text-nowrap">Scheme No.</th>
                            <th className="text-nowrap">Sanction Amount</th>
                            <th className="text-nowrap">Branch Name</th>
                            <th className="text-nowrap">Cluster</th>
                            <th className="text-nowrap">Rejected Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tvrRejected
                            ?.slice(pagination.start, pagination.end)
                            ?.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1 + pagination.start}</td>

                                <td className="text-nowrap">
                                  {item.appClientId}
                                </td>
                                <td className="text-nowrap">{item.loanid}</td>
                                <td className="text-nowrap">
                                  {item.clientName}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientmobileno}
                                </td>
                                <td className="text-nowrap">{item.loanType}</td>
                                <td className="text-nowrap">{item.schemeNo}</td>
                                <td className="text-nowrap">
                                  {item.sanctionAmount}
                                </td>
                                <td className="text-nowrap">
                                  {item.branchname}
                                </td>
                                <td className="text-nowrap">{item.cluster}</td>
                                <td className="text-nowrap">
                                  {item.verificationDate}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {tvrRejected && tvrRejected.length !== 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={tvrRejected.length}
                      />
                    )}

                    <table
                      className="table table-striped mt-3 "
                      style={{ display: "none" }}
                      ref={TVRRejected}
                    >
                      <thead>
                        <tr>
                          <th className="text-nowrap">Sr. No</th>
                          <th className="text-nowrap">App Client Id</th>
                          <th className="text-nowrap">Loan Id</th>
                          <th className="text-nowrap">Client Name</th>
                          <th className="text-nowrap">Mobile No</th>
                          <th className="text-nowrap">Loan Type</th>
                          <th className="text-nowrap">Scheme No.</th>
                          <th className="text-nowrap">Sanction Amount</th>
                          <th className="text-nowrap">Branch Name</th>
                          <th className="text-nowrap">Cluster</th>
                          <th className="text-nowrap">Rejected Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tvrRejected?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>

                            <td className="text-nowrap">{item.appClientId}</td>
                            <td className="text-nowrap">{item.loanid}</td>
                            <td className="text-nowrap">{item.clientName}</td>
                            <td className="text-nowrap">
                              {item.clientmobileno}
                            </td>
                            <td className="text-nowrap">{item.loanType}</td>
                            <td className="text-nowrap">{item.schemeNo}</td>
                            <td className="text-nowrap">
                              {item.sanctionAmount}
                            </td>
                            <td className="text-nowrap">{item.branchname}</td>
                            <td className="text-nowrap">{item.cluster}</td>
                            <td className="text-nowrap">
                              {item.verificationDate}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "tab6" ? "show active" : ""
              }`}
              id="tab6"
              role="tabpanel"
              aria-labelledby="tab6-tab"
            >
              <div className="pt-3">
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex ms-2">
                      <DownloadTableExcel
                        filename="ReHit Penny Drop"
                        sheet="users"
                        currentTableRef={ReHitDrop.current}
                      >
                        <button
                          className="btn btn-success border"
                          style={{
                            width: "120px",
                            backgroundColor: "#66a725",
                          }}
                        >
                          {" "}
                          Download{" "}
                        </button>
                      </DownloadTableExcel>
                    </div>

                    <div className="table-responsive mt-3">
                      <table className="table table-striped ">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Sr. No</th>
                            <th className="text-nowrap">App Client Id</th>
                            <th className="text-nowrap">Loan Id</th>
                            <th className="text-nowrap">Client Name</th>
                            <th className="text-nowrap">Mobile No</th>
                            <th className="text-nowrap">Sanction Amount</th>
                            <th className="text-nowrap">Branch Name</th>
                            <th className="text-nowrap">IFSC Code</th>
                            <th className="text-nowrap">Bank Branch</th>
                            <th className="text-nowrap">Entry Date</th>
                            <th className="text-nowrap">Status</th>
                            <th className="text-nowrap">Approve</th>
                            <th className="text-nowrap">Reject</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reHitPennyDrop
                            ?.slice(pagination.start, pagination.end)
                            ?.map((item, i) => (
                              <tr key={i}>
                                <td>{i + 1 + pagination.start}</td>

                                <td className="text-nowrap">
                                  {item.appclientid}
                                </td>
                                <td className="text-nowrap">{item.loanid}</td>
                                <td className="text-nowrap">
                                  {item.clientname}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientmobileno}
                                </td>
                                <td className="text-nowrap">
                                  {item.sanctionAmount}
                                </td>
                                <td className="text-nowrap">
                                  {item.branchName}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientBankIfsc}
                                </td>
                                <td className="text-nowrap">
                                  {item.clientAccountNo}
                                </td>
                                <td className="text-nowrap">
                                  {item.entrydate}
                                </td>
                                <td className="text-nowrap">{item.status}</td>
                                <td className="text-nowrap">
                                  <button
                                    className="btn btn-success"
                                    onClick={() =>
                                      reHitPennyDropApprove(item.loanid)
                                    }
                                  >
                                    {isLoader ? (
                                      <div
                                        className="spinner-border text-success"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Approve"
                                    )}
                                  </button>
                                </td>
                                <td className="text-nowrap">
                                  <button
                                    className="btn btn-success"
                                    onClick={() =>
                                      reHitPennyDropReject(item.loanid)
                                    }
                                  >
                                    {isLoader ? (
                                      <div
                                        className="spinner-border text-success"
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : (
                                      "Reject"
                                    )}
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {reHitPennyDrop && reHitPennyDrop.length !== 0 && (
                      <Pagination
                        showPrePage={showPrePage}
                        onPaginationChange={onPaginationChange}
                        total={reHitPennyDrop.length}
                      />
                    )}
                    <table
                      className="table table-striped mt-3 "
                      style={{ display: "none" }}
                      ref={ReHitDrop}
                    >
                      <thead>
                        <tr>
                          <th className="text-nowrap">Sr. No</th>
                          <th className="text-nowrap">App Client Id</th>
                          <th className="text-nowrap">Loan Id</th>
                          <th className="text-nowrap">Client Name</th>
                          <th className="text-nowrap">Mobile No</th>
                          <th className="text-nowrap">Sanction Amount</th>
                          <th className="text-nowrap">Branch Name</th>
                          <th className="text-nowrap">IFSC Code</th>
                          <th className="text-nowrap">Bank Branch</th>
                          <th className="text-nowrap">Entry Date</th>
                          <th className="text-nowrap">Status</th>
                          <th className="text-nowrap">Approve</th>
                          <th className="text-nowrap">Reject</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reHitPennyDrop?.map((item, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>

                            <td className="text-nowrap">{item.appclientid}</td>
                            <td className="text-nowrap">{item.loanid}</td>
                            <td className="text-nowrap">{item.clientname}</td>
                            <td className="text-nowrap">
                              {item.clientmobileno}
                            </td>
                            <td className="text-nowrap">
                              {item.sanctionAmount}
                            </td>
                            <td className="text-nowrap">{item.branchName}</td>
                            <td className="text-nowrap">
                              {item.clientBankIfsc}
                            </td>
                            <td className="text-nowrap">
                              {item.clientAccountNo}
                            </td>
                            <td className="text-nowrap">{item.entrydate}</td>
                            <td className="text-nowrap">{item.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditAppraisalForMagic;
