import React from "react";

function FinanceReport() {
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              Disbursement Report
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-item"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Collection Report
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-item"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact"
              type="button"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              Employers
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-item"
              id="settings-tab"
              data-bs-toggle="tab"
              data-bs-target="#settings"
              type="button"
              role="tab"
              aria-controls="settings"
              aria-selected="false"
            >
              Ac Admin
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Client name</th>
                          <th>LID</th>
                          <th>Mobile</th>
                          <th>Dt of disb</th>
                          <th>Employer name/code </th>
                          <th>Loan amt</th>
                          <th>Set up fees</th>
                          <th>Convenience fee</th>
                          <th>Disbursed amt</th>
                          <th>Payable to Salarytap</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Client name</th>
                          <th>LID</th>
                          <th>Mobile</th>
                          <th>Employer name/code </th>
                          <th>Amt due</th>
                          <th>Amt collected</th>
                          <th>Balance due</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Employer name/Code</th>
                          <th>Setup fees</th>
                          <th>Conv fee</th>
                          <th>Subvention fees</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="settings"
            role="tabpanel"
            aria-labelledby="settings-tab"
          >
            <div className="pt-3">
              <div className="card">
                <div className="p-3 pt-4">
                  <div className="p-3 d-flex flex-column flex-md-row justify-content-end align-items-center">
                    <button
                      className="btn btn-success mb-3 mb-md-0"
                      style={{ width: "120px", backgroundColor: "#66a725" }}
                    >
                      Add Admin
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceReport;
