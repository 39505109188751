import React, { useState, useEffect } from "react";

const MapComponent = ({ data, status }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    console.log("tidididiidi", data);
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA0tm4EMf37E7HBXy9n8mvfFgILNDsSB-U&libraries=places`;
    script.async = true;
    script.onload = handleScriptLoad;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [data, status]);

  const handleScriptLoad = () => {
    if (status) {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: data.lat, lng: data.lng },
        zoom: 8,
      });

      new window.google.maps.Marker({
        position: { lat: data.lat, lng: data.lng },
        map: map,
        title: "Company Location",
      });
    } else {
      console.log('this.props.data::: ', data);
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: Number(data[0].latitude), lng: Number(data[0].longitude) },
        zoom: 8,
      });
      console.log("that.handleGeoTaggingDialogOpen();", data);
      data.forEach((location) => {
        console.log('location::: ', location);
        new window.google.maps.Marker({
          position: { lat: Number(location.latitude), lng: Number(location.longitude) },
          map: map,
          title: location.docType, // You can customize the title
        });
      });
    }
  };

  return <div id="map" style={{ width: "100%", height: "400px" }} />;
};

export default MapComponent;
