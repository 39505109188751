import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { Modal, Button, Form } from "react-bootstrap";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { APIURL } from "../../../Config";
import axios from "axios";
import { Select } from "antd";
import * as Yup from "yup";
import { toast } from "react-toastify";

const PendingPage = () => {
  const token = localStorage.getItem("token");
  const ids = localStorage.getItem("id");
  const role = localStorage.getItem("role");
  const tableRef = React.useRef(null);
  const [selectDate, setSelectDate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clientName, setClientName] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [clientDetails, setClientDetails] = useState([]);
  const [banketId, setBanketId] = useState(0);
  const [clientId, setClientId] = useState(0);
  const [engineerDetail, setEngineerDetail] = useState([]);

  const validationSchema = Yup.object({
    clientId: Yup.string().required("Client selection is required."),
  });
  // Function to handle saving changes (you can add more logic here)

  useEffect(() => {
    fetchClientDetails();
    fetchAssignTo();
  }, []);
  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/getisActivedata`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchAssignTo = async () => {
    await axios
      .get(`${APIURL}/api/EngineerDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setEngineerDetail(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [dateValue1, setDateValue1] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    const todayDate = new Date();
    setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
    const sevenDaysBeforeDate = new Date();
    sevenDaysBeforeDate.setDate(todayDate.getDate() - 7);
    setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    handleSubmit();
  }, []);
  const handleSubmit = () => {
    console.log(":::::::", dateValue1, dateValue2);
    let url =
      role === "CPV Super Admin"
        ? `${APIURL}/api/clientStatusEngineer/${ids}/0`
        : `${APIURL}/api/clientStatus/${ids}/0`;
    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerByClientsAndAssignTo/${ids}/0/${selectValue}/${assignTo}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/clientStatusEngineerByClients/${ids}/0/${selectValue}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerByAssignTo/${ids}/0/${assignTo}`;
    }

    axios
      .request(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::: ", response);
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [formValues, setFormValues] = useState({ id: "" });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    setBanketId(e.target.value);
  };

  const handleSubmitFrom = () => {
    console.log("shfsj");
    axios
      .get(`${APIURL}/api/updateengineerid/${clientId}/${banketId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::----: ", response);
        setShowModal(false);
        toast.success(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div class="pt-4">
        <h4 className="text-center my-3">Pending</h4>

        <div className="pt-3">
          <div className="card">
            <div className="p-3 pt-4">
              <div className="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
                <div>
                  <DownloadTableExcel
                    filename="Pending"
                    sheet="users"
                    currentTableRef={tableRef.current}
                  >
                    <button
                      className="btn btn-success mb-3 mb-md-0"
                      style={{ width: "120px", background: "blue" }}
                    >
                      Download
                    </button>
                  </DownloadTableExcel>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-center">
                  {!selectDate ? (
                    <button
                      className="btn btn-info"
                      style={{ width: "150px" }}
                      onClick={() => setSelectDate(true)}
                    >
                      Filters
                    </button>
                  ) : (
                    <>
                      <div className="d-flex" style={{ width: "500px" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <div className="mt-2 mx-1">
                            <Form.Select
                              options={clientDetails}
                              value={selectValue}
                              type="number"
                              className="form-control ml-2"
                              onChange={(e) => setSelectValue(e.target.value)}
                            >
                              <option value="">Select Client</option>
                              {clientDetails.map((ids) => (
                                <option value={ids.id} key={ids.id}>
                                  {ids.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="mt-2 mx-2">
                            <Form.Select
                              options={engineerDetail}
                              value={assignTo}
                              type="text"
                              className="form-control ml-2"
                              onChange={(e) => setAssignTo(e.target.value)}
                            >
                              <option value="">Select Assign To</option>

                              {engineerDetail.map((ids) => (
                                <option value={ids.id} key={ids.id}>
                                  {ids.name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </LocalizationProvider>
                        <div className="mt-2 mx-2">
                          <button
                            className="btn btn-success"
                            style={{
                              background: "#66a725",
                              width: "100px",
                            }}
                            onClick={() => handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="table-responsive mt-3">
                <table class="table table-boarded" ref={tableRef}>
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Case No</th>
                      <th>Applicant Name</th>
                      <th>Mobile Number</th>
                      <th>Address</th>
                      <th>Client Name</th>
                      <th>Assign to</th>
                      {role === "CPV Super Admin" && <th>Assign change</th>}
                      {/* <th>Case Reference Number</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item?.caseReferenceNumber}</td>
                        <td>{item?.applicantName}</td>
                        <td>{item?.mobile}</td>
                        <td>{item?.address}</td>
                        <td>{item?.clientName}</td>
                        <td>{item?.assignBy}</td>
                        {role === "CPV Super Admin" && (
                          <td>
                            <div>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setShowModal(true);
                                  setClientId(item?.id);
                                }}
                              >
                                Change
                              </button>
                            </div>
                          </td>
                        )}
                        {/* <td>{item?.caseReferenceNumber}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setErrors({});
          setFormValues({ id: "" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change assign to case</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mt-2">
              <label htmlFor="id">Client ID*</label>
              <select
                name="id"
                value={banketId}
                onChange={handleInputChange}
                className={`form-select ${errors.id ? "is-invalid" : ""}`}
              >
                <option value="">Select Client</option>{" "}
                {/* Default placeholder option */}
                {clientDetails.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>

              {errors.id && <div className="invalid-feedback">{errors.id}</div>}
            </div>

            <div className="d-flex gap-2 mt-3 ">
              <button
                style={{ width: "fit-content" }}
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setShowModal(false);
                  setErrors({});
                  setFormValues({ id: "" });
                }}
              >
                Close
              </button>
              <button
                style={{ width: "fit-content" }}
                type="button"
                className="btn btn-primary"
                onClick={() => handleSubmitFrom()}
              >
                Save
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PendingPage;
