import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

function GrievanceRedressal() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Navbar />
      <div className="">
        <div className="container">
          <div className=" px-md-5">
            <div className="card bg-light "
           
            >
              <div
                className=" h4 text-center"
                style={{
                  backgroundColor: "#66a725",
                  color: "#fff",
                  padding: 4,
                  borderTopLeftRadius: "0.375rem",
                  borderTopRightRadius: "0.375rem",
                }}
              >
                Grievance Redressal{" "}
              </div>
              <div className=" p-4">
                <div>
                  <h6 className=" mt-4" style={{ fontWeight: "400" }}>
                    We are committed to resolving any problem or issue of yours.
                    If you are unsatisfied with the resolution of your issue,
                    you can submit your grievance to{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      Care.salarytap@gmail.com
                    </span>{" "}
                    or call our Grievance Redressal Officer, Gautam Kumar on{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      +919810688661
                    </span>{" "}
                    on working days, between Monday and Friday from 9.30 am to
                    5.30 pm.
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Ombudsman Scheme for Non-Banking Financial Companies, 2021
                    The customer can also approach Ombudsman under THE RESERVE
                    BANK - INTEGRATED OMBUDSMAN SCHEME, 2021, in case of any
                    complaint at
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    C/o Reserve Bank of India
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Sansad Marg, New Delhi -110001
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    STD Code: 011 Telephone No: 23724856
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Fax No :{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      23725218-19
                    </span>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Email :{" "}
                    <span style={{ color: "blue", cursor: "pointer" }}>
                      crpc@rbi.org.in.
                    </span>
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    The complaints under the Scheme made online shall be
                    registered on the portal({" "}
                    <a href="https://cms.rbi.org.in/" target="_blank">
                      https://cms.rbi.org.in
                    </a>
                    ).
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    The customer can also raise complaint using Sachet link: (
                    <a
                      href="https://sachet.rbi.org.in/Complaints/Add"
                      target="_blank"
                    >
                      https://sachet.rbi.org.in/Complaints/Add
                    </a>
                    ).
                  </h6>
                  <h6 className=" mt-3" style={{ fontWeight: "400" }}>
                    Complaints in electronic mode (E-mail) and physical form,
                    including postal and hand-delivered complaints, shall be
                    addressed and sent to the place where the Centralised
                    Receipt and Processing Centre of the Reserve Bank is
                    established, for scrutiny and initial processing.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default GrievanceRedressal;
