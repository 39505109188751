import React, { useState } from "react";

function Search() {
  const [keyName, setKeyName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const searchHandler = () => {
    const search = { [keyName]: searchInput };
    console.log("search", search);
    // setIsLoader(true);
    // axios
    //   .request(`${APIURL}/employee/searchdetails`, {
    //     method: "POST",
    //     data: search,
    //     headers: {
    //       "Content-Type": "application/json",

    //     },
    //   })
    //   .then((response) => {
    //     SetManageEmp(response?.data);

    //     setSearchInput("");
    //     setIsLoader(false);
    //   })
    //   .catch((error) => {
    //     setIsLoader(false);
    //     console.log(error);
    //   });
  };
  return (
    <div className="p-3">
      <div>
        <ul className="nav nav-tabs" id="myTabs" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-item "
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="true"
            >
              Search
            </button>
          </li>
        </ul>
        <div className="tab-content mt-3" id="myTabsContent">
          <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div
              className="d-flex flex-column flex-md-row align-items-center"
              style={{ width: "40%" }}
            >
              <select
                className="form-select mx-md-2 mb-2 mb-md-0"
                id="lang"
                onChange={(e) => setKeyName(e.target.value)}
                value={keyName}
              >
                <option value="mobile">Mobile No.</option>
                <option value="lid">Loan id</option>
                <option value="name">Name</option>
              </select>
              <input
                className="form-control"
                style={{
                  height: "38px",
                  width: "100%",
                  background: "#fff",
                }}
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleInputChange}
              />
              <button
                className="btn btn-success ml-md-2 mt-2 mt-md-0"
                style={{ background: "#66a725", color: "#fff", width: "25%" }}
                onClick={searchHandler}
              >
                {isLoader ? (
                  <div className="spinner-border text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Search"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
