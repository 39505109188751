import React, { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { useLocation } from "react-router-dom";
import a1 from "../../image/Captial trust ad-2.jpg";
import a2 from "../../image/Captial trust ad-3.jpg";

function Media() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Navbar />
      <div className="">
      <div className="container">
          <div className="px-md-5">
            <div className="card bg-light "
           
            >
          <div
            className="h4 text-center"
            style={{
              backgroundColor: "#66a725",
              color: "#fff",
              padding: 4,
              borderTopLeftRadius: "0.375rem",
              borderTopRightRadius: "0.375rem",
            }}
          >
            Media
          </div>
          <div className="text-white p-4">
            <div className="row">
              <div
                className="col-sm-12 col-md-6 col-lg-6 mt-4 text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <object data={a1} width="300" height="250"></object>
                <button
                  className="btn btn-success mt-2"
                  style={{ width: "100px" ,backgroundColor:'#66a725',color:"#fff"}}
                  onClick={() => window.open(a1)}
                >
                  View
                </button>
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-6 mt-4 text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <object data={a2} width="300" height="250"></object>
                <button
                  className="btn btn-success mt-2"
                  style={{ width: "100px" ,backgroundColor:'#66a725',color:"#fff"}}
                  onClick={() => window.open(a2)}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>
  </div>
      <Footer />
    </>
  );
}

export default Media;
